<template>
  <v-row>
    <v-col class="pt-0 text-center grey400--text">
      <v-progress-circular indeterminate v-if="isLoadingGsenData" />
      <span v-else-if="!isLoadingGsenData && dataList.length === 0">{{ $t('noData') }}</span>
      <div v-show="!isLoadingGsenData && dataList.length > 0">
        <canvas :id="'gsenDataChart-' + item" :ref="'gsenDataChart-' + item" style="max-height: 300px" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { getGsenData } from '@/services/api'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-dayjs-3'
import zoomPlugin from 'chartjs-plugin-zoom'
// import annotationPlugin from 'chartjs-plugin-annotation'
import { Tooltip } from 'chart.js'
import { mapGetters } from 'vuex'

Chart.register(zoomPlugin)
// Chart.register(annotationPlugin)

export default {
  name: 'GsenData',
  props: ['gsenUdid', 'item', 'dataDtFrom', 'dataDtTo', 'color'],
  data: () => ({
    updateInterval: null,
    updateIntervalTime: 300000,
    isLoadingGsenData: true,
    dataList: [],
    gsenDataChart: null,
  }),
  computed: {
    ...mapGetters(['standardInfo']),
  },
  watch: {
    '$vuetify.breakpoint.name'() {
      // 차트 재생성
      if (this.gsenDataChart !== null && (this.gsenDataChart.canvas ?? null) !== null) this.gsenDataChart.destroy()

      setTimeout(() => {
        this.createGsenDataChart()
      }, 500)
    },
  },
  async mounted() {
    await this.getGsenData()

    this.updateInterval = setInterval(() => {
      this.getGsenData(true)
    }, this.updateIntervalTime)
  },
  beforeDestroy() {
    clearInterval(this.updateInterval)
  },
  methods: {
    async getGsenData(update = false) {
      if (this.dataDtFrom !== '' && this.dataDtTo !== '') {
        if (!update) this.isLoadingGsenData = true
        try {
          let result = await getGsenData(this, this.gsenUdid, this.item, this.dataDtFrom, this.dataDtTo)
          this.dataList = result.data.reverse()
          if (update) this.gsenDataChart.update()
          else await this.createGsenDataChart()
        } catch (error) {
          if (error.message) console.error(error.code, error.message)
          else if (error.data) console.error(error.data)
          else console.error(error)
        } finally {
          this.isLoadingGsenData = false
        }
      }
    },
    async createGsenDataChart() {
      if (this.gsenDataChart !== null) this.gsenDataChart.destroy()

      Tooltip.positioners.custom = function (items) {
        const pos = Tooltip.positioners.average(items)

        // Happens when nothing is found
        if (pos === false) {
          return false
        }

        return {
          x: pos.x,
          y: pos.y + 80,
          yAlign: 'bottom',
        }
      }

      const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div')

        if (!tooltipEl) {
          tooltipEl = document.createElement('div')
          tooltipEl.style.whiteSpace = 'nowrap'
          tooltipEl.style.background = '#fff'
          tooltipEl.style.border = '1px solid ' + this.$vuetify.theme.themes.light['grey100']
          tooltipEl.style.borderRadius = '10px'
          tooltipEl.style.fontSize = '14px'
          tooltipEl.style.opacity = 1
          tooltipEl.style.pointerEvents = 'none'
          tooltipEl.style.position = 'absolute'
          tooltipEl.style.transform = 'translate(-50%, 0)'
          tooltipEl.style.transition = 'all .1s ease'

          const table = document.createElement('table')
          table.style.margin = '0px'

          tooltipEl.appendChild(table)
          chart.canvas.parentNode.appendChild(tooltipEl)
        }

        return tooltipEl
      }

      const externalTooltipHandler = (context) => {
        // Tooltip Element
        const { chart, tooltip } = context
        const tooltipEl = getOrCreateTooltip(chart)

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0
          return
        }

        // Set Text
        if (tooltip.body) {
          const titleLines = tooltip.title || []
          const bodyLines = tooltip.body.map((b) => b.lines)

          const tableHead = document.createElement('thead')

          titleLines.forEach((title) => {
            const tr = document.createElement('tr')
            tr.style.borderWidth = 0

            const td = document.createElement('td')
            td.style.borderWidth = 0
            td.style.fontSize = '14px'
            td.style.color = this.$vuetify.theme.themes.light['grey800']
            const text = document.createTextNode(title)

            td.appendChild(text)
            tr.appendChild(td)
            tableHead.appendChild(tr)
          })

          const tableBody = document.createElement('tbody')
          bodyLines.reverse().forEach((body, i) => {
            const colors = tooltip.labelColors[bodyLines.length - 1 - i]

            const span = document.createElement('span')
            span.style.background = colors.backgroundColor
            span.style.borderColor = colors.borderColor
            span.style.borderWidth = '1px'
            span.style.height = '8px'
            span.style.width = '8px'
            span.style.borderRadius = '8px'

            const tr = document.createElement('tr')
            tr.style.backgroundColor = 'inherit'
            tr.style.borderWidth = 0

            // let measuredValue = Number(body[0].split(':').pop().replace(/,/g, ''))
            let index = context.tooltip.dataPoints[0].dataIndex
            let measuredValue = Number(this.dataList[index]['GSEN_DATA_' + this.item + '_AVG'])

            const th = document.createElement('th')
            th.style.borderWidth = 0
            th.style.fontSize = '16px'
            th.style.color = this.color !== false ? this.getStatusColor(measuredValue) : '#000'

            body[0] =
              (this.item.indexOf('PM') > -1 || this.item === 'TEMP'
                ? Number(measuredValue).toFixed(1)
                : Number(measuredValue).toFixed(0) + ' ') +
              ' ' +
              this.standardInfo[`${this.item}`]['STD_UNIT']

            const text = document.createTextNode(body)

            th.appendChild(span)
            th.appendChild(text)
            tr.appendChild(th)
            tableBody.appendChild(tr)
          })

          const tableRoot = tooltipEl.querySelector('table')

          // Remove old children
          while (tableRoot.firstChild) {
            tableRoot.firstChild.remove()
          }

          // Add new children
          tableRoot.appendChild(tableHead)
          tableRoot.appendChild(tableBody)
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1
        tooltipEl.style.left = positionX + tooltip.caretX + 'px'
        tooltipEl.style.top = positionY + tooltip.caretY + 10 + 'px'
        // tooltipEl.style.font = tooltip.options.bodyFont.string
        tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
      }

      this.gsenDataChart = new Chart(this.$refs['gsenDataChart-' + this.item], {
        type: 'line',
        plugins: [
          {
            afterDraw: (chart) => {
              if (chart.tooltip?._active?.length) {
                let x = chart.tooltip._active[0].element.x
                let yAxis = chart.scales.y
                let ctx = chart.ctx
                ctx.save()
                ctx.beginPath()
                ctx.setLineDash([5, 7])
                ctx.moveTo(x, yAxis.top)
                ctx.lineTo(x, yAxis.bottom)
                ctx.lineWidth = 1
                ctx.strokeStyle = this.$vuetify.theme.themes.light['grey300']
                ctx.stroke()
                ctx.restore()
              }
            },
          },
        ],
        data: {
          // labels: this.dataList.map((data) =>
          //   this.$day.utc(data['GSEN_DATA_DT_QUARTER'], 'YYYY-MM-DD hh:mm:ss').local().format('YYYY-MM-DD hh:mm:ss')
          // ),
          datasets: [
            {
              label: this.$t(this.item),
              data: this.dataList.map((data) => ({
                x: Number(this.$day.utc(data['GSEN_DATA_DT_QUARTER'], 'YYYY-MM-DD hh:mm:ss').format('x')),
                y:
                  Number(data['GSEN_DATA_' + this.item + '_AVG']) >
                  Number(this.standardInfo[`${this.item}`]['STD_ALERT']) + 50
                    ? Number(this.standardInfo[`${this.item}`]['STD_ALERT']) + 50
                    : Number(data['GSEN_DATA_' + this.item + '_AVG']),
              })), // this.dataList.map((data) => parseInt(Number(data['GSEN_DATA_' + this.item + '_AVG']))),
              fill: false,
              tension: 0.2,
              borderWidth: 2,
              // borderColor: this.$vuetify.theme.themes.light['grey400'],
              borderColor: (context) => {
                const chart = context.chart
                const { ctx, chartArea } = chart

                if (!chartArea || this.color === false) return
                return this.getChartBorderColor(ctx, chartArea)
              },
              pointBorderWidth: 0,
              pointBackgroundColor: 'transparent',
              pointHoverBackgroundColor: (context) => context.element.options.borderColor,
            },
          ],
        },
        options: {
          responsive: true,
          interaction: {
            intersect: false,
            mode: 'index',
          },
          plugins: {
            legend: { display: false },
            tooltip: {
              enabled: false,
              position: 'nearest',
              mode: 'x',
              external: externalTooltipHandler,
            },
            zoom: {
              limits: {
                x: {
                  min: 'original',
                  max: 'original',
                  minRange: 12 * 60 * 60 * 1000, // 12시간을 한 화면에 표시
                  // minRange: (this.$vuetify.breakpoint.smAndDown ? 3 : 15) * 60 * 60 * 1000,
                },
                // y: { min: 'original', max: 'original' },
              },
              pan: {
                enabled: true,
                mode: 'x',
              },
              zoom: {
                enabled: true,
                mode: 'x',
              },
            },
          },
          animation: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
              type: 'timeseries',
              ticks: {
                autoSkip: true,
                autoSkipPadding: 10, // 10개 (2시간)씩 스킵
                maxRotation: 0,
                major: {
                  enabled: true,
                },
                font: function (context) {
                  if (context.tick && context.tick.major) {
                    return {
                      weight: 'bold',
                    }
                  }
                },
              },
              time: {
                unit: 'hour',
                stepSize: 1,
                displayFormats: {
                  hour: 'HH:mm',
                  minute: 'HH:mm',
                  second: 'HH:mm',
                  day: 'MM-DD',
                },
                tooltipFormat: 'YYYY-MM-DD HH:mm',
              },
            },
            y: {
              beginAtZero: true,
              max:
                this.item !== 'TEMP' && this.item !== 'HUMI'
                  ? Number(this.standardInfo[`${this.item}`]['STD_ALERT']) + 50
                  : Number(this.standardInfo[`${this.item}`]['STD_SAFE_MAX']),
              ticks: {
                // stepSize: (Number(this.standardInfo[`${this.item}`]['STD_ALERT']) + 50) / 4,
              },
              grid: {
                borderWidth: 0,
                tickWidth: 0,
                color: this.$vuetify.theme.themes.light['grey100'],
              },
            },
          },
        },
      })
      this.gsenDataChart.zoom(1.99)
      this.gsenDataChart.pan({ x: -99999999 }, undefined, 'default')
    },
    getChartBorderColor(ctx, chartArea) {
      // let safeMax = this.standardInfo[`${this.item}`]['STD_SAFE_MAX']
      let warning = Number(this.standardInfo[`${this.item}`]['STD_WARN'])
      let alert = Number(this.standardInfo[`${this.item}`]['STD_ALERT'])

      let width, height, gradient
      const chartWidth = chartArea.right - chartArea.left
      const chartHeight = chartArea.bottom - chartArea.top

      if (!gradient || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth
        height = chartHeight
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
        gradient.addColorStop(0, this.$vuetify.theme.themes.light['safe'])
        gradient.addColorStop(warning / (alert + 50) - 0.01, this.$vuetify.theme.themes.light['safe'])
        gradient.addColorStop(warning / (alert + 50), this.$vuetify.theme.themes.light['warning'])
        gradient.addColorStop(alert / (alert + 50) - 0.01, this.$vuetify.theme.themes.light['warning'])
        gradient.addColorStop(alert / (alert + 50), this.$vuetify.theme.themes.light['danger'])
        gradient.addColorStop(1, this.$vuetify.theme.themes.light['danger'])
      }

      return gradient
    },
    getStatusColor(val) {
      let statusColor = 'grey400'

      if (val >= this.standardInfo[`${this.item}`]['STD_ALERT']) statusColor = 'danger'
      else if (val >= this.standardInfo[`${this.item}`]['STD_WARN']) statusColor = 'warning'
      else if (val < this.standardInfo[`${this.item}`]['STD_WARN']) statusColor = 'safe'

      return this.$vuetify.theme.themes.light[statusColor]
    },
  },
}
</script>
