<template>
  <Layout>
    <v-container class="pt-lg-15 pt-md-10 pb-0" fluid slot="title">
      <v-row no-gutters>
        <v-col cols="12" md="auto">
          <h2
            :class="[
              { 'font-size-30': $vuetify.breakpoint.mdAndUp },
              { 'font-size-20': $vuetify.breakpoint.smAndDown },
            ]"
          >
            {{ $t('gillsControl') }}
            <span
              :class="[
                'ml-3 font-weight-regular',
                { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                { 'font-size-14': $vuetify.breakpoint.smAndDown },
              ]"
              v-html="$t('totalGillsCount', { count: gillsListPage['totalCount'] })"
              v-if="gillsListPage !== null"
            />
          </h2>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="auto" class="d-flex align-end justify-end">
          <span
            :class="[
              'grey600--text',
              { 'font-size-14': $vuetify.breakpoint.mdAndUp },
              { 'font-size-12': $vuetify.breakpoint.smAndDown },
              { 'mt-2 mb-1': $vuetify.breakpoint.smAndDown },
            ]"
          >
            {{ lastUpdatedTime ? $t('lastUpdate') + lastUpdatedTime : '' }}
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pa-lg-4 pt-lg-10 pt-md-10 align-content-start" fluid fill-height slot="content">
      <v-row>
        <v-col>
          <v-container fluid class="pa-0">
            <v-row>
              <v-col cols="12" md="6" lg="4" class="pa-3" v-for="gills in gillsList" :key="gills['GSEN_UDID']">
                <v-sheet outlined color="grey100" rounded="lg" class="box">
                  <v-card
                    tag="section"
                    rounded="lg"
                    elevation="0"
                    class="pa-4 pa-md-10"
                    :to="{ name: 'GillsDetail', params: { gillsUdid: gills['GILLS_UDID'] } }"
                  >
                    <v-container fill-height class="pa-0">
                      <v-row class="align-self-start">
                        <v-col :class="['col-auto', { 'pa-2': $vuetify.breakpoint.smAndDown }]">
                          <h3
                            :class="[
                              { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                              { 'font-size-16': $vuetify.breakpoint.smAndDown },
                            ]"
                          >
                            {{ gills['GILLS_NAME'] }}
                          </h3>
                        </v-col>
                        <v-spacer />
                        <v-col :class="['text-right', { 'pa-2': $vuetify.breakpoint.smAndDown }]">
                          <v-chip
                            label
                            :small="$vuetify.breakpoint.mdAndUp"
                            :x-small="$vuetify.breakpoint.smAndDown"
                            dark
                            :color="(gills['STATE'] === 'ERROR' ? 'NOSIGNAL' : gills['STATE']).toLowerCase()"
                          >
                            {{ $t((gills['STATE'] === 'WORKING' ? 'ONLINE' : gills['STATE']).toLowerCase()) }}
                          </v-chip>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col class="pa-0">
                          <span class="ont-weight-regular font-size-12 grey300--text" v-if="gillsListPage !== null">
                            {{ gills['AREA_A']['AREA_A_NAME'] }} > {{ gills['AREA_B']['AREA_B_NAME'] }} >
                            {{ gills['AREA_C']['AREA_C_NAME'] }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="align-self-baseline">
                        <v-col class="d-flex pa-0">
                          <v-container v-if="$vuetify.breakpoint.mdAndUp">
                            <v-row
                              v-for="item in gills['GILLS_DEV_VER'] !== 'G2-REV2.0' ? itemListG1 : itemListG2"
                              :key="item"
                            >
                              <v-col>
                                <v-container class="pa-0">
                                  <v-row>
                                    <v-col
                                      :class="[
                                        `${getGsenStatus(
                                          gills['GSEN'].length === 0 ||
                                            (gills['GSEN'].length > 0 && gills['GSEN'][0]['GSEN_DATA_' + item] === null)
                                            ? 'E'
                                            : gills['GSEN'][0]['GSEN_DATA_' + item + '_STATUS']
                                        )}--text`,
                                        'font-weight-bold',
                                        { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                                        { 'font-size-16': $vuetify.breakpoint.smAndDown },
                                      ]"
                                    >
                                      {{
                                        $t(
                                          getGsenStatusWord(
                                            gills['GSEN'].length === 0 ||
                                              (gills['GSEN'].length > 0 &&
                                                gills['GSEN'][0]['GSEN_DATA_' + item] === null)
                                              ? 'E'
                                              : gills['GSEN'][0]['GSEN_DATA_' + item + '_STATUS']
                                          )
                                        )
                                      }}
                                    </v-col>
                                    <v-col class="text-right font-weight-medium">
                                      <p
                                        :class="[
                                          'ma-0',
                                          { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                                          { 'font-size-12': $vuetify.breakpoint.smAndDown },
                                        ]"
                                      >
                                        {{ $t(item) }}
                                      </p>
                                      <p
                                        :class="[
                                          'ma-0 grey300--text',
                                          { 'font-size-12': $vuetify.breakpoint.mdAndUp },
                                          { 'font-size-10': $vuetify.breakpoint.smAndDown },
                                        ]"
                                      >
                                        {{ $store.getters.standardInfo[item]['STD_UNIT'] }}
                                      </p>
                                    </v-col>
                                  </v-row>
                                  <v-row no-gutters>
                                    <v-col cols="auto" class="pa-0 d-flex align-center">
                                      <v-chip
                                        class="d-flex align-center justify-center font-weight-medium"
                                        style="width: 45px"
                                        x-small
                                        dark
                                        :color="
                                          getGsenStatus(
                                            gills['GSEN'].length === 0 ||
                                              (gills['GSEN'].length > 0 &&
                                                gills['GSEN'][0]['GSEN_DATA_' + item] === null)
                                              ? 'E'
                                              : gills['GSEN'][0]['GSEN_DATA_' + item + '_STATUS']
                                          )
                                        "
                                      >
                                        {{
                                          gills['GSEN'].length === 0 ||
                                          (gills['GSEN'].length > 0 && gills['GSEN'][0]['GSEN_DATA_' + item] === null)
                                            ? '-'
                                            : gills['GSEN'][0]['GSEN_DATA_' + item] >= 999
                                            ? '999+'
                                            : item.indexOf('PM') > -1 || item === 'TEMP'
                                            ? gills['GSEN'][0]['GSEN_DATA_' + item].toFixed(1)
                                            : gills['GSEN'][0]['GSEN_DATA_' + item].toFixed(0)
                                        }}
                                      </v-chip>
                                      <span>&nbsp;</span>
                                    </v-col>
                                    <v-col class="pa-0 d-flex align-center">
                                      <v-progress-linear
                                        :color="
                                          getGsenStatus(
                                            gills['GSEN'].length === 0 ||
                                              (gills['GSEN'].length > 0 &&
                                                gills['GSEN'][0]['GSEN_DATA_' + item] === null)
                                              ? 'E'
                                              : gills['GSEN'][0]['GSEN_DATA_' + item + '_STATUS']
                                          )
                                        "
                                        class="rounded-xl grey100"
                                        buffer-value="0"
                                        :value="
                                          getPercent(
                                            item,
                                            gills['GSEN'].length === 0 ||
                                              (gills['GSEN'].length > 0 &&
                                                gills['GSEN'][0]['GSEN_DATA_' + item] === null)
                                              ? 0
                                              : gills['GSEN'][0]['GSEN_DATA_' + item]
                                          )
                                        "
                                        height="16"
                                      ></v-progress-linear>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-container v-else>
                            <v-row :class="[{ 'flex-nowrap overflow-x-auto': $vuetify.breakpoint.smAndDown }]">
                              <v-col
                                v-for="item in gills['GILLS_DEV_VER'] !== 'G2-REV2.0' ? itemListG1 : itemListG2"
                                :key="item"
                                class="pa-2"
                              >
                                <v-sheet
                                  rounded="lg"
                                  class="d-flex justify-center align-center mx-auto"
                                  width="68"
                                  height="68"
                                  :color="
                                    getGsenStatus(
                                      gills['GSEN'].length === 0 ||
                                        (gills['GSEN'].length > 0 && gills['GSEN'][0]['GSEN_DATA_' + item] === null)
                                        ? 'E'
                                        : gills['GSEN'][0]['GSEN_DATA_' + item + '_STATUS']
                                    )
                                  "
                                  dark
                                >
                                  <p class="ma-0 text-center">
                                    <span class="font-size-16 font-weight-bold">
                                      {{
                                        gills['GSEN'].length === 0 ||
                                        (gills['GSEN'].length > 0 && gills['GSEN'][0]['GSEN_DATA_' + item] === null)
                                          ? '-'
                                          : gills['GSEN'][0]['GSEN_DATA_' + item].toFixed(0)
                                      }}
                                      <!-- <span class="font-size-12 font-weight-regular">
                                        {{ $store.getters.standardInfo[item]['STD_UNIT'] }}
                                      </span> -->
                                    </span>
                                    <br />
                                    <span class="font-size-12">{{ $t(item) }}</span>
                                  </p>
                                </v-sheet>
                              </v-col>
                              <v-responsive class="pa-2" v-if="!$vuetify.breakpoint.smAndDown"></v-responsive>
                            </v-row>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row v-if="gillsListPage !== null && gillsListPage['totalCount'] > 0">
              <v-col>
                <v-pagination v-model="gillsPage" :length="gillsListPage['totalPage']" @input="getGillsList" />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col class="text-center grey400--text py-16">
                <v-progress-circular indeterminate v-if="isLoadingGillsList" />
                <span v-else>{{ $t('noData') }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
import Layout from '@/layouts/Dashboard'
import { getGillsList } from '@/services/api'
import { getGsenStatus, getGsenStatusWord } from '@/services/common'

export default {
  name: 'GillsList',
  components: {
    Layout,
  },
  data: () => ({
    updateInterval: null,
    updateIntervalTime: 10000,
    lastUpdatedTime: '',
    gillsPage: 1,
    gillsItemPerPage: 15,
    gillsList: [],
    gillsListPage: null,
    isLoadingGillsList: true,
    itemListG1: ['PM1P0', 'PM2P5', 'PM10P0'],
    itemListG2: ['PM2P5', 'PM10P0', 'TVOC'],
  }),
  created() {
    this.getGillsList()

    this.updateInterval = setInterval(() => {
      this.getGillsList(true)
    }, this.updateIntervalTime)
  },
  beforeDestroy() {
    clearInterval(this.updateInterval)
  },
  methods: {
    async getGillsList(update = false) {
      if (!update) this.isLoadingGillsList = true
      try {
        let result = await getGillsList(this, this.gillsPage, this.gillsItemPerPage)
        this.gillsListPage = result.data['PAGE']
        this.gillsList = result.data['DATA']
        // this.gillsList =
        //   this.gillsListPage['currentPage'] === 1 ? result.data['DATA'] : [...this.gillsList, ...result.data['DATA']]
        // this.lastUpdatedTime = this.$day().format('YYYY.MM.DD hh:mm:ss')
        this.lastUpdatedTime = this.$day
          .unix(Math.max(...this.gillsList.map((gills) => this.$day(gills['LAST_COMM'] ?? 0).unix())))
          .format('YYYY.MM.DD hh:mm:ss')
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingGillsList = false
      }
    },
    getGsenStatus(status) {
      return getGsenStatus(status)
    },
    getGsenStatusWord(status) {
      return getGsenStatusWord(status)
    },
    getPercent(item, value) {
      return ((value / parseFloat(this.$store.getters.standardInfo[item]['STD_ALERT'])) * 100).toFixed(0)
    },
  },
}
</script>

<style scoped>
.v-chip:before {
  background-color: none !important;
}
* >>> .v-progress-linear__determinate {
  border-radius: 24px !important;
}
</style>
