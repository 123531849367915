<template>
  <Layout>
    <v-form slot="content" class="text-center white">
      <h2 class="mb-4 mb-md-16 pb-2">{{ $t('maintenance') }}</h2>
      <v-container class="px-0">
        <v-row>
          <v-col class="pt-0" v-html="$t('maintenanceMsg')"></v-col>
        </v-row>
      </v-container>
    </v-form>
  </Layout>
</template>

<script>
import Layout from '@/layouts/Onboarding'

export default {
  name: 'Maintenance',
  components: {
    Layout,
  },
  created() {},
  data: () => ({}),
}
</script>
