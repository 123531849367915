<template>
  <Layout>
    <v-container class="pt-lg-15 pt-md-10 pb-0" fluid slot="title">
      <h2 :class="[{ 'font-size-30': $vuetify.breakpoint.mdAndUp }, { 'font-size-20': $vuetify.breakpoint.smAndDown }]">
        {{ $t('management') }}
      </h2>
    </v-container>
    <v-container class="pa-lg-4 pt-lg-10 pt-md-10 align-content-start" fluid fill-height slot="content">
      <v-row>
        <v-col>
          <v-sheet outlined color="grey100" rounded="lg" class="box">
            <v-card tag="section" rounded="lg" elevation="0" class="py-4">
              <v-tabs
                color="grey900"
                active-class="font-weight-bold"
                :slider-size="$vuetify.breakpoint.mdAndUp ? 4 : 3"
                :height="$vuetify.breakpoint.mdAndUp ? 48 : 36"
                show-arrows
              >
                <v-tab
                  :to="{ name: 'AreaManagement' }"
                  :class="[
                    'pa-0 mx-4 mx-md-10',
                    { 'font-size-18': $vuetify.breakpoint.mdAndUp },
                    { 'font-size-14': $vuetify.breakpoint.smAndDown },
                  ]"
                >
                  {{ $t('areaManagement') }}
                </v-tab>
                <v-tab
                  :to="{ name: 'StandardManagement' }"
                  :class="[
                    'pa-0 mx-4 mx-md-10',
                    { 'font-size-18': $vuetify.breakpoint.mdAndUp },
                    { 'font-size-14': $vuetify.breakpoint.smAndDown },
                  ]"
                  v-if="userInfo['USER_LEVEL'] === 'G'"
                >
                  {{ $t('standardManagement') }}
                </v-tab>
                <v-tab
                  :to="{ name: 'CompanyManagement' }"
                  :class="[
                    'pa-0 mx-4 mx-md-10',
                    { 'font-size-18': $vuetify.breakpoint.mdAndUp },
                    { 'font-size-14': $vuetify.breakpoint.smAndDown },
                  ]"
                  v-if="userInfo['USER_LEVEL'] === 'G'"
                >
                  {{ $t('companyManagement') }}
                </v-tab>
                <v-tab
                  :to="{ name: 'UserManagement' }"
                  :class="[
                    'pa-0 mx-4 mx-md-10',
                    { 'font-size-18': $vuetify.breakpoint.mdAndUp },
                    { 'font-size-14': $vuetify.breakpoint.smAndDown },
                  ]"
                >
                  {{ $t('userManagement') }}
                </v-tab>
              </v-tabs>
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0">
          <router-view />
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
import Layout from '@/layouts/Dashboard'
import { mapGetters } from 'vuex'

export default {
  name: 'Management',
  components: {
    Layout,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['userInfo']),
  },
  created() {
    // 일반 회원은 관리 페이지 접근 불가
    if (this.userInfo['USER_LEVEL'] === 'U') this.$router.replace({ name: 'Gills' })
  },
  methods: {},
}
</script>

<style scoped>
::v-deep.v-tab {
  letter-spacing: 0 !important;
  min-width: auto;
}
</style>
