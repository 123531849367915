<template>
  <v-app>
    <v-sheet class="header">
      <v-container class="pa-0">
        <v-app-bar
          dense
          flat
          color="transparent"
          :height="$vuetify.breakpoint.mdAndUp ? 64 : 56"
          :clipped-left="$vuetify.breakpoint.smAndDown"
        >
          <v-app-bar-nav-icon
            @click.stop="isShowSidebar = !isShowSidebar"
            v-if="$vuetify.breakpoint.smAndDown"
            class="grey800--text"
          />
          <v-toolbar-title>
            <h1
              :class="[
                { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                { 'font-size-16': $vuetify.breakpoint.smAndDown },
              ]"
              align-item-center
            >
              <router-link
                :to="{ name: userInfo['COMPANY_ID'] === 'GILLS' ? 'Dashboard' : 'Gills' }"
                class="grey300--text"
              >
                {{ userInfo ? userInfo['COMPANY_NAME'] : '' }}
              </router-link>
            </h1>
          </v-toolbar-title>
          <v-spacer />
          <v-card width="auto" elevation="0">
            <v-tabs
              centered
              :height="$vuetify.breakpoint.mdAndUp ? 64 : 56"
              active-class="v-tab--active font-weight-bold grey800--text"
              slider-color="grey800"
              v-show="$vuetify.breakpoint.mdAndUp"
              optional
              v-if="userInfo['COMPANY_ID'] === 'GILLS'"
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                v-for="tab in tabList"
                :key="tab.name"
                :to="{ name: tab.name }"
                :class="[
                  'font-weight-regular',
                  'grey300--text',
                  { 'font-size-18': $vuetify.breakpoint.mdAndUp },
                  { 'font-size-14': $vuetify.breakpoint.smAndDown },
                ]"
              >
                {{ $t(tab.translateKey) }}
              </v-tab>
            </v-tabs>
          </v-card>
          <v-spacer />
          <!-- <v-avatar color="grey300" size="28">
            <v-icon @click="getToken()" color="white">mdi-account</v-icon>
          </v-avatar> -->
          <v-dialog persistent v-model="dialogMyInfo" width="500" :fullscreen="$vuetify.breakpoint.smAndDown">
            <v-card>
              <v-card-title class="grey100">
                {{ isEditMyInfo ? $t('editMyInfo') : $t('myInfo') }}
              </v-card-title>

              <v-card-text v-if="editedMyInfo">
                <v-form ref="myInfoForm" @submit.prevent="setMyInfo" v-model="isMyInfoFormValid">
                  <v-container class="table black--text">
                    <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                      <v-col class="font-weight-bold" cols="4">
                        {{ $t('name') }}
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          dense
                          outlined
                          hide-details="auto"
                          color="grey600"
                          v-if="isEditMyInfo"
                          v-model="editedMyInfo['USER_NAME']"
                          maxlength="20"
                          class="rounded-lg"
                          :rules="[rules.required(editedMyInfo['USER_NAME'], 'userName')]"
                          validate-on-blur
                        />
                        <span v-else>{{ userInfo['USER_NAME'] }}</span>
                      </v-col>
                    </v-row>
                    <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                      <v-col class="font-weight-bold" cols="4">
                        {{ $t('email') }}
                      </v-col>
                      <v-col cols="8">
                        {{ userInfo['USER_EMAIL'] }}
                      </v-col>
                    </v-row>
                    <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                      <v-col class="font-weight-bold" cols="4">
                        {{ $t('userLevel') }}
                      </v-col>
                      <v-col cols="8">
                        {{ $t(getUserLevel(userInfo['USER_LEVEL'])) }}
                      </v-col>
                    </v-row>
                    <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                      <v-col class="font-weight-bold" cols="4">
                        {{ $t('tel') }}
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          dense
                          outlined
                          hide-details="auto"
                          color="grey600"
                          v-if="isEditMyInfo"
                          v-model="editedMyInfo['USER_TEL']"
                          maxlength="20"
                          @keyup="editedMyInfo['USER_TEL'] = editedMyInfo['USER_TEL'].replace(/[^0-9+-]/g, '')"
                          class="rounded-lg"
                          :rules="[rules.required(editedMyInfo['USER_TEL'], 'userTel')]"
                          validate-on-blur
                        />
                        <span v-else>{{ userInfo['USER_TEL'] }}</span>
                      </v-col>
                    </v-row>
                    <!-- <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                      <v-col>
                        <v-row>
                          <v-col class="font-weight-bold">{{ $t('chargeDevice') }}</v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4" class="py-1"></v-col>
                          <v-col cols="8" class="py-1"></v-col>
                        </v-row>
                      </v-col>
                    </v-row> -->
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-dialog persistent v-model="dialogUpdatePw" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="rounded-lg" color="grey600" outlined v-bind="attrs" v-on="on">
                      {{ $t('updatePw') }}
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="grey100">
                      {{ $t('updatePw') }}
                    </v-card-title>

                    <v-card-text>
                      <v-form ref="updatePwForm" @submit.prevent="updatePw" v-model="isUpdatePwFormValid">
                        <v-container class="table black--text">
                          <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                            <v-col class="font-weight-bold" cols="4">
                              {{ $t('oldPassword') }}
                            </v-col>
                            <v-col cols="8">
                              <v-text-field
                                v-model="oldPw"
                                dense
                                outlined
                                hide-details="auto"
                                color="grey600"
                                maxlength="20"
                                :type="isShowOldPw ? 'text' : 'password'"
                                @click:append="isShowOldPw = !isShowOldPw"
                                :append-icon="isShowOldPw ? 'mdi-eye-off' : 'mdi-eye'"
                                :rules="[rules.required(oldPw, 'oldPw'), rules.min]"
                                validate-on-blur
                                autofocus
                                class="rounded-lg"
                              />
                            </v-col>
                          </v-row>
                          <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                            <v-col class="font-weight-bold" cols="4">
                              {{ $t('newPassword') }}
                            </v-col>
                            <v-col cols="8">
                              <v-text-field
                                v-model="newPw"
                                dense
                                outlined
                                hide-details="auto"
                                color="grey600"
                                maxlength="20"
                                :type="isShowNewPw ? 'text' : 'password'"
                                @click:append="isShowNewPw = !isShowNewPw"
                                :append-icon="isShowNewPw ? 'mdi-eye-off' : 'mdi-eye'"
                                :rules="[rules.required(newPw, 'newPw'), rules.min]"
                                validate-on-blur
                                class="rounded-lg"
                              />
                            </v-col>
                          </v-row>
                          <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                            <v-col class="font-weight-bold" cols="4">
                              {{ $t('newPasswordConfirm') }}
                            </v-col>
                            <v-col cols="8">
                              <v-text-field
                                v-model="newPwConfirm"
                                dense
                                outlined
                                hide-details="auto"
                                color="grey600"
                                maxlength="20"
                                :type="isShowNewPwConfirm ? 'text' : 'password'"
                                @click:append="isShowNewPwConfirm = !isShowNewPwConfirm"
                                :append-icon="isShowNewPwConfirm ? 'mdi-eye-off' : 'mdi-eye'"
                                :rules="[
                                  rules.required(newPwConfirm, 'newPwConfirm'),
                                  rules.min,
                                  rules.sameNewPwConfirm,
                                ]"
                                validate-on-blur
                                class="rounded-lg"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn class="rounded-lg" color="grey600" outlined @click="dialogUpdatePw = false">
                        {{ $t('close') }}
                      </v-btn>
                      <v-btn
                        class="rounded-lg"
                        color="grey600"
                        elevation="0"
                        @click="updatePw"
                        :loading="isUpdatingPw"
                        :disabled="isUpdatingPw || !isUpdatePwFormValid"
                        :outlined="isUpdatingPw || !isUpdatePwFormValid"
                        :dark="!isUpdatingPw && isUpdatePwFormValid"
                      >
                        {{ $t('change') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-spacer />
                <v-btn class="rounded-lg" color="grey600" outlined @click="dialogMyInfo = false" v-if="!isEditMyInfo">
                  {{ $t('close') }}
                </v-btn>
                <v-btn class="rounded-lg" color="grey600" outlined @click="isEditMyInfo = false" v-else>
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn
                  class="rounded-lg"
                  color="grey600"
                  elevation="0"
                  dark
                  @click="isEditMyInfo = true"
                  v-if="!isEditMyInfo"
                >
                  {{ $t('edit') }}
                </v-btn>
                <v-btn
                  class="rounded-lg"
                  color="grey600"
                  elevation="0"
                  @click="setMyInfo"
                  :loading="isSavingMyInfo"
                  :disabled="isSavingMyInfo || !isMyInfoFormValid"
                  :outlined="isSavingMyInfo || !isMyInfoFormValid"
                  :dark="!isSavingMyInfo && isMyInfoFormValid"
                  v-if="isEditMyInfo"
                >
                  {{ $t('save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn large text v-bind="attrs" v-on="on" class="pr-3 rounded-lg">
                <v-avatar color="grey300" size="28">
                  <v-icon color="white">mdi-account</v-icon>
                </v-avatar>
                <span
                  :class="[
                    'mx-2 grey300--text',
                    { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                    { 'font-size-14': $vuetify.breakpoint.smAndDown },
                  ]"
                >
                  {{ userInfo ? userInfo.USER_NAME : '' }}
                </span>
                <v-icon color="grey300" dense>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-subheader class="text-center" v-if="userInfo['USER_LEVEL'] === 'G'">{{ $t('devMode') }}</v-subheader>
              <v-list-item @click="getToken" v-if="userInfo['USER_LEVEL'] === 'G'">
                <v-list-item-title>{{ $t('getToken') }}</v-list-item-title>
              </v-list-item>
              <v-divider v-if="userInfo['USER_LEVEL'] === 'G'" />
              <v-list-item @click="dialogMyInfo = true">
                <v-list-item-title>{{ $t('myInfo') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="$router.push({ name: 'Management' }).catch(() => {})"
                v-if="userInfo['USER_LEVEL'] !== 'U'"
              >
                <v-list-item-title>{{ $t('management') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="setSignOut">
                <v-list-item-title>{{ $t('signOut') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
      </v-container>
    </v-sheet>
    <v-navigation-drawer
      v-model="isShowSidebar"
      app
      :floating="!isShowSidebar"
      clipped
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-list class="pt-0 pb-0">
        <v-list-item
          class="px-6 py-1 font-weight-regular"
          v-for="tab in tabList"
          :key="tab.name"
          link
          :to="{ name: tab.name }"
          active-class="v-list-item--active font-weight-bold grey800 white--text"
        >
          <!-- <v-list-item-icon>
            <v-icon color="grey darken-2">{{ tab.icon }}</v-icon>
          </v-list-item-icon> -->
          <v-list-item-content>
            <v-list-item-title
              :class="[
                { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                { 'font-size-14': $vuetify.breakpoint.smAndDown },
              ]"
            >
              {{ $t(tab.translateKey) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="mt-md-16 mt-14">
      <transition name="fade">
        <v-container class="pa-0 pt-4 pt-md-0 align-content-start">
          <slot name="title" />
          <slot name="content" />
        </v-container>
      </transition>
    </v-main>
    <v-footer class="py-3" clipped-left>
      <span class="ml-auto overline">© {{ $day().format('YYYY') }} CARBONTECH</span>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import firebase from 'firebase/app'
import { setMyInfo } from '@/services/api'
import { isNull } from '@/services/common'
import { getUserLevel } from '@/services/common'

export default {
  name: 'DashboardLayout',
  data: () => ({
    isShowSidebar: false,
    isLoading: false,
    tabList: [
      { name: 'Dashboard', translateKey: 'dashboard' },
      { name: 'Gills', translateKey: 'gillsControl' },
      { name: 'Gsen', translateKey: 'measureAq' },
      { name: 'History', translateKey: 'history' },
    ],
    dialogMyInfo: false,
    isEditMyInfo: false,
    editedMyInfo: null,
    isMyInfoFormValid: false,
    isSavingMyInfo: false,
    dialogUpdatePw: false,
    isUpdatingPw: false,
    isShowOldPw: false,
    isShowNewPw: false,
    isShowNewPwConfirm: false,
    oldPw: '',
    newPw: '',
    newPwConfirm: '',
    isUpdatePwFormValid: false,
  }),
  computed: {
    ...mapGetters(['userInfo']),
    isSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown
    },
    rules() {
      let vm = this

      return {
        required: (value, type) => !isNull(value) || vm.$t('msgEnter' + type.charAt(0).toUpperCase() + type.slice(1)),
        min: (value) => (value ?? '').length >= 6 || vm.$t('msgPasswordMinLength'),
        sameNewPwConfirm: (value) =>
          vm.newPw === '' || (vm.newPw !== '' && value === vm.newPw) || vm.$t('msgPasswordNotSame'),
      }
    },
  },
  watch: {
    isSmAndDown() {
      if (!this.isSmAndDown) this.isShowSidebar = false
    },
    dialogMyInfo() {
      if (this.dialogMyInfo) this.editedMyInfo = { ...this.userInfo }
    },
    dialogUpdatePw() {
      if (!this.dialogUpdatePw && this.$refs.updatePwForm) this.$refs.updatePwForm.reset()
    },
  },
  methods: {
    ...mapActions(['setSignOut']),
    getUserLevel(level) {
      return getUserLevel(level)
    },
    async getToken() {
      let token = await firebase.auth().currentUser.getIdToken()
      window.prompt('token', token)
    },
    async setMyInfo() {
      this.isSavingMyInfo = true
      try {
        let result = await setMyInfo(this, this.editedMyInfo['USER_NAME'], this.editedMyInfo['USER_TEL'])
        this.$store.commit('setUserInfo', result.data)
        this.isEditMyInfo = false
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isSavingMyInfo = false
      }
    },
    async updatePw() {
      this.isUpdatingPw = true

      if (!this.$refs.updatePwForm.validate()) {
        this.isUpdatingPw = false
        return false
      }

      try {
        const user = firebase.auth().currentUser
        const credential = await firebase.auth.EmailAuthProvider.credential(user.email, this.oldPw)
        await user.reauthenticateWithCredential(credential)
        await user.updatePassword(this.newPw)
        this.dialogUpdatePw = false
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(error.message)
      } finally {
        this.isUpdatingPw = false
      }
    },
  },
}
</script>

<style scoped>
.container {
  max-width: 1600px !important;
}

.header {
  position: fixed;
  width: 100%;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: var(--v-grey100-base) !important;
  box-shadow: 0 3px 6px rgb(236 239 241 / 50%) !important;
  z-index: 1;
}

h1 {
  height: 100%;
  cursor: default;
}

.v-tab {
  letter-spacing: 0;
  margin: 0 8px 0;
}

* >>> h2,
* >>> h3 {
  color: var(--v-grey900-base) !important;
}

* >>> a {
  text-decoration: none;
}

* >>> .font-size-10 {
  font-size: 10px;
}

* >>> .font-size-12 {
  font-size: 12px;
}

* >>> .font-size-14 {
  font-size: 14px;
}

* >>> .font-size-16 {
  font-size: 16px;
}

* >>> .font-size-18 {
  font-size: 18px;
}

* >>> .font-size-20 {
  font-size: 20px;
}

* >>> .font-size-22 {
  font-size: 22px;
}

* >>> .font-size-24 {
  font-size: 24px;
}

* >>> .font-size-28 {
  font-size: 28px;
}

* >>> .font-size-30 {
  font-size: 30px;
}

* >>> .font-size-32 {
  font-size: 32px;
}

* >>> .font-size-40 {
  font-size: 40px;
}

* >>> .box {
  box-shadow: 0 2px 2px rgb(236 239 241 / 60%) !important;
}

* >>> span.state-bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 1px solid #fff;
  border-radius: 10px;
  margin-right: 10px;
}

* >>> span.state-bullet.working {
  border-color: var(--v-working-base) !important;
}

* >>> span.state-bullet.standby {
  border-color: var(--v-standby-base) !important;
}

* >>> span.state-bullet.lowbat {
  border-color: var(--v-lowbat-base) !important;
}

* >>> span.state-bullet.abnormal {
  border-color: var(--v-abnormal-base) !important;
}

* >>> span.state-bullet.nosignal {
  border-color: var(--v-nosignal-base) !important;
}

* >>> span.state-bullet.deactived {
  border-color: var(--v-nosignal-base) !important;
}

* >>> .table .thead.row {
  min-height: 60px;
  border-radius: 10px;
  background: var(--v-grey100-base) !important;
}

* >>> .table .tbody.row {
  min-height: 72px;
  border-bottom: 1px solid var(--v-grey100-base) !important;
}

* >>> .v-dialog .table .tbody.row {
  min-height: 56px;
}

* >>> .v-pagination .v-pagination__item,
* >>> .v-pagination .v-pagination__navigation {
  box-shadow: none !important;
  border: 1px solid var(--v-grey100-base) !important;
}

* >>> .v-pagination .v-pagination__item.v-pagination__item--active {
  border: 1px solid var(--v-primary-base) !important;
}

* >>> .v-pagination .v-pagination__navigation .v-icon {
  color: var(--v-grey300-base) !important;
}

* >>> .v-chip.v-size--x-small {
  padding: 10px 6px;
}
</style>
