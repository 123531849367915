var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"pt-lg-15 pt-md-10 pb-0",attrs:{"slot":"title","fluid":""},slot:"title"},[_c('h2',{class:[{ 'font-size-30': _vm.$vuetify.breakpoint.mdAndUp }, { 'font-size-20': _vm.$vuetify.breakpoint.smAndDown }]},[_vm._v(" "+_vm._s(_vm.$t('dashboard'))+" ")])]),_c('v-container',{staticClass:"pa-lg-4 pt-lg-15 pt-md-10 align-content-start",attrs:{"slot":"content","fluid":"","fill-height":""},slot:"content"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-sheet',{staticClass:"box",attrs:{"outlined":"","color":"grey100","rounded":"lg"}},[_c('v-card',{class:_vm.isLoadingGillsData ? 'd-flex justify-center align-center' : 'pa-4 pa-md-10',attrs:{"tag":"section","rounded":"lg","elevation":"0","height":_vm.$vuetify.breakpoint.mdAndUp ? '400' : '280',"loading":_vm.isLoadingGillsData}},[_c('template',{slot:"progress"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey800"}})],1),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingGillsData),expression:"!isLoadingGillsData"}],staticClass:"pa-0",attrs:{"fill-height":""}},[_c('v-row',{staticClass:"align-self-start"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{class:[
                      { 'font-size-20': _vm.$vuetify.breakpoint.mdAndUp },
                      { 'font-size-16': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.$t('gillsWorking'))+" ")])])],1),_c('v-row',{staticClass:"align-self-baseline"},[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('p',{staticClass:"working-rate mb-0 text-center grey900--text"},[_c('span',{class:[
                        'd-block font-weight-bold',
                        { 'font-size-40': _vm.$vuetify.breakpoint.mdAndUp },
                        { 'font-size-20': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.gillsWorkingRate)+"% ")]),_c('span',{class:[
                        'd-block',
                        { 'font-size-16': _vm.$vuetify.breakpoint.mdAndUp },
                        { 'font-size-14': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.$t('steadyWorking'))+" ")])]),_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingGillsData),expression:"!isLoadingGillsData"}],ref:"gillsChart",attrs:{"id":"gillsChart","width":"100%","height":"100%"}})]),_c('v-col',{staticClass:"py-0"},[_c('ul',{staticClass:"device-count"},_vm._l((Object.keys(_vm.gillsData)),function(item){return _c('li',{key:item,class:[{ 'mb-2': item === 'TOTAL' }]},[_c('dl',{class:[
                          'd-flex justify-space-between align-center grey900--text',
                          { 'mb-3': item === 'TOTAL' },
                          _vm.$vuetify.breakpoint.mdAndUp ? 'big' : 'small',
                          { 'font-size-16': _vm.$vuetify.breakpoint.mdAndUp },
                          { 'font-size-14': _vm.$vuetify.breakpoint.smAndDown } ]},[_c('dt',[(item !== 'TOTAL')?_c('span',{class:['state-bullet', item.toLowerCase()]}):_vm._e(),_c('span',{class:[{ 'font-weight-bold': item === 'TOTAL' }]},[_vm._v(" "+_vm._s(_vm.$t(item.toLowerCase()))+" ")])]),_c('dd',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.gillsData[item] + _vm.$t(_vm.gillsData[item] === 1 ? 'unitDevice' : 'unitDevices'))+" ")])])])}),0)])],1)],1)],2)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-sheet',{staticClass:"box",attrs:{"outlined":"","color":"grey100","rounded":"lg"}},[_c('v-card',{class:_vm.isLoadingGsenData ? 'd-flex justify-center align-center' : 'pa-4 pa-md-10',attrs:{"tag":"section","rounded":"lg","elevation":"0","height":_vm.$vuetify.breakpoint.mdAndUp ? '400' : '280',"loading":_vm.isLoadingGsenData}},[_c('template',{slot:"progress"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey800"}})],1),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingGsenData),expression:"!isLoadingGsenData"}],staticClass:"pa-0",attrs:{"fill-height":""}},[_c('v-row',{staticClass:"align-self-start"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{class:[
                      { 'font-size-20': _vm.$vuetify.breakpoint.mdAndUp },
                      { 'font-size-16': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.$t('gsenWorking'))+" ")])])],1),_c('v-row',{staticClass:"align-self-baseline"},[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('p',{staticClass:"working-rate mb-0 text-center grey900--text"},[_c('span',{class:[
                        'd-block font-weight-bold',
                        { 'font-size-40': _vm.$vuetify.breakpoint.mdAndUp },
                        { 'font-size-20': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.gsenWorkingRate)+"% ")]),_c('span',{class:[
                        'd-block',
                        { 'font-size-16': _vm.$vuetify.breakpoint.mdAndUp },
                        { 'font-size-14': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.$t('steadyWorking'))+" ")])]),_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingGsenData),expression:"!isLoadingGsenData"}],ref:"gsenChart",attrs:{"id":"gsenChart","width":"100%","height":"100%"}})]),_c('v-col',{staticClass:"py-0"},[_c('ul',{staticClass:"device-count"},_vm._l((Object.keys(_vm.gsenData)),function(item){return _c('li',{key:item,class:[{ 'mb-2': item === 'TOTAL' }]},[_c('dl',{class:[
                          'd-flex justify-space-between align-center grey900--text',
                          { 'mb-3': item === 'TOTAL' },
                          _vm.$vuetify.breakpoint.mdAndUp ? 'big' : 'small',
                          { 'font-size-16': _vm.$vuetify.breakpoint.mdAndUp },
                          { 'font-size-14': _vm.$vuetify.breakpoint.smAndDown } ]},[_c('dt',[(item !== 'TOTAL')?_c('span',{class:['state-bullet', item.toLowerCase()]}):_vm._e(),_c('span',{class:[{ 'font-weight-bold': item === 'TOTAL' }]},[_vm._v(" "+_vm._s(_vm.$t(item.toLowerCase()))+" ")])]),_c('dd',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.gsenData[item] + _vm.$t(_vm.gsenData[item] === 1 ? 'unitDevice' : 'unitDevices'))+" ")])])])}),0)])],1)],1)],2)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-sheet',{staticClass:"box",attrs:{"outlined":"","color":"grey100","rounded":"lg"}},[_c('v-card',{class:_vm.isLoadingAqData ? 'd-flex justify-center align-center' : 'pa-4 pa-md-10',attrs:{"tag":"section","rounded":"lg","elevation":"0","height":_vm.$vuetify.breakpoint.mdAndUp ? '400' : '280',"loading":_vm.isLoadingAqData}},[_c('template',{slot:"progress"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey800"}})],1),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingAqData),expression:"!isLoadingAqData"}],staticClass:"pa-0",attrs:{"fill-height":""}},[_c('v-row',{staticClass:"align-self-start"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{class:[
                      { 'font-size-20': _vm.$vuetify.breakpoint.mdAndUp },
                      { 'font-size-16': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.$t('airQuality'))+" ")])])],1),_c('v-row',{staticClass:"align-self-baseline",style:(_vm.$vuetify.breakpoint.mdAndUp ? 'height: 100%' : '')},[_c('v-col',{staticClass:"pa-4 pa-md-10 d-flex justify-center align-center",style:(_vm.$vuetify.breakpoint.mdAndUp ? 'height: 100%' : ''),attrs:{"cols":"12"}},[_c('div',{style:('position: relative; width: 100%; ' +
                      (_vm.$vuetify.breakpoint.mdAndUp ? 'height: 100%' : 'height: 180px'))},[_c('canvas',{ref:"aqChart",attrs:{"id":"aqChart","width":"100%","height":"100%"}})])])],1)],1)],2)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-sheet',{staticClass:"box",attrs:{"outlined":"","color":"grey100","rounded":"lg"}},[_c('v-card',{class:_vm.isLoadingHistoryList ? 'd-flex justify-center align-center' : '',attrs:{"tag":"section","rounded":"lg","elevation":"0","height":_vm.$vuetify.breakpoint.mdAndUp ? '400' : '280',"loading":_vm.isLoadingHistoryList}},[_c('template',{slot:"progress"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey800"}})],1),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingHistoryList),expression:"!isLoadingHistoryList"}],staticClass:"pa-0",attrs:{"fill-height":""}},[_c('v-row',{staticClass:"align-self-start pa-4 pa-md-10 pb-0 pb-md-0"},[_c('v-col',{staticClass:"d-flex justify-space-between align-baseline",attrs:{"cols":"12"}},[_c('h3',{class:[
                      { 'font-size-20': _vm.$vuetify.breakpoint.mdAndUp },
                      { 'font-size-16': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.$t('history'))+" ")]),_c('router-link',{staticClass:"font-size-12 grey300--text",attrs:{"to":{ name: 'History' }}},[_vm._v("자세히 보기")])],1)],1),_c('v-row',{staticClass:"mt-6 align-self-baseline",style:('height: calc(100% - ' + (_vm.$vuetify.breakpoint.mdAndUp ? '94' : '64') + 'px)'),attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-4 py-0 px-md-10 py-md-0 overflow-auto",staticStyle:{"height":"100%"},attrs:{"cols":"12"}},[(!_vm.isLoadingHistoryList && _vm.historyList.length > 0)?_c('ul',{staticClass:"history-list"},_vm._l((_vm.$vuetify.breakpoint.mdAndUp ? _vm.historyList : _vm.historyList.slice(0, 5)),function(history){return _c('li',{key:history['HISTORY_ID']},[_c('p',{class:[
                          'memo grey900--text mb-1',
                          { 'font-size-16': _vm.$vuetify.breakpoint.mdAndUp },
                          { 'font-size-14': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(history['HISTORY_MEMO'])+" ")]),_c('p',{class:[
                          'date font-size-14 grey300--text mb-6',
                          { 'font-size-14': _vm.$vuetify.breakpoint.mdAndUp },
                          { 'font-size-12': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.$day().to(_vm.$day(history['HISTORY_DT'])))+" ")])])}),0):_c('v-card',{staticClass:"grey400--text py-16 d-flex align-center justify-center",attrs:{"height":"100%","elevation":"0"}},[_c('span',[_vm._v(_vm._s(_vm.$t('noData')))])])],1)],1)],1)],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }