<template>
  <Layout>
    <v-container class="pt-lg-15 pt-md-10 pb-0" fluid slot="title" v-show="false">
      <h2 :class="[{ 'font-size-30': $vuetify.breakpoint.mdAndUp }, { 'font-size-20': $vuetify.breakpoint.smAndDown }]">
        {{ $t('dashboard') }}
      </h2>
    </v-container>
    <v-container class="pa-lg-4 pt-lg-15 pt-md-10 align-content-start" fluid fill-height slot="content">
      <v-row>
        <v-col cols="12" md="6">
          <v-sheet outlined color="grey100" rounded="lg" class="box">
            <v-card
              tag="section"
              rounded="lg"
              elevation="0"
              :height="$vuetify.breakpoint.mdAndUp ? '400' : '280'"
              :loading="isLoadingGillsData"
              :class="isLoadingGillsData ? 'd-flex justify-center align-center' : 'pa-4 pa-md-10'"
            >
              <template slot="progress">
                <v-progress-circular indeterminate color="grey800"></v-progress-circular>
              </template>
              <v-container fill-height class="pa-0" v-show="!isLoadingGillsData">
                <v-row class="align-self-start">
                  <v-col cols="12">
                    <h3
                      :class="[
                        { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                        { 'font-size-16': $vuetify.breakpoint.smAndDown },
                      ]"
                    >
                      {{ $t('gillsWorking') }}
                    </h3>
                  </v-col>
                </v-row>
                <v-row class="align-self-baseline">
                  <v-col class="d-flex justify-center align-center">
                    <p class="working-rate mb-0 text-center grey900--text">
                      <span
                        :class="[
                          'd-block font-weight-bold',
                          { 'font-size-40': $vuetify.breakpoint.mdAndUp },
                          { 'font-size-20': $vuetify.breakpoint.smAndDown },
                        ]"
                      >
                        {{ gillsWorkingRate }}%
                      </span>
                      <span
                        :class="[
                          'd-block',
                          { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                          { 'font-size-14': $vuetify.breakpoint.smAndDown },
                        ]"
                      >
                        {{ $t('steadyWorking') }}
                      </span>
                    </p>
                    <canvas id="gillsChart" ref="gillsChart" width="100%" height="100%" v-show="!isLoadingGillsData" />
                  </v-col>
                  <v-col class="py-0">
                    <ul class="device-count">
                      <li v-for="item in Object.keys(gillsData)" :key="item" :class="[{ 'mb-2': item === 'TOTAL' }]">
                        <dl
                          :class="[
                            'd-flex justify-space-between align-center grey900--text',
                            { 'mb-3': item === 'TOTAL' },
                            $vuetify.breakpoint.mdAndUp ? 'big' : 'small',
                            { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                            { 'font-size-14': $vuetify.breakpoint.smAndDown },
                          ]"
                        >
                          <dt>
                            <span :class="['state-bullet', item.toLowerCase()]" v-if="item !== 'TOTAL'"></span>
                            <span :class="[{ 'font-weight-bold': item === 'TOTAL' }]">
                              {{ $t(item.toLowerCase()) }}
                            </span>
                          </dt>
                          <dd class="font-weight-bold">
                            {{ gillsData[item] + $t(gillsData[item] === 1 ? 'unitDevice' : 'unitDevices') }}
                          </dd>
                        </dl>
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="6">
          <v-sheet outlined color="grey100" rounded="lg" class="box">
            <v-card
              tag="section"
              rounded="lg"
              elevation="0"
              :height="$vuetify.breakpoint.mdAndUp ? '400' : '280'"
              :loading="isLoadingGsenData"
              :class="isLoadingGsenData ? 'd-flex justify-center align-center' : 'pa-4 pa-md-10'"
            >
              <template slot="progress">
                <v-progress-circular indeterminate color="grey800"></v-progress-circular>
              </template>
              <v-container fill-height class="pa-0" v-show="!isLoadingGsenData">
                <v-row class="align-self-start">
                  <v-col cols="12">
                    <h3
                      :class="[
                        { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                        { 'font-size-16': $vuetify.breakpoint.smAndDown },
                      ]"
                    >
                      {{ $t('gsenWorking') }}
                    </h3>
                  </v-col>
                </v-row>
                <v-row class="align-self-baseline">
                  <v-col class="d-flex justify-center align-center">
                    <p class="working-rate mb-0 text-center grey900--text">
                      <span
                        :class="[
                          'd-block font-weight-bold',
                          { 'font-size-40': $vuetify.breakpoint.mdAndUp },
                          { 'font-size-20': $vuetify.breakpoint.smAndDown },
                        ]"
                      >
                        {{ gsenWorkingRate }}%
                      </span>
                      <span
                        :class="[
                          'd-block',
                          { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                          { 'font-size-14': $vuetify.breakpoint.smAndDown },
                        ]"
                      >
                        {{ $t('steadyWorking') }}
                      </span>
                    </p>
                    <canvas id="gsenChart" ref="gsenChart" width="100%" height="100%" v-show="!isLoadingGsenData" />
                  </v-col>
                  <v-col class="py-0">
                    <ul class="device-count">
                      <li v-for="item in Object.keys(gsenData)" :key="item" :class="[{ 'mb-2': item === 'TOTAL' }]">
                        <dl
                          :class="[
                            'd-flex justify-space-between align-center grey900--text',
                            { 'mb-3': item === 'TOTAL' },
                            $vuetify.breakpoint.mdAndUp ? 'big' : 'small',
                            { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                            { 'font-size-14': $vuetify.breakpoint.smAndDown },
                          ]"
                        >
                          <dt>
                            <span :class="['state-bullet', item.toLowerCase()]" v-if="item !== 'TOTAL'"></span>
                            <span :class="[{ 'font-weight-bold': item === 'TOTAL' }]">
                              {{ $t(item.toLowerCase()) }}
                            </span>
                          </dt>
                          <dd class="font-weight-bold">
                            {{ gsenData[item] + $t(gsenData[item] === 1 ? 'unitDevice' : 'unitDevices') }}
                          </dd>
                        </dl>
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8">
          <v-sheet outlined color="grey100" rounded="lg" class="box">
            <v-card
              tag="section"
              rounded="lg"
              elevation="0"
              :height="$vuetify.breakpoint.mdAndUp ? '400' : '280'"
              :loading="isLoadingAqData"
              :class="isLoadingAqData ? 'd-flex justify-center align-center' : 'pa-4 pa-md-10'"
            >
              <template slot="progress">
                <v-progress-circular indeterminate color="grey800"></v-progress-circular>
              </template>
              <v-container fill-height class="pa-0" v-show="!isLoadingAqData">
                <v-row class="align-self-start">
                  <v-col cols="12">
                    <h3
                      :class="[
                        { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                        { 'font-size-16': $vuetify.breakpoint.smAndDown },
                      ]"
                    >
                      {{ $t('airQuality') }}
                    </h3>
                  </v-col>
                </v-row>
                <v-row class="align-self-baseline" :style="$vuetify.breakpoint.mdAndUp ? 'height: 100%' : ''">
                  <v-col
                    cols="12"
                    class="pa-4 pa-md-10 d-flex justify-center align-center"
                    :style="$vuetify.breakpoint.mdAndUp ? 'height: 100%' : ''"
                  >
                    <div
                      :style="
                        'position: relative; width: 100%; ' +
                        ($vuetify.breakpoint.mdAndUp ? 'height: 100%' : 'height: 180px')
                      "
                    >
                      <canvas id="aqChart" ref="aqChart" width="100%" height="100%" />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="4">
          <v-sheet outlined color="grey100" rounded="lg" class="box">
            <v-card
              tag="section"
              rounded="lg"
              elevation="0"
              :height="$vuetify.breakpoint.mdAndUp ? '400' : '280'"
              :loading="isLoadingHistoryList"
              :class="isLoadingHistoryList ? 'd-flex justify-center align-center' : ''"
            >
              <template slot="progress">
                <v-progress-circular indeterminate color="grey800"></v-progress-circular>
              </template>
              <v-container fill-height class="pa-0" v-show="!isLoadingHistoryList">
                <v-row class="align-self-start pa-4 pa-md-10 pb-0 pb-md-0">
                  <v-col cols="12" class="d-flex justify-space-between align-baseline">
                    <h3
                      :class="[
                        { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                        { 'font-size-16': $vuetify.breakpoint.smAndDown },
                      ]"
                    >
                      {{ $t('history') }}
                    </h3>
                    <router-link :to="{ name: 'History' }" class="font-size-12 grey300--text">자세히 보기</router-link>
                  </v-col>
                </v-row>
                <v-row
                  class="mt-6 align-self-baseline"
                  no-gutters
                  :style="'height: calc(100% - ' + ($vuetify.breakpoint.mdAndUp ? '94' : '64') + 'px)'"
                >
                  <v-col cols="12" class="px-4 py-0 px-md-10 py-md-0 overflow-auto" style="height: 100%">
                    <ul class="history-list" v-if="!isLoadingHistoryList && historyList.length > 0">
                      <li
                        v-for="history in $vuetify.breakpoint.mdAndUp ? historyList : historyList.slice(0, 5)"
                        :key="history['HISTORY_ID']"
                      >
                        <p
                          :class="[
                            'memo grey900--text mb-1',
                            { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                            { 'font-size-14': $vuetify.breakpoint.smAndDown },
                          ]"
                        >
                          {{ history['HISTORY_MEMO'] }}
                        </p>
                        <p
                          :class="[
                            'date font-size-14 grey300--text mb-6',
                            { 'font-size-14': $vuetify.breakpoint.mdAndUp },
                            { 'font-size-12': $vuetify.breakpoint.smAndDown },
                          ]"
                        >
                          {{ $day().to($day(history['HISTORY_DT'])) }}
                        </p>
                      </li>
                    </ul>
                    <v-card
                      height="100%"
                      elevation="0"
                      class="grey400--text py-16 d-flex align-center justify-center"
                      v-else
                    >
                      <span>{{ $t('noData') }}</span>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
import Layout from '@/layouts/Dashboard'
import { getDashboardGills, getDashboardGsen, getDashboardAq, getHistoryList } from '@/services/api'
import Chart from 'chart.js/auto'
import { Tooltip } from 'chart.js'

export default {
  name: 'Dashboard',
  components: {
    Layout,
  },
  data: () => ({
    updateInterval: null,
    updateIntervalTime: 30000,
    gillsData: [],
    gsenData: [],
    aqData: [],
    historyList: [],
    isLoadingGillsData: true,
    isLoadingGsenData: true,
    isLoadingAqData: true,
    isLoadingHistoryList: true,
    gillsWorkingRate: 0,
    gsenWorkingRate: 0,
    gillsChart: null,
    gsenChart: null,
    aqChart: null,
    stateList: ['safe', 'warning', 'alert'], // 'danger', 'error'
  }),
  computed: {
    measureItems() {
      return Object.keys(this.aqData).filter((item) => item !== 'TOTAL')
    },
    aqChartData() {
      return this.stateList.map((state) => ({
        label: this.$t(state),
        data: Object.entries(this.aqData)
          .filter((item) => item[0] !== 'TOTAL')
          .map((item) => item[1][state.toUpperCase()] + (state === 'alert' ? item[1]['DANGER'] : 0)),
        backgroundColor: this.$vuetify.theme.themes.light[state],
        order: this.stateList.length - this.stateList.indexOf(state),
      }))
    },
  },
  watch: {
    '$vuetify.breakpoint.name'() {
      // 차트 재생성
      if (this.gillsChart !== null && (this.gillsChart.canvas ?? null) !== null) this.gillsChart.destroy()
      if (this.gsenChart !== null && (this.gsenChart.canvas ?? null) !== null) this.gsenChart.destroy()
      if (this.aqChart !== null && (this.aqChart.canvas ?? null) !== null) this.aqChart.destroy()

      setTimeout(() => {
        this.createGillsChart()
        this.createGsenChart()
        this.createAqChart()
      }, 500)
    },
  },
  created() {
    this.getGillsData()
    this.getGsenData()
    this.getAqData()
    this.getHistoryList()

    this.updateInterval = setInterval(() => {
      this.getGillsData(true)
      this.getGsenData(true)
      this.getAqData(true)
      this.getHistoryList(true)
    }, this.updateIntervalTime)
  },
  beforeDestroy() {
    clearInterval(this.updateInterval)
  },
  methods: {
    async getGillsData(update = false) {
      if (!update) this.isLoadingGillsData = true
      try {
        let result = await getDashboardGills(this)
        this.gillsData = result.data
        this.gillsWorkingRate = (
          (parseInt(this.gillsData['WORKING']) / parseInt(this.gillsData['TOTAL'])) *
          100
        ).toFixed(0)
        if (this.gillsWorkingRate === 'NaN') this.gillsWorkingRate = 0
        if (update) this.gillsChart.update()
        else await this.createGillsChart()
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingGillsData = false
      }
    },
    async getGsenData(update = false) {
      if (!update) this.isLoadingGsenData = true
      try {
        let result = await getDashboardGsen(this)
        this.gsenData = result.data

        this.gsenWorkingRate = ((parseInt(this.gsenData['WORKING']) / parseInt(this.gsenData['TOTAL'])) * 100).toFixed(
          0
        )
        if (this.gsenWorkingRate === 'NaN') this.gsenWorkingRate = 0
        if (update) this.gsenChart.update()
        else await this.createGsenChart()
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingGsenData = false
      }
    },
    async getAqData(update = false) {
      if (!update) this.isLoadingAqData = true
      try {
        let result = await getDashboardAq(this)
        this.aqData = result.data
        if (update) this.aqChart.update()
        else await this.createAqChart()
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingAqData = false
      }
    },
    async getHistoryList(update = false) {
      if (!update) this.isLoadingHistoryList = true
      try {
        let result = await getHistoryList(this)
        this.historyList = result.data['DATA']
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingHistoryList = false
      }
    },
    async createGillsChart() {
      this.gillsChart = new Chart(this.$refs.gillsChart, {
        type: 'doughnut',
        data: {
          labels: Object.keys(this.gillsData)
            .filter((item) => item !== 'TOTAL')
            .map((state) => {
              return this.$t(state.toLowerCase())
            }),
          datasets: [
            {
              data: Object.entries(this.gillsData)
                .filter((item) => item[0] !== 'TOTAL')
                .map((item) => item[1]),
              borderWidth: 1,
              borderColor: Object.keys(this.gillsData)
                .filter((item) => item !== 'TOTAL')
                .map((state) => {
                  let color

                  if (state === 'DEACTIVED') color = this.$vuetify.theme.themes.light['grey200']
                  else color = this.$vuetify.theme.themes.light[state.toLowerCase()]

                  return color
                }),
              backgroundColor: Object.keys(this.gillsData)
                .filter((item) => item !== 'TOTAL')
                .map((state) => {
                  return this.$vuetify.theme.themes.light[state.toLowerCase()]
                }),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          cutout: '90%',
          radius: '90%',
          spacing: 3,
          elements: {
            arc: {
              borderRadius: 20,
            },
          },
          events: [],
          plugins: { legend: { display: false } },
          animation: false,
        },
      })
    },
    async createGsenChart() {
      this.gsenChart = new Chart(this.$refs.gsenChart, {
        type: 'doughnut',
        data: {
          labels: Object.keys(this.gsenData)
            .filter((item) => item !== 'TOTAL')
            .map((state) => {
              return this.$t(state.toLowerCase())
            }),
          datasets: [
            {
              data: Object.entries(this.gsenData)
                .filter((item) => item[0] !== 'TOTAL')
                .map((item) => item[1]),
              borderWidth: 1,
              borderColor: Object.keys(this.gsenData)
                .filter((item) => item !== 'TOTAL')
                .map((state) => {
                  let color

                  if (state === 'DEACTIVED') color = this.$vuetify.theme.themes.light['grey200']
                  else color = this.$vuetify.theme.themes.light[state.toLowerCase()]

                  return color
                }),
              backgroundColor: Object.keys(this.gsenData)
                .filter((item) => item !== 'TOTAL')
                .map((state) => {
                  return this.$vuetify.theme.themes.light[state.toLowerCase()]
                }),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          cutout: '90%',
          radius: '90%',
          spacing: 3,
          elements: {
            arc: {
              borderRadius: 20,
            },
          },
          events: [],
          plugins: { legend: { display: false } },
          animation: false,
        },
      })
    },
    async createAqChart() {
      Tooltip.positioners.custom = function (items) {
        const pos = Tooltip.positioners.average(items)

        // Happens when nothing is found
        if (pos === false) {
          return false
        }

        return {
          x: pos.x,
          y: pos.y + 80,
          yAlign: 'bottom',
        }
      }

      const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div')

        if (!tooltipEl) {
          tooltipEl = document.createElement('div')
          tooltipEl.style.whiteSpace = 'nowrap'
          tooltipEl.style.background = '#fff'
          tooltipEl.style.border = '1px solid ' + this.$vuetify.theme.themes.light['grey100']
          tooltipEl.style.borderRadius = '10px'
          tooltipEl.style.fontSize = '14px'
          tooltipEl.style.opacity = 1
          tooltipEl.style.pointerEvents = 'none'
          tooltipEl.style.position = 'absolute'
          tooltipEl.style.transform = 'translate(-50%, 0)'
          tooltipEl.style.transition = 'all .1s ease'

          const table = document.createElement('table')
          table.style.margin = '0px'

          tooltipEl.appendChild(table)
          chart.canvas.parentNode.appendChild(tooltipEl)
        }

        return tooltipEl
      }

      const externalTooltipHandler = (context) => {
        // Tooltip Element
        const { chart, tooltip } = context
        const tooltipEl = getOrCreateTooltip(chart)

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0
          return
        }

        // Set Text
        if (tooltip.body) {
          const titleLines = tooltip.title || []
          const bodyLines = tooltip.body.map((b) => b.lines)

          const tableHead = document.createElement('thead')

          titleLines.forEach((title) => {
            const tr = document.createElement('tr')
            tr.style.borderWidth = 0

            const th = document.createElement('th')
            th.style.borderWidth = 0
            const text = document.createTextNode(title)

            th.appendChild(text)
            tr.appendChild(th)
            tableHead.appendChild(tr)
          })

          const tableBody = document.createElement('tbody')
          bodyLines.reverse().forEach((body, i) => {
            const colors = tooltip.labelColors[bodyLines.length - 1 - i]

            const span = document.createElement('span')
            span.style.background = colors.backgroundColor
            span.style.borderColor = colors.borderColor
            span.style.borderWidth = '1px'
            span.style.marginRight = '10px'
            span.style.height = '8px'
            span.style.width = '8px'
            span.style.display = 'inline-block'
            span.style.borderRadius = '8px'

            const tr = document.createElement('tr')
            tr.style.backgroundColor = 'inherit'
            tr.style.borderWidth = 0

            const td = document.createElement('td')
            td.style.borderWidth = 0

            body[0] = body[0].replace(': ', '　')

            const text = document.createTextNode(body)

            td.appendChild(span)
            td.appendChild(text)
            tr.appendChild(td)
            tableBody.appendChild(tr)
          })

          const tableRoot = tooltipEl.querySelector('table')

          // Remove old children
          while (tableRoot.firstChild) {
            tableRoot.firstChild.remove()
          }

          // Add new children
          tableRoot.appendChild(tableHead)
          tableRoot.appendChild(tableBody)
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1
        tooltipEl.style.left = positionX + tooltip.caretX + 'px'
        tooltipEl.style.top = positionY + tooltip.caretY + 'px'
        // tooltipEl.style.font = tooltip.options.bodyFont.string
        tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
      }

      this.aqChart = new Chart(this.$refs.aqChart, {
        type: 'bar',
        data: {
          labels: this.measureItems.map((item) => {
            return this.$t(item)
          }),
          datasets: this.aqChartData,
        },
        options: {
          barRoundness: 1,
          barThickness: 16,
          borderRadius: 16,
          inflateAmount: 0,
          // borderSkipped: false,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
                drawBorder: false,
              },
              stacked: true,
              ticks: {
                color: this.$vuetify.theme.themes.light['grey900'],
                font: {
                  size: 14,
                  weight: '500',
                },
              },
            },
            y: {
              ticks: {
                display: false,
                stepSize: this.$refs.aqChart['TOTAL'] > 5 ? 5 : 1,
              },
              grid: {
                drawBorder: false,
                color: this.$vuetify.theme.themes.light['grey100'],
              },
              // display: false,
              stacked: true,
              beginAtZero: true,
            },
          },
          plugins: {
            legend: { display: false },
            tooltip: {
              enabled: false,
              position: 'nearest',
              mode: 'x',
              external: externalTooltipHandler,
            },
          },
          animation: false,
        },
      })
    },
  },
}
</script>
<style scoped>
.working-rate {
  position: absolute;
}

ul.device-count {
  list-style: none !important;
  padding-left: 0 !important;
}

ul.device-count li dl.big {
  height: 40px;
}

ul.device-count li dl.small {
  height: 28px;
}

ul.device-count li:first-child {
  border-bottom: 1px solid var(--v-grey100-base);
}

ul.aq-list {
  list-style: none !important;
  padding-left: 0 !important;
}

ul.history-list {
  list-style: none !important;
  padding-left: 0 !important;
}

ul.history-list .memo {
  letter-spacing: -0.2px;
}
</style>
