var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('v-container',{staticClass:"pt-lg-15 pt-md-10 pb-0",attrs:{"slot":"title","fluid":""},slot:"title"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('h2',{class:[
            { 'font-size-30': _vm.$vuetify.breakpoint.mdAndUp },
            { 'font-size-20': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.$t('gillsControl'))+" "),(_vm.gillsListPage !== null)?_c('span',{class:[
              'ml-3 font-weight-regular',
              { 'font-size-16': _vm.$vuetify.breakpoint.mdAndUp },
              { 'font-size-14': _vm.$vuetify.breakpoint.smAndDown } ],domProps:{"innerHTML":_vm._s(_vm.$t('totalGillsCount', { count: _vm.gillsListPage['totalCount'] }))}}):_vm._e()])]),_c('v-spacer'),_c('v-col',{staticClass:"d-flex align-end justify-end",attrs:{"cols":"12","md":"auto"}},[_c('span',{class:[
            'grey600--text',
            { 'font-size-14': _vm.$vuetify.breakpoint.mdAndUp },
            { 'font-size-12': _vm.$vuetify.breakpoint.smAndDown },
            { 'mt-2 mb-1': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.lastUpdatedTime ? _vm.$t('lastUpdate') + _vm.lastUpdatedTime : '')+" ")])])],1)],1),_c('v-container',{staticClass:"pa-lg-4 pt-lg-10 pt-md-10 align-content-start",attrs:{"slot":"content","fluid":"","fill-height":""},slot:"content"},[_c('v-row',[_c('v-col',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.gillsList),function(gills){return _c('v-col',{key:gills['GSEN_UDID'],staticClass:"pa-3",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-sheet',{staticClass:"box",attrs:{"outlined":"","color":"grey100","rounded":"lg"}},[_c('v-card',{staticClass:"pa-4 pa-md-10",attrs:{"tag":"section","rounded":"lg","elevation":"0","to":{ name: 'GillsDetail', params: { gillsUdid: gills['GILLS_UDID'] } }}},[_c('v-container',{staticClass:"pa-0",attrs:{"fill-height":""}},[_c('v-row',{staticClass:"align-self-start"},[_c('v-col',{class:['col-auto', { 'pa-2': _vm.$vuetify.breakpoint.smAndDown }]},[_c('h3',{class:[
                            { 'font-size-20': _vm.$vuetify.breakpoint.mdAndUp },
                            { 'font-size-16': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(gills['GILLS_NAME'])+" ")])]),_c('v-spacer'),_c('v-col',{class:['text-right', { 'pa-2': _vm.$vuetify.breakpoint.smAndDown }]},[_c('v-chip',{attrs:{"label":"","small":_vm.$vuetify.breakpoint.mdAndUp,"x-small":_vm.$vuetify.breakpoint.smAndDown,"dark":"","color":(gills['STATE'] === 'ERROR' ? 'NOSIGNAL' : gills['STATE']).toLowerCase()}},[_vm._v(" "+_vm._s(_vm.$t((gills['STATE'] === 'WORKING' ? 'ONLINE' : gills['STATE']).toLowerCase()))+" ")])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0"},[(_vm.gillsListPage !== null)?_c('span',{staticClass:"ont-weight-regular font-size-12 grey300--text"},[_vm._v(" "+_vm._s(gills['AREA_A']['AREA_A_NAME'])+" > "+_vm._s(gills['AREA_B']['AREA_B_NAME'])+" > "+_vm._s(gills['AREA_C']['AREA_C_NAME'])+" ")]):_vm._e()])],1),_c('v-row',{staticClass:"align-self-baseline"},[_c('v-col',{staticClass:"d-flex pa-0"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-container',_vm._l((gills['GILLS_DEV_VER'] !== 'G2-REV2.0' ? _vm.itemListG1 : _vm.itemListG2),function(item){return _c('v-row',{key:item},[_c('v-col',[_c('v-container',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{class:[
                                      ((_vm.getGsenStatus(
                                        gills['GSEN'].length === 0 ||
                                          (gills['GSEN'].length > 0 && gills['GSEN'][0]['GSEN_DATA_' + item] === null)
                                          ? 'E'
                                          : gills['GSEN'][0]['GSEN_DATA_' + item + '_STATUS']
                                      )) + "--text"),
                                      'font-weight-bold',
                                      { 'font-size-20': _vm.$vuetify.breakpoint.mdAndUp },
                                      { 'font-size-16': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.$t( _vm.getGsenStatusWord( gills['GSEN'].length === 0 || (gills['GSEN'].length > 0 && gills['GSEN'][0]['GSEN_DATA_' + item] === null) ? 'E' : gills['GSEN'][0]['GSEN_DATA_' + item + '_STATUS'] ) ))+" ")]),_c('v-col',{staticClass:"text-right font-weight-medium"},[_c('p',{class:[
                                        'ma-0',
                                        { 'font-size-16': _vm.$vuetify.breakpoint.mdAndUp },
                                        { 'font-size-12': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.$t(item))+" ")]),_c('p',{class:[
                                        'ma-0 grey300--text',
                                        { 'font-size-12': _vm.$vuetify.breakpoint.mdAndUp },
                                        { 'font-size-10': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.$store.getters.standardInfo[item]['STD_UNIT'])+" ")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 d-flex align-center",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"d-flex align-center justify-center font-weight-medium",staticStyle:{"width":"45px"},attrs:{"x-small":"","dark":"","color":_vm.getGsenStatus(
                                          gills['GSEN'].length === 0 ||
                                            (gills['GSEN'].length > 0 &&
                                              gills['GSEN'][0]['GSEN_DATA_' + item] === null)
                                            ? 'E'
                                            : gills['GSEN'][0]['GSEN_DATA_' + item + '_STATUS']
                                        )}},[_vm._v(" "+_vm._s(gills['GSEN'].length === 0 || (gills['GSEN'].length > 0 && gills['GSEN'][0]['GSEN_DATA_' + item] === null) ? '-' : gills['GSEN'][0]['GSEN_DATA_' + item] >= 999 ? '999+' : item.indexOf('PM') > -1 || item === 'TEMP' ? gills['GSEN'][0]['GSEN_DATA_' + item].toFixed(1) : gills['GSEN'][0]['GSEN_DATA_' + item].toFixed(0))+" ")]),_c('span',[_vm._v(" ")])],1),_c('v-col',{staticClass:"pa-0 d-flex align-center"},[_c('v-progress-linear',{staticClass:"rounded-xl grey100",attrs:{"color":_vm.getGsenStatus(
                                          gills['GSEN'].length === 0 ||
                                            (gills['GSEN'].length > 0 &&
                                              gills['GSEN'][0]['GSEN_DATA_' + item] === null)
                                            ? 'E'
                                            : gills['GSEN'][0]['GSEN_DATA_' + item + '_STATUS']
                                        ),"buffer-value":"0","value":_vm.getPercent(
                                          item,
                                          gills['GSEN'].length === 0 ||
                                            (gills['GSEN'].length > 0 &&
                                              gills['GSEN'][0]['GSEN_DATA_' + item] === null)
                                            ? 0
                                            : gills['GSEN'][0]['GSEN_DATA_' + item]
                                        ),"height":"16"}})],1)],1)],1)],1)],1)}),1):_c('v-container',[_c('v-row',{class:[{ 'flex-nowrap overflow-x-auto': _vm.$vuetify.breakpoint.smAndDown }]},[_vm._l((gills['GILLS_DEV_VER'] !== 'G2-REV2.0' ? _vm.itemListG1 : _vm.itemListG2),function(item){return _c('v-col',{key:item,staticClass:"pa-2"},[_c('v-sheet',{staticClass:"d-flex justify-center align-center mx-auto",attrs:{"rounded":"lg","width":"68","height":"68","color":_vm.getGsenStatus(
                                    gills['GSEN'].length === 0 ||
                                      (gills['GSEN'].length > 0 && gills['GSEN'][0]['GSEN_DATA_' + item] === null)
                                      ? 'E'
                                      : gills['GSEN'][0]['GSEN_DATA_' + item + '_STATUS']
                                  ),"dark":""}},[_c('p',{staticClass:"ma-0 text-center"},[_c('span',{staticClass:"font-size-16 font-weight-bold"},[_vm._v(" "+_vm._s(gills['GSEN'].length === 0 || (gills['GSEN'].length > 0 && gills['GSEN'][0]['GSEN_DATA_' + item] === null) ? '-' : gills['GSEN'][0]['GSEN_DATA_' + item].toFixed(0))+" ")]),_c('br'),_c('span',{staticClass:"font-size-12"},[_vm._v(_vm._s(_vm.$t(item)))])])])],1)}),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-responsive',{staticClass:"pa-2"}):_vm._e()],2)],1)],1)],1)],1)],1)],1)],1)}),1),(_vm.gillsListPage !== null && _vm.gillsListPage['totalCount'] > 0)?_c('v-row',[_c('v-col',[_c('v-pagination',{attrs:{"length":_vm.gillsListPage['totalPage']},on:{"input":_vm.getGillsList},model:{value:(_vm.gillsPage),callback:function ($$v) {_vm.gillsPage=$$v},expression:"gillsPage"}})],1)],1):_c('v-row',[_c('v-col',{staticClass:"text-center grey400--text py-16"},[(_vm.isLoadingGillsList)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_c('span',[_vm._v(_vm._s(_vm.$t('noData')))])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }