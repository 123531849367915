<template>
  <v-form ref="callForm" @submit.prevent>
    <v-container fill-height fluid class="pa-0">
      <v-row no-gutters v-if="$vuetify.breakpoint.mdAndUp">
        <v-col>
          <v-row no-gutters>
            <v-col>
              <v-select
                :items="[
                  {
                    AREA_A_ID: '',
                    AREA_A_NAME: $t('all'),
                  },
                  ...areaAList,
                ]"
                item-value="AREA_A_ID"
                item-text="AREA_A_NAME"
                :no-data-text="$t('msgNoData')"
                :loading="isLoadingAreaA"
                :disabled="isLoadingAreaA"
                outlined
                hide-details="auto"
                :label="$t('areaA')"
                v-model="areaAId"
                class="mx-1 rounded-lg"
                color="grey600"
              />
            </v-col>
            <v-col>
              <v-select
                :items="[
                  {
                    AREA_B_ID: '',
                    AREA_B_NAME: $t('all'),
                  },
                  ...areaBList,
                ]"
                item-value="AREA_B_ID"
                item-text="AREA_B_NAME"
                :no-data-text="$t('msgNoData')"
                :loading="isLoadingAreaB"
                :disabled="isLoadingAreaB || areaAId === ''"
                outlined
                hide-details="auto"
                :label="$t('areaB')"
                v-model="areaBId"
                class="mx-1 rounded-lg"
                color="grey600"
              />
            </v-col>
            <v-col>
              <v-select
                :items="[
                  {
                    AREA_C_ID: '',
                    AREA_C_NAME: $t('all'),
                  },
                  ...areaCList,
                ]"
                item-value="AREA_C_ID"
                item-text="AREA_C_NAME"
                :no-data-text="$t('msgNoData')"
                :loading="isLoadingAreaC"
                :disabled="isLoadingAreaC || areaBId === ''"
                outlined
                hide-details="auto"
                :label="$t('areaC')"
                v-model="areaCId"
                class="mx-1 rounded-lg"
                color="grey600"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" v-if="isUseKeywordOffset">
          <v-row no-gutters>
            <v-col cols="4" offset="8">
              <v-text-field
                outlined
                hide-details="auto"
                v-model="keyword"
                class="mx-1 rounded-lg"
                background-color="grey100"
                prepend-inner-icon="mdi-magnify"
                :placeholder="$t('msgEnterKeyword')"
                @keyup.enter="requestSearch"
                v-if="isUseKeyword"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="$vuetify.breakpoint.smAndDown">
        <v-col>
          <v-dialog v-model="dialogLocationSelect" max-width="540">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :height="$vuetify.breakpoint.smAndDown ? '40' : '56'"
                block
                outlined
                color="grey600"
                class="my-1 rounded-lg"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>mdi-crosshairs-gps</v-icon>
                {{ currentLocation }}
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="grey100">{{ $t('selectLocation') }}</v-card-title>

              <v-card-text class="py-6 px-4">
                <v-container>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="[
                          {
                            AREA_A_ID: '',
                            AREA_A_NAME: $t('all'),
                          },
                          ...areaAList,
                        ]"
                        item-value="AREA_A_ID"
                        item-text="AREA_A_NAME"
                        :no-data-text="$t('msgNoData')"
                        :loading="isLoadingAreaA"
                        :disabled="isLoadingAreaA"
                        outlined
                        hide-details="auto"
                        :label="$t('areaA')"
                        v-model="areaAId"
                        class="mx-1 rounded-lg"
                        color="grey600"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="[
                          {
                            AREA_B_ID: '',
                            AREA_B_NAME: $t('all'),
                          },
                          ...areaBList,
                        ]"
                        item-value="AREA_B_ID"
                        item-text="AREA_B_NAME"
                        :no-data-text="$t('msgNoData')"
                        :loading="isLoadingAreaB"
                        :disabled="isLoadingAreaB || areaAId === ''"
                        outlined
                        hide-details="auto"
                        :label="$t('areaB')"
                        v-model="areaBId"
                        class="mx-1 rounded-lg"
                        color="grey600"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="[
                          {
                            AREA_C_ID: '',
                            AREA_C_NAME: $t('all'),
                          },
                          ...areaCList,
                        ]"
                        item-value="AREA_C_ID"
                        item-text="AREA_C_NAME"
                        :no-data-text="$t('msgNoData')"
                        :loading="isLoadingAreaC"
                        :disabled="isLoadingAreaC || areaBId === ''"
                        outlined
                        hide-details="auto"
                        :label="$t('areaC')"
                        v-model="areaCId"
                        class="mx-1 rounded-lg"
                        color="grey600"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <!-- <v-divider></v-divider> -->

              <v-card-actions class="pt-0 pb-6 px-8">
                <v-spacer />
                <v-btn class="rounded-lg" color="grey600" dark elevation="0" @click="dialogLocationSelect = false">
                  {{ $t('complete') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="$vuetify.breakpoint.smAndDown && isUseKeyword">
        <v-col>
          <v-text-field
            dense
            outlined
            hide-details="auto"
            v-model="keyword"
            class="my-1 rounded-lg"
            background-color="grey100"
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('msgEnterKeyword')"
            @keyup.enter="requestSearch"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { getAreaA, getAreaB, getAreaC } from '@/services/api'

export default {
  name: 'SearchBox',
  props: ['isUseKeyword', 'isUseKeywordOffset'],
  data: () => ({
    areaAList: [],
    areaBList: [],
    areaCList: [],
    areaAId: '',
    areaBId: '',
    areaCId: '',
    keyword: '',
    isLoadingAreaA: false,
    isLoadingAreaB: false,
    isLoadingAreaC: false,
    dialogLocationSelect: false,
  }),
  computed: {
    currentLocation() {
      let location = ['', '', '']
      location[0] =
        this.areaAId !== ''
          ? this.areaAList.find((area) => area['AREA_A_ID'] === this.areaAId)['AREA_A_NAME']
          : this.$t('all')
      if (location[0] !== '' && location[0] !== this.$t('all'))
        location[1] =
          this.areaBId !== ''
            ? this.areaBList.find((area) => area['AREA_B_ID'] === this.areaBId)['AREA_B_NAME']
            : this.$t('all')
      if (location[1] !== '' && location[1] !== this.$t('all'))
        location[2] =
          this.areaCId !== ''
            ? this.areaCList.find((area) => area['AREA_C_ID'] === this.areaCId)['AREA_C_NAME']
            : this.$t('all')

      return location.filter(Boolean).join(' > ')
    },
  },
  watch: {
    areaAId() {
      this.areaBId = ''
      this.areaCId = ''
      this.areaBList = []
      this.areaCList = []

      this.requestSearch()

      if (this.areaAId !== '') this.getAreaB()
    },
    areaBId() {
      this.areaCId = ''
      this.areaCList = []

      this.requestSearch()

      if (this.areaBId !== '') this.getAreaC()
    },
    areaCId() {
      this.requestSearch()
    },
  },
  created() {
    this.getAreaA()
  },
  methods: {
    async getAreaA() {
      this.isLoadingAreaA = true
      try {
        let result = await getAreaA(this)
        this.areaAList = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingAreaA = false
      }
    },
    async getAreaB() {
      this.isLoadingAreaB = true
      try {
        let result = await getAreaB(this, this.areaAId)
        this.areaBList = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingAreaB = false
      }
    },
    async getAreaC() {
      this.isLoadingAreaC = true
      try {
        let result = await getAreaC(this, this.areaBId)
        this.areaCList = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingAreaC = false
      }
    },
    requestSearch() {
      this.$emit('setAreaAId', this.areaAId)
      this.$emit('setAreaBId', this.areaBId)
      this.$emit('setAreaCId', this.areaCId)
      this.$emit('setKeyword', this.keyword)
      this.$emit('requestSearch')
    },
  },
}
</script>

<style scoped></style>
