// 서비스 정보 로드
export async function getServiceInfo(app) {
  return await app.$axios.get('/v1/service/info')
}

// 인증 - 회원가입
export async function setSignUp(app, userId, userEmail, userName, userTel) {
  return await app.$axios.post('/v1/auth/signup', {
    USER_ID: userId,
    USER_EMAIL: userEmail,
    USER_NAME: userName,
    USER_TEL: userTel,
  })
}

// 인증 - 로그인
export async function setSignIn(app, deviceToken) {
  return await app.$axios.post('/v1/auth/signin', {
    DEVICE_TOKEN: deviceToken,
  })
}

// 인증 - 초대 유효성 확인
export async function getInviteCheck(app, userEmail) {
  return await app.$axios.post('/v1/auth/invite', {
    USER_EMAIL: userEmail,
  })
}

// 내 정보 수정
export async function setMyInfo(app, userName, userTel) {
  return await app.$axios.patch('/v1/user/info', {
    USER_NAME: userName,
    USER_TEL: userTel,
  })
}

// 대시보드 - 길스 작동 현황 조회
export async function getDashboardGills(app) {
  return await app.$axios.get('/v1/dashboard/gills')
}

// 대시보드 - 공기질 측정기 작동 현황 조회
export async function getDashboardGsen(app) {
  return await app.$axios.get('/v1/dashboard/gsen')
}

// 대시보드 - 공기질 상태 조회
export async function getDashboardAq(app) {
  return await app.$axios.get('/v1/dashboard/aq')
}

// 길스 제어 - 목록 조회
export async function getGillsList(app, page, itemPerPage) {
  return await app.$axios.get('/v1/gills', {
    params: {
      PAGE: page,
      ITEM_PER_PAGE: itemPerPage,
    },
  })
}

// 길스 제어 - 정보 조회
export async function getGillsDetail(app, gillsUdid) {
  return await app.$axios.get(`/v1/gills/${gillsUdid}`)
}

// 길스 제어 - 제어 상태 변경
export async function setGillsControl(app, gillsUdid, control) {
  return await app.$axios.post(`/v1/gills/${gillsUdid}/control`, control)
}

// 길스 제어 - 작동 기록 조회
export async function getGillsHistory(app, gillsUdid, page, itemPerPage) {
  return await app.$axios.get(`/v1/gills/${gillsUdid}/history`, {
    params: {
      PAGE: page,
      ITEM_PER_PAGE: itemPerPage,
    },
  })
}

// 길스 제어 - 관리자 정보 조회
export async function getGillsManager(app, gillsUdid) {
  return await app.$axios.get(`/v1/gills/${gillsUdid}/manager`)
}

// 길스 관리 - 정보 변경
export async function setGillsInfo(app, gillsUdid, info) {
  return await app.$axios.post(`/v1/gills/${gillsUdid}/info`, info)
}

// 길스 관리 - 설치 구역 변경
export async function setGillsArea(app, gillsUdid, areaAId, areaBId, areaCId) {
  return await app.$axios.post(`/v1/gills/${gillsUdid}/area`, {
    AREA_A_ID: areaAId,
    AREA_B_ID: areaBId,
    AREA_C_ID: areaCId,
  })
}

// 길스 관리 - 관리 가능 회원 목록 조회
export async function getGillsUser(app, gillsUdid, page, itemPerPage) {
  return await app.$axios.get(`/v1/gills/${gillsUdid}/user`, {
    params: {
      PAGE: page,
      ITEM_PER_PAGE: itemPerPage,
    },
  })
}

// 길스 관리 - 관리자 변경
export async function setGillsManager(app, gillsUdid, userId) {
  return await app.$axios.post(`/v1/gills/${gillsUdid}/manager`, {
    USER_ID: userId,
  })
}

// 길스 관리 - 공기질 측정기 조회
export async function getGillsGsen(app, gillsUdid, page, itemPerPage, areaAId, areaBId, areaCId) {
  return await app.$axios.get(`/v1/gills/${gillsUdid}/gsen`, {
    params: {
      PAGE: page,
      ITEM_PER_PAGE: itemPerPage,
      AREA_A_ID: areaAId,
      AREA_B_ID: areaBId,
      AREA_C_ID: areaCId,
    },
  })
}

// 길스 관리 - 연동 공기질 측정기 변경
export async function setGillsGsen(app, gillsUdid, gsenList) {
  return await app.$axios.post(`/v1/gills/${gillsUdid}/gsen`, {
    GSEN_UDID: gsenList,
  })
}

// 길스 관리 - 작동 시간 조회
export async function getGillsOpTime(app, gillsUdid) {
  return await app.$axios.get(`/v1/gills/${gillsUdid}/optime`)
}

// 길스 관리 - 작동 시간 변경
export async function setGillsOpTime(app, gillsUdid, gillsOpTime) {
  return await app.$axios.post(`/v1/gills/${gillsUdid}/optime`, {
    GILLS_SCHEDULE: gillsOpTime,
  })
}

// 공기질 측정 - 목록 조회
export async function getGsenList(app, page, itemPerPage, areaAId, areaBId, areaCId, keyword) {
  return await app.$axios.get('/v1/gsen', {
    params: {
      PAGE: page,
      ITEM_PER_PAGE: itemPerPage,
      AREA_A_ID: areaAId,
      AREA_B_ID: areaBId,
      AREA_C_ID: areaCId,
      KEYWORD: keyword,
    },
  })
}

// 공기질 측정 - 정보 조회
export async function getGsenDetail(app, gsenUdid) {
  return await app.$axios.get(`/v1/gsen/${gsenUdid}`)
}

// 공기질 측정 - 측정 항목 기간별 차트데이터 검색
export async function getGsenData(app, gsenUdid, item, dataDtFrom, dataDtTo) {
  return await app.$axios.get(`/v1/gsen/${gsenUdid}/data`, {
    params: {
      ITEM: item,
      DATA_DT_FROM: dataDtFrom,
      DATA_DT_TO: dataDtTo,
    },
  })
}

// 히스토리 - 목록 조회
export async function getHistoryList(
  app,
  page,
  itemPerPage,
  areaAId,
  areaBId,
  areaCId,
  historyCat,
  historyDtFrom,
  historyDtTo
) {
  return await app.$axios.get('/v1/history', {
    params: {
      PAGE: page,
      ITEM_PER_PAGE: itemPerPage,
      AREA_A_ID: areaAId,
      AREA_B_ID: areaBId,
      AREA_C_ID: areaCId,
      HISTORY_CAT: historyCat,
      HISTORY_DT_FROM: historyDtFrom,
      HISTORY_DT_TO: historyDtTo,
    },
  })
}

// 구역 - 상위 지역 조회
export async function getAreaA(app) {
  return await app.$axios.get('/v1/area/a')
}

// 구역 - 하위 지역 조회
export async function getAreaB(app, areaAId) {
  return await app.$axios.get('/v1/area/b', { params: { AREA_A_ID: areaAId } })
}

// 구역 - 세부 구역 조회
export async function getAreaC(app, areaBId) {
  return await app.$axios.get('/v1/area/c', { params: { AREA_B_ID: areaBId } })
}

// 관리 - 구역 - 추가
export async function setArea(app, areaType, parentAreaId, areaName) {
  return await app.$axios.post(`/v1/manage/area/${areaType}`, {
    PARENT_AREA_ID: parentAreaId,
    AREA_NAME: areaName,
  })
}

// 관리 - 구역 - 순서 변경
export async function setAreaOrder(app, areaType, areaId, beforeOrder, afterOrder) {
  return await app.$axios.patch(`/v1/manage/area/${areaType}/order`, {
    BEFORE_ORDER: beforeOrder,
    AFTER_ORDER: afterOrder,
  })
}

// 관리 - 구역 - 이름 수정
export async function setAreaName(app, areaType, areaId, areaName) {
  return await app.$axios.patch(`/v1/manage/area/${areaType}/${areaId}`, {
    AREA_NAME: areaName,
  })
}

// 관리 - 구역 - 삭제 대상 보기
export async function getDelAreaInfo(app, areaType, areaId) {
  return await app.$axios.get(`/v1/manage/area/${areaType}/${areaId}`)
}

// 관리 - 구역 - 삭제
export async function delArea(app, areaType, areaId) {
  return await app.$axios.delete(`/v1/manage/area/${areaType}/${areaId}`)
}

// 관리 - 기준 - 조회
export async function getStandard(app) {
  return await app.$axios.get('/v1/manage/standard')
}

// 관리 - 기준 - 저장
export async function setStandard(app, standard) {
  return await app.$axios.post('/v1/manage/standard', {
    STANDARD: standard,
  })
}

// 관리 - 회사 - 목록 조회
export async function getCompanyList(app) {
  return await app.$axios.get('/v1/manage/company')
}

// 관리 - 회사 - 추가
export async function setCompany(app, companyId, companyName) {
  return await app.$axios.post('/v1/manage/company', {
    COMPANY_ID: companyId,
    COMPANY_NAME: companyName,
    COMPANY_USER_ID: '',
  })
}

// 관리 - 사용자 - 사용자 목록 조회
export async function getUserList(app, page, itemPerPage, areaAId, areaBId, areaCId) {
  return await app.$axios.get('/v1/manage/user', {
    params: {
      PAGE: page,
      ITEM_PER_PAGE: itemPerPage,
      AREA_A_ID: areaAId,
      AREA_B_ID: areaBId,
      AREA_C_ID: areaCId,
    },
  })
}

// 관리 - 사용자 - 사용자 추가
export async function setUserInvite(app, companyId, userEmail, userLevel) {
  return await app.$axios.post(`/v1/manage/user/invite`, {
    COMPANY_ID: companyId,
    USER_EMAIL: userEmail,
    USER_LEVEL: userLevel,
  })
}

// 관리 - 사용자 - 사용자 정보 조회
// export async function getUserInfo(app, userId) {
//   return await app.$axios.get(`/v1/manage/user/${userId}`)
// }

// 관리 - 사용자 - 사용자 정보 변경
export async function setUserInfo(app, userId, userName, userTel, userLevel) {
  return await app.$axios.patch(`/v1/manage/user/${userId}`, {
    USER_NAME: userName,
    USER_TEL: userTel,
    USER_LEVEL: userLevel,
  })
}

// 관리 - 사용자 - 사용자 삭제
export async function delUserInfo(app, userId) {
  return await app.$axios.delete(`/v1/manage/user/${userId}`)
}

// 관리 - 사용자 - 초대 사용자 삭제
export async function delInvitedUserInfo(app, companyId, userEmail) {
  return await app.$axios.delete(`/v1/manage/user/invite`, {
    data: {
      COMPANY_ID: companyId,
      USER_EMAIL: userEmail,
    },
  })
}

// 관리 - 사용자 - 관리 가능 길스 목록
export async function getUserGillsList(app, userId, page, itemPerPage) {
  return await app.$axios.get(`/v1/manage/user/${userId}/gills`, {
    params: {
      PAGE: page,
      ITEM_PER_PAGE: itemPerPage,
    },
  })
}

// 관리 - 사용자 - 관리 길스 목록
export async function getUserCharge(app, userId) {
  return await app.$axios.get(`/v1/manage/user/${userId}/charge`)
}

// 관리 - 사용자 - 관리 길스 저장
export async function setUserCharge(app, userId, gillsUdid) {
  return await app.$axios.post(`/v1/manage/user/${userId}/charge`, {
    GILLS_UDID: gillsUdid,
  })
}
