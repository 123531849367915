import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#2D667D',
        grey100: '#ECEFF1',
        grey200: '#CFD8DC',
        grey300: '#B0BEC5',
        grey400: '#90A4AE',
        grey500: '#78909C',
        grey600: '#546E7A',
        grey700: '#455A64',
        grey800: '#37474F',
        grey900: '#263238',
        working: '#2196F3',
        standby: '#546E7A',
        lowbat: '#FB8C00',
        abnormal: '#EC407A',
        nosignal: '#CFD8DC',
        deactived: '#fff',
        normal: '#414349',
        safe: '#3944BC',
        warning: '#3CB043',
        alert: '#FA8128',
        danger: '#ff2400',
        gerror: '#CFD8DC',
      },
    },
    options: {
      customProperties: true,
    },
  },
})
