<template>
  <Layout>
    <v-container class="pt-lg-15 pt-md-10 pb-0" fluid slot="title">
      <v-row no-gutters>
        <v-col cols="12" md="auto">
          <h2
            :class="[
              { 'font-size-30': $vuetify.breakpoint.mdAndUp },
              { 'font-size-20': $vuetify.breakpoint.smAndDown },
            ]"
          >
            {{ $t('gsen') }}
            <span
              :class="[
                'ml-3 font-weight-regular',
                { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                { 'font-size-14': $vuetify.breakpoint.smAndDown },
              ]"
              v-html="$t('totalGsenCount', { count: gsenListPage['totalCount'] })"
              v-if="gsenListPage !== null"
            />
          </h2>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="auto" class="d-flex align-end justify-end">
          <span
            :class="[
              'grey600--text',
              { 'font-size-14': $vuetify.breakpoint.mdAndUp },
              { 'font-size-12': $vuetify.breakpoint.smAndDown },
              { 'mt-2 mb-1': $vuetify.breakpoint.smAndDown },
            ]"
          >
            {{ lastUpdatedTime ? $t('lastUpdate') + lastUpdatedTime : '' }}
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pa-lg-4 pt-lg-10 pt-md-10 align-content-start" fluid fill-height slot="content">
      <v-row>
        <v-col>
          <v-sheet outlined color="grey100" rounded="lg" class="box">
            <v-card tag="section" rounded="lg" elevation="0" class="pa-4 pa-md-10">
              <search-box
                :isUseKeyword="true"
                :isUseKeywordOffset="true"
                @setAreaAId="setAreaAId"
                @setAreaBId="setAreaBId"
                @setAreaCId="setAreaCId"
                @setKeyword="setKeyword"
                @requestSearch="requestSearch"
              />
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-container fluid class="pa-0">
            <v-row>
              <v-col cols="12" md="6" lg="4" class="pa-3" v-for="gsen in gsenList" :key="gsen['GSEN_UDID']">
                <v-sheet outlined color="grey100" rounded="lg" class="box">
                  <v-card
                    tag="section"
                    rounded="lg"
                    elevation="0"
                    class="pa-4 pa-md-10"
                    :to="{ name: 'GsenDetail', params: { gsenUdid: gsen['GSEN_UDID'] } }"
                  >
                    <v-container fill-height class="pa-0">
                      <v-row class="align-self-start">
                        <v-col :class="[{ 'pa-2': $vuetify.breakpoint.smAndDown }]">
                          <h3
                            :class="[
                              { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                              { 'font-size-16': $vuetify.breakpoint.smAndDown },
                            ]"
                          >
                            {{ gsen['GSEN_NAME'] }}
                          </h3>
                        </v-col>
                        <v-spacer />
                        <v-col :class="['text-right', { 'pa-2': $vuetify.breakpoint.smAndDown }]">
                          <v-chip
                            label
                            :small="$vuetify.breakpoint.mdAndUp"
                            :x-small="$vuetify.breakpoint.smAndDown"
                            dark
                            :color="(gsen['STATE'] === 'ERROR' ? 'NOSIGNAL' : gsen['STATE']).toLowerCase()"
                          >
                            {{ $t((gsen['STATE'] === 'WORKING' ? 'ONLINE' : gsen['STATE']).toLowerCase()) }}
                          </v-chip>
                        </v-col>
                      </v-row>
                      <v-row class="align-self-baseline">
                        <v-col class="d-flex pa-0">
                          <v-container>
                            <v-row :class="[{ 'flex-nowrap overflow-x-auto': $vuetify.breakpoint.smAndDown }]">
                              <v-col v-for="item in itemList" :key="item" class="pa-2">
                                <v-sheet
                                  rounded="lg"
                                  class="d-flex justify-center align-center"
                                  width="68"
                                  height="68"
                                  :color="getGsenStatus(gsen['GSEN_DATA_' + item + '_STATUS'])"
                                  dark
                                >
                                  <span
                                    :class="[
                                      { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                                      { 'font-size-14': $vuetify.breakpoint.smAndDown },
                                    ]"
                                  >
                                    {{ $t(item) }}
                                  </span>
                                </v-sheet>
                              </v-col>
                              <v-responsive class="pa-2" v-if="!$vuetify.breakpoint.smAndDown"></v-responsive>
                            </v-row>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row v-if="gsenListPage !== null && gsenListPage['totalCount'] > 0">
              <v-col>
                <v-pagination v-model="gsenPage" :length="gsenListPage['totalPage']" @input="getGsenList" />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col class="text-center grey400--text py-16">
                <v-progress-circular indeterminate v-if="isLoadingGsenList" />
                <span v-else>{{ $t('noData') }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
import Layout from '@/layouts/Dashboard'
import SearchBox from '@/components/SearchBox.vue'
import { getGsenList } from '@/services/api'
import { getGsenStatus } from '@/services/common'

export default {
  name: 'GsenList',
  components: {
    Layout,
    SearchBox,
  },
  data: () => ({
    updateInterval: null,
    updateIntervalTime: 10000,
    lastUpdatedTime: '',
    areaAId: '',
    areaBId: '',
    areaCId: '',
    keyword: '',
    gsenPage: 1,
    gsenItemPerPage: 15,
    gsenList: [],
    gsenListPage: null,
    isLoadingGsenList: true,
    itemList: ['PM1P0', 'PM2P5', 'PM10P0', 'TVOC', 'CO2', 'CO', 'HCHO', 'RADON', 'TEMP', 'HUMI', 'SOUND'],
  }),
  created() {
    this.getGsenList()

    this.updateInterval = setInterval(() => {
      this.getGsenList(true)
    }, this.updateIntervalTime)
  },
  beforeDestroy() {
    clearInterval(this.updateInterval)
  },
  methods: {
    setAreaAId(areaAId) {
      this.areaAId = areaAId
    },
    setAreaBId(areaBId) {
      this.areaBId = areaBId
    },
    setAreaCId(areaCId) {
      this.areaCId = areaCId
    },
    setKeyword(keyword) {
      this.keyword = keyword
    },
    requestSearch() {
      this.gsenPage = 1
      this.getGsenList()
    },
    async getGsenList(update = false) {
      if (!update) this.isLoadingGsenList = true
      try {
        let result = await getGsenList(
          this,
          this.gsenPage,
          this.gsenItemPerPage,
          this.areaAId,
          this.areaBId,
          this.areaCId,
          this.keyword
        )
        this.gsenListPage = result.data['PAGE']
        this.gsenList = result.data['DATA']
        // this.gsenList =
        //   this.gsenListPage['currentPage'] === 1 ? result.data['DATA'] : [...this.gsenList, ...result.data['DATA']]
        // this.lastUpdatedTime = this.$day().format('YYYY.MM.DD hh:mm:ss')
        this.lastUpdatedTime = this.$day
          .unix(Math.max(...this.gsenList.map((gsen) => this.$day(gsen['GSEN_DATA_DT'] ?? 0).unix())))
          .format('YYYY.MM.DD hh:mm:ss')
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingGsenList = false
      }
    },
    getGsenStatus(status) {
      return getGsenStatus(status)
    },
  },
}
</script>

<style scoped>
.v-chip:before {
  background-color: none !important;
}
</style>
