'use strict'

import Vue from 'vue'
import axios from 'axios'
import firebase from 'firebase/app'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

if (process.env.VUE_APP_AWS_API_BASE_URL) axios.defaults.baseURL = process.env.VUE_APP_AWS_API_BASE_URL
axios.defaults.headers.common['X-Api-Key'] = process.env.VUE_APP_AWS_API_KEY

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    const user = firebase.auth().currentUser

    if (user) {
      let token = await user.getIdToken()

      config.headers['Authorization'] = 'bearer ' + token
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error.response)
  }
)

// eslint-disable-next-line no-unused-vars
Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      },
    },
    $axios: {
      get() {
        return _axios
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin
