import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
// import store from '@/store'
import rootViews from '@/views/index'
import authViews from '@/views/auth/index'
import gillsViews from '@/views/gills/index'
import gsenViews from '@/views/gsen/index'
import managementViews from '@/views/management/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/', // 404 페이지 셋팅 생략
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/',
    redirect: '/signin',
    name: 'Home',
    // component: rootViews.Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: rootViews.Maintenance,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: authViews.SignIn,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: authViews.SignUp,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/resetpw',
    name: 'ResetPw',
    component: authViews.ResetPw,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/resetpw-sent',
    name: 'ResetPwSent',
    component: authViews.ResetPwSent,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: rootViews.Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/gills',
    name: 'Gills',
    component: gillsViews.GillsList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/gills/:gillsUdid',
    name: 'GillsDetail',
    component: gillsViews.GillsDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/gsen',
    name: 'Gsen',
    component: gsenViews.GsenList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/gsen/:gsenUdid',
    name: 'GsenDetail',
    component: gsenViews.GsenDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/history',
    name: 'History',
    component: rootViews.History,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/management',
    name: 'Management',
    component: managementViews.Management,
    redirect: '/management/area',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/management/area',
        name: 'AreaManagement',
        component: managementViews.AreaManagement,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/management/standard',
        name: 'StandardManagement',
        component: managementViews.StandardManagement,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/management/company',
        name: 'CompanyManagement',
        component: managementViews.CompanyManagement,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/management/user',
        name: 'UserManagement',
        component: managementViews.UserManagement,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const currentUser = firebase.auth().currentUser
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  // 접근 권한 없이 접근 권한 필요 페이지에 접근 할 경우
  if (requiresAuth && !currentUser) next('/signin')
  // 접근 권한이 있을 경우
  else {
    // 회원이면 로그인/가입/비번 재생성 페이지 접근 X
    if (
      currentUser &&
      (to.name === 'SignIn' || to.name === 'SignUp' || to.name === 'ResetPw' || to.name === 'ResetPwSent')
    )
      next('/gills')
    else next()
  }
})

export default router
