<template>
  <Layout>
    <v-container class="pt-lg-15 pt-md-10 pb-0" fluid slot="title">
      <h2 :class="[{ 'font-size-30': $vuetify.breakpoint.mdAndUp }, { 'font-size-20': $vuetify.breakpoint.smAndDown }]">
        {{ $t('history') }}
      </h2>
    </v-container>
    <v-container class="pa-lg-4 pt-lg-10 pt-md-10 align-content-start" fluid fill-height slot="content">
      <v-row>
        <v-col>
          <v-sheet outlined color="grey100" rounded="lg" class="box">
            <v-card tag="section" rounded="lg" elevation="0" class="pa-4 pa-md-10">
              <search-box
                :isUseKeyword="false"
                :isUseKeywordOffset="true"
                @setAreaAId="setAreaAId"
                @setAreaBId="setAreaBId"
                @setAreaCId="setAreaCId"
                @setKeyword="setKeyword"
                @requestSearch="requestSearch"
              />
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet outlined color="grey100" rounded="lg" class="box">
            <v-card tag="section" rounded="lg" elevation="0" class="pa-4 pa-md-10">
              <v-container fill-height fluid class="pa-0">
                <v-row no-gutters>
                  <search-schedule-box @setSearchDtFrom="setSearchDtFrom" @setSearchDtTo="setSearchDtTo" />
                  <v-col>
                    <v-container fluid class="pa-0">
                      <v-row no-gutters>
                        <v-col md="9" offset-md="3" lg="7" offset-lg="5">
                          <v-row class="py-1 px-2">
                            <v-col class="px-1">
                              <v-btn
                                class="rounded-lg"
                                block
                                :x-large="$vuetify.breakpoint.mdAndUp"
                                :height="$vuetify.breakpoint.mdAndUp ? 56 : 40"
                                color="grey600"
                                dark
                                elevation="0"
                                :outlined="historyCat !== ''"
                                @click="setHistoryCat('')"
                              >
                                {{ $t('all') }}
                              </v-btn>
                            </v-col>
                            <v-col class="px-1">
                              <v-btn
                                class="rounded-lg"
                                block
                                :x-large="$vuetify.breakpoint.mdAndUp"
                                :height="$vuetify.breakpoint.mdAndUp ? 56 : 40"
                                color="grey600"
                                dark
                                elevation="0"
                                :outlined="historyCat !== 'gills'"
                                @click="setHistoryCat('gills')"
                              >
                                {{ $t('gills') }}
                              </v-btn>
                            </v-col>
                            <v-col class="px-1">
                              <v-btn
                                class="rounded-lg"
                                block
                                :x-large="$vuetify.breakpoint.mdAndUp"
                                :height="$vuetify.breakpoint.mdAndUp ? 56 : 40"
                                color="grey600"
                                dark
                                elevation="0"
                                :outlined="historyCat !== 'gsen'"
                                @click="setHistoryCat('gsen')"
                              >
                                {{ $t('gsen') }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row v-if="historyListPage !== null && historyListPage['totalCount'] > 0">
                  <v-col class="overflow-auto">
                    <v-responsive min-width="900">
                      <v-container
                        fluid
                        :class="[
                          'table',
                          { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                          { 'font-size-14': $vuetify.breakpoint.smAndDown },
                        ]"
                      >
                        <v-row :class="['thead align-center text-center', { dense: $vuetify.breakpoint.smAndDown }]">
                          <v-col cols="2" class="font-weight-bold">{{ $t('date') }}</v-col>
                          <v-col cols="2" class="font-weight-bold">{{ $t('category') }}</v-col>
                          <v-col class="font-weight-bold">{{ $t('memo') }}</v-col>
                        </v-row>
                        <v-row
                          :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]"
                          v-for="history in historyList"
                          :key="history['HISTORY_ID']"
                        >
                          <v-col cols="2" class="text-center">
                            {{ $day(history['HISTORY_DT']).format('YYYY.MM.DD hh:mm:ss') }}
                          </v-col>
                          <v-col cols="2" class="text-center">{{ $t(history['HISTORY_CAT'].toLowerCase()) }}</v-col>
                          <v-col>{{ history['HISTORY_MEMO'] }}</v-col>
                        </v-row>
                      </v-container>
                    </v-responsive>
                  </v-col>
                </v-row>
                <v-row v-if="historyListPage !== null && historyListPage['totalCount'] > 0">
                  <v-col>
                    <v-pagination
                      v-model="historyPage"
                      :length="historyListPage['totalPage']"
                      @input="getHistoryList"
                    />
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col class="text-center grey400--text py-16">
                    <v-progress-circular indeterminate v-if="isLoadingHistoryList" />
                    <span v-else>{{ $t('noData') }}</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
import Layout from '@/layouts/Dashboard'
import SearchBox from '@/components/SearchBox.vue'
import SearchScheduleBox from '@/components/SearchScheduleBox.vue'
import { getHistoryList } from '@/services/api'

export default {
  name: 'History',
  components: {
    Layout,
    SearchBox,
    SearchScheduleBox,
  },
  data: () => ({
    updateInterval: null,
    updateIntervalTime: 30000,
    areaAId: '',
    areaBId: '',
    areaCId: '',
    keyword: '',
    historyPage: 1,
    historyItemPerPage: 10,
    historyCat: '',
    historyDtFrom: '',
    historyDtTo: '',
    historyList: [],
    historyListPage: null,
    isLoadingHistoryList: true,
  }),
  created() {
    // this.getHistoryList()

    this.updateInterval = setInterval(() => {
      this.getHistoryList(true)
    }, this.updateIntervalTime)
  },
  beforeDestroy() {
    clearInterval(this.updateInterval)
  },
  methods: {
    setAreaAId(areaAId) {
      this.areaAId = areaAId
    },
    setAreaBId(areaBId) {
      this.areaBId = areaBId
    },
    setAreaCId(areaCId) {
      this.areaCId = areaCId
    },
    setKeyword(keyword) {
      this.keyword = keyword
    },
    setHistoryCat(historyCat) {
      this.historyCat = historyCat
      this.requestSearch()
    },
    setSearchDtFrom(searchDateFrom) {
      this.historyDtFrom = searchDateFrom
      if (this.historyDtFrom !== '' && this.historyDtTo !== '') this.requestSearch()
    },
    setSearchDtTo(searchDateTo) {
      this.historyDtTo = searchDateTo
      if (this.historyDtFrom !== '' && this.historyDtTo !== '') this.requestSearch()
    },
    requestSearch() {
      this.historyPage = 1
      this.getHistoryList()
    },
    async getHistoryList(update = false) {
      if (!update) this.isLoadingHistoryList = true
      try {
        let result = await getHistoryList(
          this,
          this.historyPage,
          this.historyItemPerPage,
          this.areaAId,
          this.areaBId,
          this.areaCId,
          this.historyCat,
          this.historyDtFrom,
          this.historyDtTo
        )
        this.historyList = result.data['DATA']
        this.historyListPage = result.data['PAGE']
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingHistoryList = false
      }
    },
  },
}
</script>
