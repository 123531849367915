<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-sheet outlined color="grey100" rounded="lg" class="box area-wrap overflow-hidden">
          <v-card tag="section" rounded="lg" elevation="0" class="pa-0" color="transparent">
            <v-container fill-height fluid class="pa-0">
              <v-row no-gutters>
                <v-col cols="12" md="4">
                  <v-container
                    fill-height
                    :class="[
                      'pa-0 justify-start',
                      { 'align-content-start white': !isLoadingAreaA && areaAList.length > 0 },
                      { 'align-start white': !isLoadingAreaA && areaAList.length === 0 },
                    ]"
                  >
                    <v-row no-gutters v-if="!isLoadingAreaA">
                      <v-col
                        :class="[
                          'font-weight-bold white--text',
                          { 'px-8 py-4 font-size-20': $vuetify.breakpoint.mdAndUp },
                          { 'px-6 py-3 font-size-16': $vuetify.breakpoint.smAndDown },
                          { grey700: !areaAId && !areaBId },
                          { grey300: areaAId },
                        ]"
                      >
                        {{ $t('areaA') }}
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <v-progress-circular indeterminate class="d-flex mx-auto my-16" v-if="isLoadingAreaA" />
                        <v-list
                          :height="$vuetify.breakpoint.smAndDown ? 200 : 600"
                          class="py-0 overflow-auto"
                          v-else-if="!isLoadingAreaA && areaAList"
                          :dense="$vuetify.breakpoint.mdAndDown"
                        >
                          <v-list-item-group v-model="areaAId" color="primary">
                            <v-list-item
                              :class="[
                                { 'px-8': $vuetify.breakpoint.mdAndUp },
                                { 'px-6': $vuetify.breakpoint.smAndDown },
                              ]"
                              :two-line="$vuetify.breakpoint.mdAndUp"
                              v-for="(areaA, index) in areaAList"
                              :key="index"
                              :value="areaA['AREA_A_ID']"
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  <span v-if="editAreaAId !== areaA['AREA_A_ID']">
                                    <strong v-if="userInfo['COMPANY_ID'] === 'GILLS'">
                                      {{ `[${areaA['COMPANY_NAME']}]` }}
                                    </strong>
                                    {{ areaA['AREA_A_NAME'] }}
                                  </span>
                                  <v-text-field
                                    outlined
                                    color="grey600"
                                    dense
                                    hide-details
                                    v-model="editAreaAName"
                                    v-else
                                    @click.stop="() => {}"
                                    :loading="isSavingArea"
                                    :disabled="isSavingArea"
                                    @keyup.enter.stop="editArea('A')"
                                  />
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-container class="pa-0">
                                  <v-row no-gutters>
                                    <v-col
                                      class="px-1 text-center"
                                      cols="auto"
                                      v-if="userInfo['COMPANY_ID'] !== 'GILLS'"
                                    >
                                      <v-btn
                                        class="rounded-lg pa-0"
                                        elevation="0"
                                        width="24"
                                        height="24"
                                        min-width="auto"
                                        min-height="auto"
                                        @click.stop="setAreaOrder('A', areaA['AREA_A_ID'], 'down')"
                                        :disabled="index === areaAList.length - 1 || isSavingArea"
                                        :color="index === areaAList.length - 1 || isSavingArea ? '' : 'grey300'"
                                        :outlined="index < areaAList.length - 1 && !isSavingArea"
                                      >
                                        <v-icon size="20">mdi-chevron-down</v-icon>
                                      </v-btn>
                                    </v-col>
                                    <v-col
                                      class="px-1 text-center"
                                      cols="auto"
                                      v-if="userInfo['COMPANY_ID'] !== 'GILLS'"
                                    >
                                      <v-btn
                                        class="rounded-lg pa-0"
                                        elevation="0"
                                        width="24"
                                        height="24"
                                        min-width="auto"
                                        min-height="auto"
                                        @click.stop="setAreaOrder('A', areaA['AREA_A_ID'], 'up')"
                                        :disabled="index === 0 || isSavingArea"
                                        :color="index === 0 || isSavingArea ? '' : 'grey300'"
                                        :outlined="index > 0 && !isSavingArea"
                                      >
                                        <v-icon size="20">mdi-chevron-up</v-icon>
                                      </v-btn>
                                    </v-col>
                                    <v-col class="px-1 text-center" cols="auto">
                                      <v-btn
                                        text
                                        class="pa-0"
                                        min-width="auto"
                                        min-height="auto"
                                        height="auto"
                                        @click.stop="
                                          editAreaAId = areaA['AREA_A_ID']
                                          editAreaAName = areaA['AREA_A_NAME']
                                        "
                                        v-if="editAreaAId !== areaA['AREA_A_ID']"
                                      >
                                        {{ $t('edit') }}
                                      </v-btn>
                                      <v-btn
                                        text
                                        class="pa-0"
                                        min-width="auto"
                                        min-height="auto"
                                        height="auto"
                                        @click.stop="editArea('A')"
                                        :disabled="isSavingArea"
                                        v-else
                                      >
                                        {{ $t('save') }}
                                      </v-btn>
                                    </v-col>
                                    <v-col class="px-1 text-center" cols="auto">
                                      <v-btn
                                        text
                                        class="pa-0"
                                        min-width="auto"
                                        min-height="auto"
                                        height="auto"
                                        color="red"
                                        @click.stop="showDelArea('A', areaA['AREA_A_ID'], areaA['AREA_A_NAME'])"
                                        :disabled="isSavingArea"
                                        v-if="editAreaAId !== areaA['AREA_A_ID']"
                                      >
                                        {{ $t('delete') }}
                                      </v-btn>
                                      <v-btn
                                        text
                                        class="pa-0"
                                        min-width="auto"
                                        min-height="auto"
                                        height="auto"
                                        @click.stop="editAreaAId = ''"
                                        v-else
                                      >
                                        {{ $t('cancel') }}
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list-item-group>
                          <v-list-item
                            :class="[
                              { 'px-8': $vuetify.breakpoint.mdAndUp },
                              { 'px-6': $vuetify.breakpoint.smAndDown },
                            ]"
                            :two-line="$vuetify.breakpoint.mdAndUp"
                            @click="showAddArea('A', userInfo['COMPANY_ID'])"
                          >
                            <v-list-item-content>
                              <v-list-item-title class="d-flex justify-center grey300--text">
                                <v-icon color="grey300" :size="$vuetify.breakpoint.mdAndUp ? 22 : 18">mdi-plus</v-icon>
                                {{ $t('addArea') }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <p v-else class="mb-0 py-16 grey300--text text-center">
                          {{ $t(areaAList.length === 0 ? 'noData' : '') }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" md="4">
                  <v-container
                    fill-height
                    :class="[
                      'pa-0 justify-start',
                      { 'align-content-start white': areaAId && !isLoadingAreaB && areaBList.length > 0 },
                      { 'align-start white': areaAId && !isLoadingAreaB && areaBList.length === 0 },
                    ]"
                  >
                    <v-row no-gutters v-if="areaAId && !isLoadingAreaB">
                      <v-col
                        :class="[
                          'font-weight-bold white--text',
                          { 'px-8 py-4 font-size-20': $vuetify.breakpoint.mdAndUp },
                          { 'px-6 py-3 font-size-16': $vuetify.breakpoint.smAndDown },
                          { grey700: areaAId && !areaBId },
                          { grey500: areaBId },
                        ]"
                      >
                        {{ $t('areaB') }}
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <v-progress-circular indeterminate class="d-flex mx-auto my-16" v-if="isLoadingAreaB" />
                        <v-list
                          :height="$vuetify.breakpoint.smAndDown ? 200 : 600"
                          class="py-0 overflow-auto"
                          v-else-if="!isLoadingAreaB && areaBList"
                          :dense="$vuetify.breakpoint.mdAndDown"
                        >
                          <v-list-item-group v-model="areaBId" color="primary">
                            <v-list-item
                              :class="[
                                { 'px-8': $vuetify.breakpoint.mdAndUp },
                                { 'px-6': $vuetify.breakpoint.smAndDown },
                              ]"
                              :two-line="$vuetify.breakpoint.mdAndUp"
                              v-for="(areaB, index) in areaBList"
                              :key="index"
                              :value="areaB['AREA_B_ID']"
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  <span v-if="editAreaBId !== areaB['AREA_B_ID']">
                                    {{ areaB['AREA_B_NAME'] }}
                                  </span>
                                  <v-text-field
                                    outlined
                                    color="grey600"
                                    dense
                                    hide-details
                                    v-model="editAreaBName"
                                    v-else
                                    @click.stop="() => {}"
                                    :loading="isSavingArea"
                                    :disabled="isSavingArea"
                                    @keyup.enter.stop="editArea('B')"
                                  />
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-container class="pa-0">
                                  <v-row no-gutters>
                                    <v-col class="px-1 text-center" cols="auto">
                                      <v-btn
                                        class="rounded-lg pa-0"
                                        elevation="0"
                                        width="24"
                                        height="24"
                                        min-width="auto"
                                        min-height="auto"
                                        @click.stop="setAreaOrder('B', areaB['AREA_B_ID'], 'down')"
                                        :disabled="index === areaBList.length - 1 || isSavingArea"
                                        :color="index === areaBList.length - 1 || isSavingArea ? '' : 'grey300'"
                                        :outlined="index < areaBList.length - 1 && !isSavingArea"
                                      >
                                        <v-icon size="20">mdi-chevron-down</v-icon>
                                      </v-btn>
                                    </v-col>
                                    <v-col class="px-1 text-center" cols="auto">
                                      <v-btn
                                        class="rounded-lg pa-0"
                                        elevation="0"
                                        width="24"
                                        height="24"
                                        min-width="auto"
                                        min-height="auto"
                                        @click.stop="setAreaOrder('B', areaB['AREA_B_ID'], 'up')"
                                        :disabled="index === 0 || isSavingArea"
                                        :color="index === 0 || isSavingArea ? '' : 'grey300'"
                                        :outlined="index > 0 && !isSavingArea"
                                      >
                                        <v-icon size="20">mdi-chevron-up</v-icon>
                                      </v-btn>
                                    </v-col>
                                    <v-col class="px-1 text-center" cols="auto">
                                      <v-btn
                                        text
                                        class="pa-0"
                                        min-width="auto"
                                        min-height="auto"
                                        height="auto"
                                        @click.stop="
                                          editAreaBId = areaB['AREA_B_ID']
                                          editAreaBName = areaB['AREA_B_NAME']
                                        "
                                        v-if="editAreaBId !== areaB['AREA_B_ID']"
                                      >
                                        {{ $t('edit') }}
                                      </v-btn>
                                      <v-btn
                                        text
                                        class="pa-0"
                                        min-width="auto"
                                        min-height="auto"
                                        height="auto"
                                        @click.stop="editArea('B')"
                                        :disabled="isSavingArea"
                                        v-else
                                      >
                                        {{ $t('save') }}
                                      </v-btn>
                                    </v-col>
                                    <v-col class="px-1 text-center" cols="auto">
                                      <v-btn
                                        text
                                        class="pa-0"
                                        min-width="auto"
                                        min-height="auto"
                                        height="auto"
                                        color="red"
                                        @click.stop="showDelArea('B', areaB['AREA_B_ID'], areaB['AREA_B_NAME'])"
                                        :disabled="isSavingArea"
                                        v-if="editAreaBId !== areaB['AREA_B_ID']"
                                      >
                                        {{ $t('delete') }}
                                      </v-btn>
                                      <v-btn
                                        text
                                        class="pa-0"
                                        min-width="auto"
                                        min-height="auto"
                                        height="auto"
                                        @click.stop="editAreaBId = ''"
                                        v-else
                                      >
                                        {{ $t('cancel') }}
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list-item-group>
                          <v-list-item
                            :class="[
                              { 'px-8': $vuetify.breakpoint.mdAndUp },
                              { 'px-6': $vuetify.breakpoint.smAndDown },
                            ]"
                            :two-line="$vuetify.breakpoint.mdAndUp"
                            @click="showAddArea('B', areaAId)"
                            v-if="areaBList"
                          >
                            <v-list-item-content>
                              <v-list-item-title class="d-flex justify-center grey300--text">
                                <v-icon color="grey300" :size="$vuetify.breakpoint.mdAndUp ? 22 : 18">mdi-plus</v-icon>
                                {{ $t('addArea') }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <p v-else class="mb-0 py-16 grey300--text text-center">
                          {{ $t('msgSelectAreaA') }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" md="4">
                  <v-container
                    fill-height
                    :class="[
                      'pa-0 justify-start',
                      { 'align-content-start white': areaBId && !isLoadingAreaC && areaCList.length > 0 },
                      { 'align-start white': areaBId && !isLoadingAreaC && areaCList.length === 0 },
                    ]"
                  >
                    <v-row no-gutters v-if="areaBId && !isLoadingAreaC">
                      <v-col
                        :class="[
                          'font-weight-bold white--text',
                          { 'px-8 py-4 font-size-20': $vuetify.breakpoint.mdAndUp },
                          { 'px-6 py-3 font-size-16': $vuetify.breakpoint.smAndDown },
                          { grey700: areaBId },
                        ]"
                      >
                        {{ $t('areaC') }}
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        <v-progress-circular indeterminate class="d-flex mx-auto my-16" v-if="isLoadingAreaC" />
                        <v-list
                          flat
                          :height="$vuetify.breakpoint.smAndDown ? 200 : 600"
                          class="py-0 overflow-auto"
                          v-else-if="!isLoadingAreaC && areaCList"
                          :dense="$vuetify.breakpoint.mdAndDown"
                        >
                          <v-list-item-group>
                            <v-list-item
                              :class="[
                                { 'px-8': $vuetify.breakpoint.mdAndUp },
                                { 'px-6': $vuetify.breakpoint.smAndDown },
                              ]"
                              :two-line="$vuetify.breakpoint.mdAndUp"
                              v-for="(areaC, index) in areaCList"
                              :key="index"
                              :value="areaC['AREA_C_ID']"
                              inactive
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  <span v-if="editAreaCId !== areaC['AREA_C_ID']">
                                    {{ areaC['AREA_C_NAME'] }}
                                  </span>
                                  <v-text-field
                                    outlined
                                    color="grey600"
                                    dense
                                    hide-details
                                    v-model="editAreaCName"
                                    v-else
                                    @click.stop="() => {}"
                                    :loading="isSavingArea"
                                    :disabled="isSavingArea"
                                    @keyup.enter.stop="editArea('C')"
                                  />
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-container class="pa-0">
                                  <v-row no-gutters>
                                    <v-col class="px-1 text-center" cols="auto">
                                      <v-btn
                                        class="rounded-lg pa-0"
                                        elevation="0"
                                        width="24"
                                        height="24"
                                        min-width="auto"
                                        min-height="auto"
                                        @click.stop="setAreaOrder('C', areaC['AREA_C_ID'], 'down')"
                                        :disabled="index === areaCList.length - 1 || isSavingArea"
                                        :color="index === areaCList.length - 1 || isSavingArea ? '' : 'grey300'"
                                        :outlined="index < areaCList.length - 1 && !isSavingArea"
                                      >
                                        <v-icon size="20">mdi-chevron-down</v-icon>
                                      </v-btn>
                                    </v-col>
                                    <v-col class="px-1 text-center" cols="auto">
                                      <v-btn
                                        class="rounded-lg pa-0"
                                        elevation="0"
                                        width="24"
                                        height="24"
                                        min-width="auto"
                                        min-height="auto"
                                        @click.stop="setAreaOrder('C', areaC['AREA_C_ID'], 'up')"
                                        :disabled="index === 0 || isSavingArea"
                                        :color="index === 0 || isSavingArea ? '' : 'grey300'"
                                        :outlined="index > 0 && !isSavingArea"
                                      >
                                        <v-icon size="20">mdi-chevron-up</v-icon>
                                      </v-btn>
                                    </v-col>
                                    <v-col class="px-1 text-center" cols="auto">
                                      <v-btn
                                        text
                                        class="pa-0"
                                        min-width="auto"
                                        min-height="auto"
                                        height="auto"
                                        @click.stop="
                                          editAreaCId = areaC['AREA_C_ID']
                                          editAreaCName = areaC['AREA_C_NAME']
                                        "
                                        v-if="editAreaCId !== areaC['AREA_C_ID']"
                                      >
                                        {{ $t('edit') }}
                                      </v-btn>
                                      <v-btn
                                        text
                                        class="pa-0"
                                        min-width="auto"
                                        min-height="auto"
                                        height="auto"
                                        @click.stop="editArea('C')"
                                        :disabled="isSavingArea"
                                        v-else
                                      >
                                        {{ $t('save') }}
                                      </v-btn>
                                    </v-col>
                                    <v-col class="px-1 text-center" cols="auto">
                                      <v-btn
                                        text
                                        class="pa-0"
                                        min-width="auto"
                                        min-height="auto"
                                        height="auto"
                                        color="red"
                                        @click.stop="showDelArea('C', areaC['AREA_C_ID'], areaC['AREA_C_NAME'])"
                                        :disabled="isSavingArea"
                                        v-if="editAreaCId !== areaC['AREA_C_ID']"
                                      >
                                        {{ $t('delete') }}
                                      </v-btn>
                                      <v-btn
                                        text
                                        class="pa-0"
                                        min-width="auto"
                                        min-height="auto"
                                        height="auto"
                                        @click.stop="editAreaCId = ''"
                                        v-else
                                      >
                                        {{ $t('cancel') }}
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list-item-group>
                          <v-list-item
                            :class="[
                              { 'px-8': $vuetify.breakpoint.mdAndUp },
                              { 'px-6': $vuetify.breakpoint.smAndDown },
                            ]"
                            :two-line="$vuetify.breakpoint.mdAndUp"
                            @click="showAddArea('C', areaBId)"
                            v-if="areaBList"
                          >
                            <v-list-item-content>
                              <v-list-item-title class="d-flex justify-center grey300--text">
                                <v-icon color="grey300" :size="$vuetify.breakpoint.mdAndUp ? 22 : 18">mdi-plus</v-icon>
                                {{ $t('addArea') }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <p v-else class="mb-0 py-16 grey300--text text-center">
                          {{ $t('msgSelectAreaB') }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-sheet>
        <v-dialog persistent v-model="dialogAddArea" width="500">
          <v-card>
            <v-card-title class="grey100">
              {{ $t(`addArea${areaType}`) }}
            </v-card-title>

            <v-card-text>
              <v-form ref="addAreaForm" @submit.prevent="addArea" v-model="isAddAreaFormValid">
                <v-container class="table black--text">
                  <v-row
                    :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]"
                    v-if="userInfo['USER_LEVEL'] === 'G' && areaType === 'A'"
                  >
                    <v-col class="font-weight-bold" cols="4">
                      {{ $t('companyName') }}
                    </v-col>
                    <v-col cols="8">
                      <v-select
                        :items="companyList"
                        item-text="COMPANY_NAME"
                        item-value="COMPANY_ID"
                        v-model="parentAreaId"
                        dense
                        outlined
                        hide-details="auto"
                        color="grey600"
                        class="rounded-lg"
                        :placeholder="$t('msgSelectCompany')"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]"
                    v-else-if="areaType !== 'A'"
                  >
                    <v-col class="font-weight-bold" cols="4">
                      {{ $t(`area${String.fromCharCode(areaType.charCodeAt(0) - 1)}`) }}
                    </v-col>
                    <v-col cols="8">
                      {{ parentAreaName }}
                    </v-col>
                  </v-row>
                  <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                    <v-col class="font-weight-bold" cols="4">
                      {{ $t('areaName') }}
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        v-model="areaName"
                        dense
                        outlined
                        hide-details="auto"
                        color="grey600"
                        maxlength="20"
                        :rules="[rules.required(areaName, 'areaName')]"
                        validate-on-blur
                        class="rounded-lg"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn class="rounded-lg" color="grey600" outlined @click="dialogAddArea = false">
                {{ $t('close') }}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="grey600"
                elevation="0"
                @click="addArea"
                :loading="isSavingArea"
                :disabled="isSavingArea || !isAddAreaFormValid"
                :outlined="isSavingArea || !isAddAreaFormValid"
                :dark="!isSavingArea && isAddAreaFormValid"
              >
                {{ $t('add') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog persistent v-model="dialogDelArea" width="500">
          <v-card class="pt-6">
            <v-card-text v-if="isLoadingDelAreaInfo" class="text-center">
              <p>{{ $t('msgLoadingDelArea') }}</p>
              <v-progress-circular indeterminate />
            </v-card-text>
            <v-card-text v-else>
              <p v-html="$t('msgDelArea', { areaType: $t(`area${delAreaType}`), areaName: delAreaName })" />
              <p v-if="delAreaInfo !== null">
                <span v-if="Object.keys(delAreaInfo).includes('AREA_B_COUNT')">
                  {{ $t('areaB') }}: {{ delAreaInfo['AREA_B_COUNT'] }}
                  {{ $t(delAreaInfo['AREA_B_COUNT'] === 1 ? 'unitPlace' : 'unitPlaces') }}
                  /
                </span>
                <span v-if="Object.keys(delAreaInfo).includes('AREA_C_COUNT')">
                  {{ $t('areaC') }}: {{ delAreaInfo['AREA_C_COUNT'] }}
                  {{ $t(delAreaInfo['AREA_C_COUNT'] === 1 ? 'unitPlace' : 'unitPlaces') }}
                  /
                </span>
                <span v-if="Object.keys(delAreaInfo).includes('GILLS_COUNT')">
                  {{ $t('gills') }}: {{ delAreaInfo['GILLS_COUNT'] }}
                  {{ $t(delAreaInfo['GILLS_COUNT'] === 1 ? 'unitDevice' : 'unitDevices') }}
                  /
                </span>
                <span v-if="Object.keys(delAreaInfo).includes('GSEN_COUNT')">
                  {{ $t('gsen') }}: {{ delAreaInfo['GSEN_COUNT'] }}
                  {{ $t(delAreaInfo['GSEN_COUNT'] === 1 ? 'unitDevice' : 'unitDevice') }}
                </span>
              </p>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn class="rounded-lg" color="grey600" outlined @click="dialogDelArea = false">
                {{ $t('close') }}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="grey600"
                elevation="0"
                @click="delArea"
                :loading="isSavingArea || isLoadingDelAreaInfo"
                :disabled="isSavingArea || isLoadingDelAreaInfo"
                :outlined="isSavingArea || isLoadingDelAreaInfo"
                :dark="!isSavingArea && !isLoadingDelAreaInfo"
              >
                {{ $t('delete') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getAreaA,
  getAreaB,
  getAreaC,
  setAreaOrder,
  setAreaName,
  setArea,
  getCompanyList,
  getDelAreaInfo,
  delArea,
} from '@/services/api'
import { isNull } from '@/services/common'
import { mapGetters } from 'vuex'

export default {
  name: 'AreaManagement',
  data: () => ({
    areaAList: null,
    areaBList: null,
    areaCList: null,
    areaAId: '',
    areaBId: '',
    areaCId: '',
    isLoadingAreaA: false,
    isLoadingAreaB: false,
    isLoadingAreaC: false,
    editAreaAId: '',
    editAreaAName: '',
    editAreaBId: '',
    editAreaBName: '',
    editAreaCId: '',
    editAreaCName: '',
    dialogAddArea: false,
    isAddAreaFormValid: false,
    isSavingArea: false,
    isLoadingCompanyList: false,
    companyList: null,
    areaType: '',
    parentAreaId: '',
    areaName: '',
    delAreaInfo: null,
    isLoadingDelAreaInfo: false,
    dialogDelArea: false,
    delAreaType: '',
    delAreaId: '',
    delAreaName: '',
  }),
  computed: {
    ...mapGetters(['userInfo']),
    rules() {
      let vm = this

      return {
        required: (value, type) => !isNull(value) || vm.$t('msgEnter' + type.charAt(0).toUpperCase() + type.slice(1)),
      }
    },
    parentAreaName() {
      let parentAreaType = String.fromCharCode(this.areaType.charCodeAt(0) - 1)

      if (this.parentAreaId === '') return ''
      else
        return this[`area${parentAreaType}List`].filter(
          (area) => area[`AREA_${parentAreaType}_ID`] === this.parentAreaId
        )[0][`AREA_${parentAreaType}_NAME`]
    },
  },
  watch: {
    areaAId() {
      this.areaBId = ''
      this.areaCId = ''
      this.areaBList = null
      this.areaCList = null

      if (this.areaAId ?? '' !== '') this.getAreaB()
    },
    areaBId() {
      this.areaCId = ''
      this.areaCList = null

      if (this.areaBId ?? '' !== '') this.getAreaC()
    },
    dialogAddArea() {
      if (!this.dialogAddArea) {
        this.isAddAreaFormValid = false
        this.areaType = ''
        this.parentAreaId = ''
        this.areaName = ''
      } else {
        if (this.$refs.addAreaForm) this.$refs.addAreaForm.reset()
      }
    },
    dialogDelArea() {
      if (!this.dialogDelArea) {
        this.delAreaType = ''
        this.delAreaId = ''
        this.delAreaName = ''
        this.delAreaInfo = null
      } else this.getDelAreaInfo()
    },
  },
  async created() {
    this.getAreaA()

    if (this.userInfo['COMPANY_ID'] === 'GILLS') await this.getCompanyList()
    else this.companyId = this.userInfo['COMPANY_ID']
  },
  methods: {
    async getAreaA() {
      this.isLoadingAreaA = true
      try {
        let result = await getAreaA(this)
        this.areaAList = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingAreaA = false
      }
    },
    async getAreaB() {
      this.isLoadingAreaB = true
      try {
        let result = await getAreaB(this, this.areaAId)
        this.areaBList = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingAreaB = false
      }
    },
    async getAreaC() {
      this.isLoadingAreaC = true
      try {
        let result = await getAreaC(this, this.areaBId)
        this.areaCList = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingAreaC = false
      }
    },
    async setAreaOrder(areaType, areaId, orderType) {
      this.isSavingArea = true

      let orderValue = 0
      if (orderType === 'up') orderValue = -1
      else if (orderType === 'down') orderValue = 1

      let beforeIndex = this[`area${areaType}List`].findIndex((area) => area[`AREA_${areaType}_ID`] === areaId)
      let afterIndex = beforeIndex + orderValue

      try {
        let result = await setAreaOrder(this, areaType.toLowerCase(), areaId, beforeIndex + 1, afterIndex + 1)

        if (result.data) {
          const item = this[`area${areaType}List`].splice(beforeIndex, 1)
          this[`area${areaType}List`].splice(afterIndex, 0, item[0])
          this[`area${areaType}Id`] = ''
        } else throw new Error(result.data)
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(this.$t('errorMsg'))
      } finally {
        this.isSavingArea = false
      }
    },
    async editArea(areaType) {
      this.isSavingArea = true
      try {
        let result = await setAreaName(
          this,
          areaType.toLowerCase(),
          this[`editArea${areaType}Id`],
          this[`editArea${areaType}Name`]
        )

        if (result.data) {
          let index = this[`area${areaType}List`].findIndex(
            (area) => area[`AREA_${areaType}_ID`] === this[`editArea${areaType}Id`]
          )
          this[`area${areaType}List`][index][`AREA_${areaType}_NAME`] = this[`editArea${areaType}Name`]
          this[`editArea${areaType}Id`] = ''
          this[`editArea${areaType}Name`] = ''
        } else throw new Error(result.data)
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(this.$t('errorMsg'))
      } finally {
        this.isSavingArea = false
      }
    },
    async getCompanyList() {
      this.isLoadingCompanyList = true
      try {
        let result = await getCompanyList(this)
        this.companyList = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(this.$t('errorMsg'))
      } finally {
        this.isLoadingCompanyList = false
      }
    },
    showAddArea(areaType, parentAreaId) {
      this.areaType = areaType
      this.parentAreaId = parentAreaId
      this.dialogAddArea = true
    },
    async addArea() {
      this.isSavingArea = true

      if (!this.$refs.addAreaForm.validate()) {
        this.isSavingArea = false
        return false
      }

      try {
        let result = await setArea(this, this.areaType.toLowerCase(), this.parentAreaId, this.areaName)

        if (result.data) {
          if (this.areaType === 'A') await this.getAreaA()
          else if (this.areaType === 'B') await this.getAreaB()
          else if (this.areaType === 'C') await this.getAreaC()

          this.dialogAddArea = false
        } else throw new Error(result.data)
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(this.$t('errorMsg'))
      } finally {
        this.isSavingArea = false
      }
    },
    showDelArea(areaType, areaId, areaName) {
      this.delAreaType = areaType
      this.delAreaId = areaId
      this.delAreaName = areaName
      this.dialogDelArea = true
    },
    async getDelAreaInfo() {
      this.isLoadingDelAreaInfo = true
      try {
        let result = await getDelAreaInfo(this, this.delAreaType, this.delAreaId)
        this.delAreaInfo = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(this.$t('errorMsg'))
      } finally {
        this.isLoadingDelAreaInfo = false
      }
    },
    async delArea() {
      this.isSavingArea = true

      try {
        let result = await delArea(this, this.delAreaType.toLowerCase(), this.delAreaId)

        if (result.data) {
          if (this.delAreaType === 'A') {
            await this.getAreaA()
            if (this.areaAId !== '') {
              this.areaAId = ''
              this.areaBList = null
            }
            if (this.areaBId !== '') {
              this.areaBId = ''
              this.areaCList = null
            }
          } else if (this.delAreaType === 'B') {
            await this.getAreaB()
            if (this.areaBId !== '') {
              this.areaBId = ''
              this.areaCList = null
            }
          } else if (this.delAreaType === 'C') {
            await this.getAreaC()
            if (this.areaCId !== '') this.areaCId = ''
          }

          this.dialogDelArea = false
        } else throw new Error(result.data)
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(this.$t('errorMsg'))
      } finally {
        this.isSavingArea = false
      }
    },
  },
}
</script>

<style scoped>
.box.area-wrap {
  background-color: transparent !important;
}
.overflow-auto {
  overflow-x: auto !important;
  overflow-y: scroll !important;
}
</style>
