<template>
  <Layout>
    <v-form
      slot="content"
      ref="signUpForm"
      class="text-center white"
      @submit.prevent="setResetPwWithEmail"
      lazy-validation
    >
      <h2 class="mb-4 mb-md-16 pb-2">{{ $t('resetPw') }}</h2>
      <v-container class="px-0">
        <v-row>
          <v-col class="text-left pb-0">
            <label for="email">{{ $t('email') }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-text-field
              dense
              ref="email"
              id="email"
              validate-on-blur
              hide-details="auto"
              :rules="[rules.required(email, 'email'), rules.email]"
              v-model="email"
              maxlength="50"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0 mt-12">
            <v-btn
              class="rounded-lg"
              type="submit"
              color="primary"
              block
              elevation="0"
              :height="$vuetify.breakpoint.mdAndUp ? 56 : 40"
              x-large
              :disabled="isLoading"
              :loading="isLoading"
              id="continueWithEmail"
              @click="setResetPwWithEmail"
            >
              {{ $t('sendResetPwEmail') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pt-0 pb-0 mt-5">
          <v-col class="pb-0 text-left">
            <router-link :to="{ name: 'SignIn' }" class="grey300--text text-decoration-none">
              {{ $t('goToSignIn') }}
            </router-link>
          </v-col>
        </v-row>
        <v-row v-if="showMsg">
          <v-col class="pt-0 pb-0 mt-5">
            <v-alert dense outlined v-html="errorMessage" />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </Layout>
</template>

<script>
import Layout from '@/layouts/Onboarding'
import firebase from 'firebase/app'
import { isNull, regExpTest } from '@/services/common'

export default {
  name: 'ResetPw',
  components: {
    Layout,
  },
  data: () => ({
    email: '',
    password: '',
    isLoading: false,
    showMsg: false,
    errorCode: '',
    errorMessage: '',
  }),
  computed: {
    rules() {
      let vm = this

      return {
        required: (value, type) => !isNull(value) || vm.$t('msgEnter' + type.charAt(0).toUpperCase() + type.slice(1)),
        email: (value) => regExpTest('EMAIL', value) || vm.$t('msgCheckEmailFormat'),
      }
    },
  },
  methods: {
    async setResetPwWithEmail() {
      this.isLoading = true
      this.errorCode = ''
      this.errorMessage = ''
      this.showMsg = false

      if (!this.$refs.signUpForm.validate()) {
        this.isLoading = false
        return false
      }

      try {
        await firebase.auth().sendPasswordResetEmail(this.email)
        this.$router.push({ name: 'ResetPwSent', params: { email: this.email } })
      } catch (error) {
        this.errorCode = error.code
        this.errorMessage =
          this.errorCode === 'auth/email-already-in-use' ? this.$t('msgEmailAlreadyInUse') : error.message
        this.showMsg = true
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
