import Vue from 'vue'
import Vuex from 'vuex'
import { getServiceInfo, setSignIn, getStandard } from '@/services/api'
import router from '../router'
import firebase from 'firebase/app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    serviceInfo: null,
    userInfo: null,
    standardInfo: null,
  },
  mutations: {
    // 서비스 정보 설정
    setServiceInfo(state, serviceInfo) {
      state.serviceInfo = serviceInfo
    },
    // 회원 정보 설정
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setStandard(state, standardInfo) {
      state.standardInfo = standardInfo
    },
  },
  actions: {
    // 서비스 정보 로드
    async getServiceInfo({ commit }) {
      try {
        let result = await getServiceInfo(this._vm)

        if (result.statusCode === 200) {
          let parsedServiceInfo = {}

          for (let item of result.body) {
            parsedServiceInfo[item.type] = item.data
          }

          await commit('setServiceInfo', parsedServiceInfo)
        }
      } catch (error) {
        if (error.message) console.log(error.message)
        if (error.response) console.log(error.response.status, error.response.data)
      }
    },
    // 회원 로그인 처리
    async setSignIn({ commit, dispatch }, signInInfo) {
      try {
        let result = await setSignIn(this._vm, signInInfo.deviceToken)

        if (result.status === 200) {
          if (result.data) {
            commit('setUserInfo', result.data)
            await dispatch('getStandard')
          } // else console.log(result.data) // else router.replace({ name: 'SignUp' })
        } else {
          // console.log(result.data)
        }
      } catch (error) {
        if (error.message) console.log(error.message)
        if (error.response) console.log(error.response.status, error.response.data)
      }
    },
    // 회원 정보 설정
    setUserInfo({ commit }, userInfo) {
      commit('setUserInfo', userInfo)
    },
    // 회원 로그아웃 처리
    async setSignOut({ commit }) {
      await firebase.auth().signOut()

      router.replace('/').catch(() => {})
      commit('setUserInfo', null)
    },
    // 기준 정보 조회
    async getStandard({ commit }) {
      try {
        let result = await getStandard(this._vm)

        if (result.status === 200) commit('setStandard', result.data)
      } catch (error) {
        if (error.message) console.log(error.message)
        if (error.response) console.log(error.response.status, error.response.data)
      }
    },
  },
  getters: {
    // 서비스 정보
    serviceInfo(state) {
      return state.serviceInfo
    },
    // 회원 정보
    userInfo(state) {
      return state.userInfo
    },
    // 기준 정보
    standardInfo(state) {
      return state.standardInfo
    },
  },
  modules: {},
})
