import Vue from 'vue'
import '@/plugins/firebase'
import firebase from 'firebase/app'
import '@/plugins/axios'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import '@/plugins/dayjs'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'

Vue.config.productionTip = false

let app

firebase.auth().onAuthStateChanged(async (user) => {
  // TODO: push notification 설정
  // let deviceToken = ''

  // 서비스 정보 가져오기
  if (!store.getters.serviceInfo) await store.dispatch('getServiceInfo')

  // 점검 중 상태 체크
  if (store.getters.serviceInfo?.STATUS.isMaintainance) router.push({ name: 'Maintenance' })
  // 점검 중이 아닐 때 정상 수행
  else {
    // URL에 전달된 쿼리 체크
    let params = new URLSearchParams(window.location.search)
    if (params.get('inviteKey') !== null) localStorage.setItem('inviteEmail', window.atob(params.get('inviteKey')))

    // 로그인 중일 때
    if (user) {
      // 퍼미션 체크하여 경로 이동
      await checkPermission(user)
    }
    // 비로그인 중일 때
    else {
      // 이메일 등으로 접속했을 경우
      if (firebase.auth().isSignInWithEmailLink(location.href)) {
        router.replace({ name: 'SignUp' })
      }
    }
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount('#app')
  }
})

// 퍼미션 체크하여 경로 이동
let checkPermission = async (user) => {
  // 회원가입 페이지가 아니면서, 회원 정보가 없을 경우 로그인 처리
  if (user && !store.getters.userInfo) await store.dispatch('setSignIn', { deviceToken: '' })
  // 로그인 시 정보를 가져오지 못하면 로그아웃 처리
  // if (!store.getters.userInfo) await store.dispatch('setSignOut')
  // 회원가입 페이지가 아니면 대시보드로 이동
  if (store.getters.userInfo) {
    if (
      // router.currentRoute.name == null ||
      router.currentRoute.name == 'SignUp' ||
      router.currentRoute.name == 'SignIn' ||
      router.currentRoute.name == 'ResetPw' ||
      router.currentRoute.name == 'ResetPwSent'
    )
      router.replace({ name: 'Gills' })
  }
}
