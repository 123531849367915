<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import './assets/css/NotoSansKR-Hestia.css';

* {
  font-family: 'Noto Sans KR', 'Apple SD Gothic Neo', 'Malgun Gothic', sans-serif !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -webkit-print-color-adjust: exact;
  /* print-color-adjust: exact; */
}

body,
.theme--light.v-application {
  background: #f9f9fb !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 9999s ease-out;
  /* -webkit-box-shadow: 0 0 0px 1000px white inset !important; */
}

.v-btn {
  letter-spacing: 0px !important;
}

.v-application .normal {
  background: linear-gradient(to bottom right, #414349, #202227) !important;
}
.v-application .safe {
  background: linear-gradient(to bottom right, #876dec, #2e2498) !important;
}
.v-application .warning {
  background: linear-gradient(to bottom right, #8edb32, #047002) !important;
}
.v-application .alert {
  background: linear-gradient(to bottom right, #f7da5b, #d4752c) !important;
}
.v-application .danger {
  background: linear-gradient(to bottom right, #e9794c, #9b0000) !important;
}
</style>
