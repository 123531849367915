<template>
  <Layout>
    <v-form
      slot="content"
      ref="signUpForm"
      class="text-center white"
      @submit.prevent="setResetPwWithEmail"
      lazy-validation
    >
      <h2 class="mb-4 mb-md-10 pb-2" v-html="$t('sendResetPwEmail')"></h2>
      <v-container class="px-0">
        <v-row>
          <v-col>
            <p class="email pa-0">{{ email }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col><p class="text pa-0" v-html="$t('msgSentResetPwEmail')"></p></v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0 mt-12">
            <v-btn
              class="rounded-lg"
              type="submit"
              color="primary"
              block
              elevation="0"
              :height="$vuetify.breakpoint.mdAndUp ? 56 : 40"
              x-large
              id="continueWithEmail"
              @click="$router.replace({ name: 'SignIn' })"
            >
              {{ $t('goToSignIn') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </Layout>
</template>

<script>
import Layout from '@/layouts/Onboarding'

export default {
  name: 'ResetPwSent',
  components: {
    Layout,
  },
  props: ['email'],
  created() {
    if (!this.email) this.$router.replace({ name: 'ResetPw' })
  },
}
</script>

<style scoped>
.email {
  line-height: 32px;
  font-size: 22px;
  font-weight: 500;
}

.small .email {
  font-size: 18px;
}

.text {
  line-height: 24px;
  font-size: 14px;
  color: var(--v-grey300-base);
}

.small .text {
  font-size: 12px;
}
</style>
