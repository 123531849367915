import Vue from 'vue'
import dayjs from 'dayjs'
require('dayjs/locale/ko')
var utc = require('dayjs/plugin/utc')
var relativeTime = require('dayjs/plugin/relativeTime')

dayjs.locale('ko')
dayjs.extend(utc)
dayjs.extend(relativeTime)

Vue.prototype.$day = dayjs
