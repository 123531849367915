var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('v-container',{staticClass:"pt-lg-15 pt-md-10 pb-0",attrs:{"slot":"title","fluid":""},slot:"title"},[_c('h2',{class:[{ 'font-size-30': _vm.$vuetify.breakpoint.mdAndUp }, { 'font-size-20': _vm.$vuetify.breakpoint.smAndDown }]},[_vm._v(" "+_vm._s(_vm.$t('management'))+" ")])]),_c('v-container',{staticClass:"pa-lg-4 pt-lg-10 pt-md-10 align-content-start",attrs:{"slot":"content","fluid":"","fill-height":""},slot:"content"},[_c('v-row',[_c('v-col',[_c('v-sheet',{staticClass:"box",attrs:{"outlined":"","color":"grey100","rounded":"lg"}},[_c('v-card',{staticClass:"py-4",attrs:{"tag":"section","rounded":"lg","elevation":"0"}},[_c('v-tabs',{attrs:{"color":"grey900","active-class":"font-weight-bold","slider-size":_vm.$vuetify.breakpoint.mdAndUp ? 4 : 3,"height":_vm.$vuetify.breakpoint.mdAndUp ? 48 : 36,"show-arrows":""}},[_c('v-tab',{class:[
                  'pa-0 mx-4 mx-md-10',
                  { 'font-size-18': _vm.$vuetify.breakpoint.mdAndUp },
                  { 'font-size-14': _vm.$vuetify.breakpoint.smAndDown } ],attrs:{"to":{ name: 'AreaManagement' }}},[_vm._v(" "+_vm._s(_vm.$t('areaManagement'))+" ")]),(_vm.userInfo['USER_LEVEL'] === 'G')?_c('v-tab',{class:[
                  'pa-0 mx-4 mx-md-10',
                  { 'font-size-18': _vm.$vuetify.breakpoint.mdAndUp },
                  { 'font-size-14': _vm.$vuetify.breakpoint.smAndDown } ],attrs:{"to":{ name: 'StandardManagement' }}},[_vm._v(" "+_vm._s(_vm.$t('standardManagement'))+" ")]):_vm._e(),(_vm.userInfo['USER_LEVEL'] === 'G')?_c('v-tab',{class:[
                  'pa-0 mx-4 mx-md-10',
                  { 'font-size-18': _vm.$vuetify.breakpoint.mdAndUp },
                  { 'font-size-14': _vm.$vuetify.breakpoint.smAndDown } ],attrs:{"to":{ name: 'CompanyManagement' }}},[_vm._v(" "+_vm._s(_vm.$t('companyManagement'))+" ")]):_vm._e(),_c('v-tab',{class:[
                  'pa-0 mx-4 mx-md-10',
                  { 'font-size-18': _vm.$vuetify.breakpoint.mdAndUp },
                  { 'font-size-14': _vm.$vuetify.breakpoint.smAndDown } ],attrs:{"to":{ name: 'UserManagement' }}},[_vm._v(" "+_vm._s(_vm.$t('userManagement'))+" ")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }