<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-sheet outlined color="grey100" rounded="lg" class="box">
          <v-card tag="section" rounded="lg" elevation="0" class="pa-4 pa-md-10">
            <v-container fill-height fluid class="pa-0">
              <v-row class="align-self-start justify-center">
                <v-col cols="12" sm="auto" class="pb-0 pb-sm-3">
                  <h3
                    :class="[
                      { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                      { 'font-size-16': $vuetify.breakpoint.smAndDown },
                    ]"
                  >
                    {{ $t('company') }}
                  </h3>
                </v-col>
                <v-col
                  v-if="companyList"
                  cols="auto"
                  :class="[
                    'd-flex align-center pa-2 pa-sm-3',
                    { 'font-size-14': $vuetify.breakpoint.mdAndUp },
                    { 'font-size-12': $vuetify.breakpoint.smAndDown },
                  ]"
                >
                  {{ $t('totalCompanyCount') }}&nbsp;
                  <strong>{{ companyList.length }}</strong>
                  {{ $t(companyList.length === 1 ? 'unitCompanies' : 'unitCompany') }}
                </v-col>
                <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
                <v-col cols="12" sm="auto">
                  <v-dialog persistent v-model="dialogAddCompany" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="rounded-lg"
                        block
                        color="grey600"
                        dark
                        elevation="0"
                        :small="$vuetify.breakpoint.smAndDown"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t('addCompany') }}
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="grey100">
                        {{ $t('addCompany') }}
                      </v-card-title>

                      <v-card-text>
                        <v-form ref="addCompanyForm" @submit.prevent="addCompany" v-model="isAddCompanyFormValid">
                          <v-container class="table black--text">
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col class="font-weight-bold" cols="4">
                                {{ $t('companyId') }}
                              </v-col>
                              <v-col cols="8">
                                <v-text-field
                                  v-model="companyId"
                                  dense
                                  outlined
                                  hide-details="auto"
                                  color="grey600"
                                  maxlength="20"
                                  :rules="[rules.required(companyId, 'companyId'), rules.upperEngOnly]"
                                  validate-on-blur
                                  class="rounded-lg"
                                  @keyup="checkCompanyId($event.target.value)"
                                />
                              </v-col>
                            </v-row>
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col class="font-weight-bold" cols="4">
                                {{ $t('companyName') }}
                              </v-col>
                              <v-col cols="8">
                                <v-text-field
                                  v-model="companyName"
                                  dense
                                  outlined
                                  hide-details="auto"
                                  color="grey600"
                                  maxlength="20"
                                  :rules="[rules.required(companyName, 'companyName')]"
                                  validate-on-blur
                                  class="rounded-lg"
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn class="rounded-lg" color="grey600" outlined @click="dialogAddCompany = false">
                          {{ $t('close') }}
                        </v-btn>
                        <v-btn
                          class="rounded-lg"
                          color="grey600"
                          elevation="0"
                          @click="addCompany"
                          :loading="isSavingAddCompany"
                          :disabled="isSavingAddCompany || !isAddCompanyFormValid"
                          :outlined="isSavingAddCompany || !isAddCompanyFormValid"
                          :dark="!isSavingAddCompany && isAddCompanyFormValid"
                        >
                          {{ $t('add') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row v-if="companyList !== null && companyList.length > 0">
                <v-col cols="12" sm="6" md="4" lg="3" v-for="company in companyList" :key="company['COMPANY_ID']">
                  <v-sheet rounded="lg" outlined color="grey100">
                    <v-card rounded="lg" elevation="0">
                      <v-container class="px-6 py-4">
                        <v-row no-gutters>
                          <v-col>
                            <p class="mb-0 font-weight-bold text-truncate">
                              {{ company['COMPANY_NAME'] }}
                            </p>
                            <p class="mb-0 text-truncate">
                              {{ company['COMPANY_ID'] }}
                            </p>
                            <p class="mb-0 font-size-14 text-truncate">
                              {{ $day(company['COMPANY_CREATED_DT']).format('YYYY.MM.DD') }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col class="text-center grey400--text py-16">
                  <v-progress-circular indeterminate v-if="isLoadingCompanyList" />
                  <span v-else>{{ $t('noData') }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getCompanyList, setCompany } from '@/services/api'
import { isNull } from '@/services/common'
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyManagement',
  data: () => ({
    dialogAddCompany: false,
    isLoadingCompanyList: false,
    isSavingAddCompany: false,
    isAddCompanyFormValid: false,
    companyList: null,
    companyId: '',
    companyName: '',
  }),
  computed: {
    ...mapGetters(['userInfo']),
    rules() {
      let vm = this

      return {
        required: (value, type) => !isNull(value) || vm.$t('msgEnter' + type.charAt(0).toUpperCase() + type.slice(1)),
        upperEngOnly: (value) => /[A-Z]/.test(value) || vm.$t('msgCheckCompanyIdFormat'),
      }
    },
  },
  watch: {
    dialogAddCompany() {
      if (this.dialogAddCompany) {
        this.isSavingAddCompany = false
        this.isAddCompanyFormValid = false
        this.companyId = ''
        this.companyName = ''
      } else {
        if (this.$refs.addCompanyForm) this.$refs.addCompanyForm.reset()
      }
    },
  },
  created() {
    // 길스 아이디 외에는 접근 불가
    if (this.userInfo['USER_LEVEL'] !== 'G') {
      this.$router.replace({ name: 'AreaManagement' })
    } else this.getCompanyList()
  },
  methods: {
    async getCompanyList() {
      this.isLoadingCompanyList = true
      try {
        let result = await getCompanyList(this)
        this.companyList = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(this.$t('errorMsg'))
      } finally {
        this.isLoadingCompanyList = false
      }
    },
    checkCompanyId(value) {
      if (/[^A-Z]/.test(value)) this.companyId = value.replace(/[^a-zA-Z]/gi, '').toUpperCase()
    },
    async addCompany() {
      this.isSavingAddCompany = true

      if (!this.$refs.addCompanyForm.validate()) {
        this.isSavingAddCompany = false
        return false
      }

      try {
        let result = await setCompany(this, this.companyId, this.companyName)
        if (result.data) {
          await this.getCompanyList()
          this.dialogAddCompany = false
        } else {
          alert(this.$t('addCompanyFailed'))
        }
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)

        alert(this.$t('errorMsg'))
      } finally {
        this.isSavingAddCompany = false
      }
    },
  },
}
</script>
