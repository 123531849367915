<template>
  <v-col v-if="$vuetify.breakpoint.mdAndUp">
    <v-row no-gutters>
      <v-col>
        <v-menu
          v-model="dialogDateFrom"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFrom"
              readonly
              outlined
              hide-details="auto"
              :label="$t('searchDateFrom')"
              append-icon="mdi-calendar"
              class="mx-1 rounded-lg"
              color="grey600"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateFrom"
            @input="
              dialogDateFrom = false
              checkDateTo()
            "
            locale="ko-kr"
            :day-format="(date) => $day(date).format('D')"
            :min="checkPeriodLimit('from', 'min')"
            :max="checkPeriodLimit('from', 'max')"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="dialogDateTo"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateTo"
              readonly
              outlined
              hide-details="auto"
              :label="$t('searchDateTo')"
              append-icon="mdi-calendar"
              class="mx-1 rounded-lg"
              color="grey600"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateTo"
            @input="dialogDateTo = false"
            locale="ko-kr"
            :day-format="(date) => $day(date).format('D')"
            :min="checkPeriodLimit('to', 'min')"
            :max="checkPeriodLimit('to', 'max')"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col></v-col>
    </v-row>
  </v-col>
  <v-col cols="12" v-else>
    <v-dialog v-model="dialogSearchDateSelect" max-width="540">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :height="$vuetify.breakpoint.smAndDown ? '40' : '56'"
          block
          outlined
          color="grey600"
          class="my-1 rounded-lg"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>mdi-calendar</v-icon>
          {{ dateFrom }} ~ {{ dateTo }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="grey100">{{ $t('selectSearchDate') }}</v-card-title>

        <v-card-text class="py-6 px-4">
          <v-container>
            <v-row>
              <v-col>
                <v-dialog
                  ref="dialogDateFrom"
                  v-model="dialogDateFrom"
                  :return-value.sync="dateFrom"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFrom"
                      readonly
                      outlined
                      hide-details="auto"
                      :label="$t('searchDateFrom')"
                      append-icon="mdi-calendar"
                      class="mx-1 rounded-lg"
                      color="grey600"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFrom"
                    locale="ko-kr"
                    :day-format="(date) => $day(date).format('D')"
                    @input="
                      $refs.dialogDateFrom.save(dateFrom)
                      checkDateTo()
                    "
                    :min="checkPeriodLimit('from', 'min')"
                    :max="checkPeriodLimit('from', 'max')"
                    scrollable
                  >
                    <v-spacer />
                    <v-btn class="rounded-lg" text color="primary" @click="dialogDateFrom = false">
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn class="rounded-lg" text color="primary" @click="$refs.dialogDateFrom.save(dateFrom)">
                      {{ $t('complete') }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-dialog ref="dialogDateTo" v-model="dialogDateTo" :return-value.sync="dateTo" persistent width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateTo"
                      readonly
                      outlined
                      hide-details="auto"
                      :label="$t('searchDateTo')"
                      append-icon="mdi-calendar"
                      class="mx-1 rounded-lg"
                      color="grey600"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateTo"
                    locale="ko-kr"
                    :day-format="(date) => $day(date).format('D')"
                    @input="$refs.dialogDateTo.save(dateTo)"
                    :min="checkPeriodLimit('to', 'min')"
                    :max="checkPeriodLimit('to', 'max')"
                    scrollable
                  >
                    <v-spacer />
                    <v-btn class="rounded-lg" text color="primary" @click="dialogDateTo = false">
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn class="rounded-lg" text color="primary" @click="$refs.dialogDateTo.save(dateTo)">
                      {{ $t('complete') }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <v-card-actions class="pt-0 pb-6 px-8">
          <v-spacer />
          <v-btn class="rounded-lg" color="grey600" dark elevation="0" @click="dialogSearchDateSelect = false">
            {{ $t('complete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
export default {
  name: 'SearchScheduleBox',
  props: ['isUseKeyword'],
  data: () => ({
    dialogSearchDateSelect: false,
    dateFrom: '',
    dateTo: '',
    dialogDateFrom: false,
    dialogDateTo: false,
    limitPeriodNum: 3,
    limitPeriodType: 'month',
  }),
  computed: {},
  watch: {
    dateFrom() {
      this.$emit('setSearchDtFrom', this.dateFrom ? this.$day(this.dateFrom).format() : '')
    },
    dateTo() {
      this.$emit('setSearchDtTo', this.dateTo ? this.$day(this.dateTo).format() : '')
    },
  },
  created() {
    this.dateFrom = this.$day().add(-3, 'M').format('YYYY-MM-DD')
    this.dateTo = this.$day().format('YYYY-MM-DD')
  },
  methods: {
    checkPeriodLimit(fromto, minmax) {
      let today = this.$day()

      if (fromto === 'from' && minmax === 'max') {
        return today.format('YYYY-MM-DD')
      }

      if (fromto === 'to' && minmax === 'min') {
        if (this.dateFrom !== '') return this.dateFrom
      }

      if (fromto === 'to' && minmax === 'max') {
        if (this.dateFrom !== '') {
          if (this.$day(this.dateFrom).add(this.limitPeriodNum, this.limitPeriodType).isBefore(today))
            return this.$day(this.dateFrom).add(this.limitPeriodNum, this.limitPeriodType).format('YYYY-MM-DD')
          else return today.format('YYYY-MM-DD')
        } else return today.format('YYYY-MM-DD')
      }
    },
    checkDateTo() {
      if (
        this.$day(this.dateTo).isAfter(this.$day(this.dateFrom).add(this.limitPeriodNum, this.limitPeriodType)) ||
        this.$day(this.dateFrom).isAfter(this.$day(this.dateTo))
      )
        this.dateTo = ''
    },
  },
}
</script>

<style scoped></style>
