<template>
  <Layout>
    <v-container class="pt-lg-15 pt-md-10 pb-0" fluid slot="title">
      <v-row no-gutters>
        <v-col cols="12" md="auto">
          <h2
            :class="[
              { 'font-size-30': $vuetify.breakpoint.mdAndUp },
              { 'font-size-20': $vuetify.breakpoint.smAndDown },
            ]"
          >
            {{ isLoadingGillsDetail ? $t('gillsControl') : gillsDetail['GILLS_NAME'] }}
            <span
              :class="[
                'font-weight-regular ml-3',
                { 'font-size-22': $vuetify.breakpoint.mdAndUp },
                { 'font-size-16': $vuetify.breakpoint.smAndDown },
              ]"
              v-if="!isLoadingGillsDetail"
            >
              {{ gillsDetail['GILLS_UDID'] }}
            </span>
            <br v-if="$vuetify.breakpoint.smAndDown" />
            <span
              :class="[
                'font-weight-regular grey300--text',
                { 'ml-3 font-size-16': $vuetify.breakpoint.mdAndUp },
                { 'font-size-14': $vuetify.breakpoint.smAndDown },
              ]"
              v-if="!isLoadingGillsDetail"
            >
              {{ gillsDetail['AREA_A']['AREA_A_NAME'] }} > {{ gillsDetail['AREA_B']['AREA_B_NAME'] }} >
              {{ gillsDetail['AREA_C']['AREA_C_NAME'] }}
            </span>
          </h2>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="auto" class="d-flex align-end justify-end">
          <span
            :class="[
              'grey600--text',
              { 'font-size-14': $vuetify.breakpoint.mdAndUp },
              { 'font-size-12': $vuetify.breakpoint.smAndDown },
              { 'mt-2 mb-1': $vuetify.breakpoint.smAndDown },
            ]"
          >
            {{ lastUpdatedTime ? $t('lastUpdate') + lastUpdatedTime : '' }}
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      class="pa-lg-4 pt-lg-10 pt-md-10 align-content-start"
      fluid
      fill-height
      slot="content"
      v-if="isLoadingGillsDetail"
    >
      <v-row>
        <v-col class="text-center grey400--text py-16">
          <v-progress-circular indeterminate />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pa-lg-4 pt-lg-10 pt-md-10 align-content-start" fluid fill-height slot="content" v-else>
      <v-row>
        <v-col>
          <v-sheet outlined color="grey100" rounded="lg" class="box" height="100%">
            <v-card tag="section" rounded="lg" elevation="0" height="100%" class="pa-4 pa-md-10">
              <v-container fill-height fluid class="pa-0">
                <v-row class="align-self-start justify-end" :class="[{ 'px-2': $vuetify.breakpoint.smAndDown }]">
                  <v-col cols="12" md="auto" :class="[{ 'px-0': $vuetify.breakpoint.smAndDown }]">
                    <h3
                      :class="[
                        { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                        { 'font-size-16': $vuetify.breakpoint.smAndDown },
                      ]"
                    >
                      {{ $t('gillsControl') }}
                    </h3>
                  </v-col>
                  <v-spacer />
                  <v-col cols="6" sm="auto" :class="[{ 'pa-1': $vuetify.breakpoint.smAndDown }]" v-if="!isControlMode">
                    <v-dialog
                      persistent
                      v-model="dialogGillsInfo"
                      width="600"
                      :fullscreen="$vuetify.breakpoint.smAndDown"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="rounded-lg"
                          block
                          color="grey600"
                          dark
                          elevation="0"
                          outlined
                          :small="$vuetify.breakpoint.smAndDown"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ $t('gillsInfo') }}
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="grey100">
                          {{ $t('gillsInfo') }}
                        </v-card-title>

                        <v-card-text v-if="editedGillsInfo">
                          <v-container class="table black--text">
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col class="font-weight-bold" cols="4" md="3">
                                {{ $t('deviceName') }}
                              </v-col>
                              <v-col>
                                <v-text-field
                                  dense
                                  outlined
                                  hide-details="auto"
                                  color="grey600"
                                  v-model="editedGillsInfo['GILLS_NAME']"
                                  maxlength="20"
                                  :disabled="isSavingGillsInfo"
                                  class="rounded-lg"
                                />
                              </v-col>
                            </v-row>
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col class="font-weight-bold" cols="4" md="3">
                                {{ $t('udid') }}
                              </v-col>
                              <v-col>
                                {{ gillsDetail['GILLS_UDID'] }}
                              </v-col>
                            </v-row>
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col class="font-weight-bold" cols="4" md="3">
                                {{ $t('installedArea') }}
                              </v-col>
                              <v-col cols="8" md="6" class="text-truncate">
                                {{
                                  gillsDetail['AREA_A']['AREA_A_NAME'] +
                                  (gillsDetail['AREA_B']['AREA_B_NAME']
                                    ? ' > ' + gillsDetail['AREA_B']['AREA_B_NAME']
                                    : '') +
                                  (gillsDetail['AREA_C']['AREA_C_NAME']
                                    ? ' > ' + gillsDetail['AREA_C']['AREA_C_NAME']
                                    : '')
                                }}
                              </v-col>
                              <v-spacer />
                              <v-col cols="12" md="auto">
                                <v-dialog persistent v-model="dialogGillsArea" max-width="540">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="rounded-lg"
                                      block
                                      color="grey600"
                                      dark
                                      elevation="0"
                                      outlined
                                      :small="$vuetify.breakpoint.smAndDown"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ $t('edit') }}
                                    </v-btn>
                                  </template>

                                  <v-card>
                                    <v-card-title class="grey100">{{ $t('installedArea') }}</v-card-title>

                                    <v-card-text class="py-6 px-4">
                                      <v-container>
                                        <v-row>
                                          <v-col>
                                            <v-select
                                              :items="areaAList"
                                              item-value="AREA_A_ID"
                                              item-text="AREA_A_NAME"
                                              :no-data-text="$t('msgNoData')"
                                              :loading="isLoadingAreaA"
                                              :disabled="isLoadingAreaA || isLoadingAreaB || isLoadingAreaC"
                                              outlined
                                              hide-details="auto"
                                              :label="$t('areaA')"
                                              v-model="areaAId"
                                              class="mx-1 rounded-lg"
                                              color="grey600"
                                            />
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-select
                                              :items="areaBList"
                                              item-value="AREA_B_ID"
                                              item-text="AREA_B_NAME"
                                              :no-data-text="$t('msgNoData')"
                                              :loading="isLoadingAreaB"
                                              :disabled="
                                                isLoadingAreaA || isLoadingAreaB || isLoadingAreaC || areaAId === ''
                                              "
                                              outlined
                                              hide-details="auto"
                                              :label="$t('areaB')"
                                              v-model="areaBId"
                                              class="mx-1 rounded-lg"
                                              color="grey600"
                                            />
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-select
                                              :items="areaCList"
                                              item-value="AREA_C_ID"
                                              item-text="AREA_C_NAME"
                                              :no-data-text="$t('msgNoData')"
                                              :loading="isLoadingAreaC"
                                              :disabled="
                                                isLoadingAreaA ||
                                                isLoadingAreaB ||
                                                isLoadingAreaC ||
                                                areaAId === '' ||
                                                areaBId === ''
                                              "
                                              outlined
                                              hide-details="auto"
                                              :label="$t('areaC')"
                                              v-model="areaCId"
                                              class="mx-1 rounded-lg"
                                              color="grey600"
                                            />
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-card-text>

                                    <!-- <v-divider></v-divider> -->

                                    <v-card-actions class="pt-0 pb-6 px-8">
                                      <v-spacer />
                                      <v-btn
                                        class="rounded-lg"
                                        color="grey600"
                                        outlined
                                        @click="dialogGillsArea = false"
                                        :disabled="isSavingGillsArea"
                                      >
                                        {{ $t('cancel') }}
                                      </v-btn>
                                      <v-btn
                                        class="rounded-lg"
                                        color="grey600"
                                        elevation="0"
                                        @click="setGillsArea"
                                        :loading="isSavingGillsArea"
                                        :disabled="
                                          isSavingGillsArea ||
                                          isLoadingAreaA ||
                                          isLoadingAreaB ||
                                          isLoadingAreaC ||
                                          areaAId === '' ||
                                          areaBId === '' ||
                                          areaCId === ''
                                        "
                                        :outlined="
                                          isSavingGillsArea ||
                                          isLoadingAreaA ||
                                          isLoadingAreaB ||
                                          isLoadingAreaC ||
                                          areaAId === '' ||
                                          areaBId === '' ||
                                          areaCId === ''
                                        "
                                        :dark="
                                          !isSavingGillsArea &&
                                          !isLoadingAreaA &&
                                          !isLoadingAreaB &&
                                          !isLoadingAreaC &&
                                          areaAId !== '' &&
                                          areaBId !== '' &&
                                          areaCId !== ''
                                        "
                                      >
                                        {{ $t('save') }}
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-col>
                            </v-row>
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col class="font-weight-bold" cols="4" md="3">
                                {{ $t('manager') }}
                              </v-col>
                              <v-col cols="8" md="6" class="text-truncate">
                                {{ managerList.map((manager) => manager['USER_NAME']).join(', ') }}
                              </v-col>
                              <v-spacer />
                              <v-col cols="12" md="auto">
                                <v-dialog
                                  persistent
                                  v-model="dialogGillsUser"
                                  max-width="600"
                                  :fullscreen="$vuetify.breakpoint.smAndDown"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="rounded-lg"
                                      block
                                      color="grey600"
                                      dark
                                      elevation="0"
                                      outlined
                                      :small="$vuetify.breakpoint.smAndDown"
                                      :disabled="isLoadingManagerList"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ $t('edit') }}
                                    </v-btn>
                                  </template>
                                  <v-card>
                                    <v-card-title class="grey100">{{ $t('manager') }}</v-card-title>

                                    <v-card-text class="py-6 px-4">
                                      <v-container>
                                        <v-row v-if="gillsUserList !== null && gillsUserListPage['totalCount'] > 0">
                                          <v-col class="overflow-auto">
                                            <v-responsive min-width="500">
                                              <v-container
                                                fluid
                                                :class="[
                                                  'table',
                                                  { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                                                  { 'font-size-14': $vuetify.breakpoint.smAndDown },
                                                ]"
                                              >
                                                <v-row
                                                  :class="[
                                                    'thead align-center text-center',
                                                    { dense: $vuetify.breakpoint.smAndDown },
                                                  ]"
                                                >
                                                  <v-col cols="2" class="font-weight-bold"></v-col>
                                                  <v-col cols="4" class="font-weight-bold">{{ $t('name') }}</v-col>
                                                  <v-col cols="6" class="font-weight-bold">{{ $t('tel') }}</v-col>
                                                </v-row>
                                                <v-row
                                                  :class="[
                                                    'tbody align-center',
                                                    { dense: $vuetify.breakpoint.smAndDown },
                                                  ]"
                                                  v-for="user in gillsUserList"
                                                  :key="user['USER_ID']"
                                                >
                                                  <v-col cols="2" class="text-center">
                                                    <v-checkbox
                                                      value
                                                      :input-value="editedGillsManager.indexOf(user['USER_ID']) > -1"
                                                      @change="checkGillsManager($event, user['USER_ID'])"
                                                      hide-details
                                                      class="ma-0 d-inline-block"
                                                    />
                                                  </v-col>
                                                  <v-col cols="4" class="text-center">{{ user['USER_NAME'] }}</v-col>
                                                  <v-col cols="6" class="text-center">{{ user['USER_TEL'] }}</v-col>
                                                </v-row>
                                              </v-container>
                                            </v-responsive>
                                          </v-col>
                                        </v-row>
                                        <v-row v-if="gillsUserListPage !== null && gillsUserListPage['totalCount'] > 0">
                                          <v-col>
                                            <v-pagination
                                              v-model="userPage"
                                              :length="gillsUserListPage['totalPage']"
                                              @input="getGillsUser"
                                            />
                                          </v-col>
                                        </v-row>
                                        <v-row v-else>
                                          <v-col class="text-center grey400--text py-16">
                                            <v-progress-circular indeterminate v-if="isLoadingGillsUser" />
                                            <span v-else>{{ $t('noData') }}</span>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-card-text>

                                    <!-- <v-divider></v-divider> -->

                                    <v-card-actions class="pt-0 pb-6 px-8">
                                      <v-spacer />
                                      <v-btn
                                        class="rounded-lg"
                                        color="grey600"
                                        outlined
                                        @click="dialogGillsUser = false"
                                        :disabled="isSavingGillsManager"
                                      >
                                        {{ $t('cancel') }}
                                      </v-btn>
                                      <v-btn
                                        class="rounded-lg"
                                        color="grey600"
                                        elevation="0"
                                        @click="setGillsManager"
                                        :loading="isSavingGillsManager"
                                        :disabled="isSavingGillsManager"
                                        :outlined="isSavingGillsManager"
                                        :dark="!isSavingGillsManager"
                                      >
                                        {{ $t('save') }}
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-col>
                            </v-row>
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col class="font-weight-bold" cols="4" md="3">
                                {{ $t('deviceVer') }}
                              </v-col>
                              <v-col>
                                {{ gillsDetail['GILLS_DEV_VER'] }}
                              </v-col>
                            </v-row>
                            <v-row
                              :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]"
                              v-if="gillsDetail['GILLS_DEV_VER'] !== 'G2-REV2.0'"
                            >
                              <v-col class="font-weight-bold" cols="4" md="3">
                                {{ $t('fwVer') }}
                              </v-col>
                              <v-col>
                                {{ gillsDetail['GILLS_FW_VER'] }}
                              </v-col>
                            </v-row>
                            <v-row
                              :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]"
                              v-if="gillsDetail['GILLS_DEV_VER'] === 'G2-REV2.0'"
                            >
                              <v-col class="font-weight-bold" cols="4" md="3">
                                {{ $t('cdpVer') }}
                              </v-col>
                              <v-col>
                                {{ gillsDetail['GILLS_CDP_VERSION'] }}
                              </v-col>
                            </v-row>
                            <v-row
                              :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]"
                              v-if="gillsDetail['GILLS_DEV_VER'] === 'G2-REV2.0'"
                            >
                              <v-col class="font-weight-bold" cols="4" md="3">
                                {{ $t('gsenVer') }}
                              </v-col>
                              <v-col>
                                {{ gillsDetail['GILLS_GSEN_VERSION'] }}
                              </v-col>
                            </v-row>
                            <v-row
                              :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]"
                              v-if="gillsDetail['GILLS_DEV_VER'] === 'G2-REV2.0'"
                            >
                              <v-col class="font-weight-bold" cols="4" md="3">
                                {{ $t('ctrlVer') }}
                              </v-col>
                              <v-col>
                                {{ gillsDetail['GILLS_CTRL_VERSION'] }}
                              </v-col>
                            </v-row>
                            <v-row
                              :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]"
                              v-if="gillsDetail['GILLS_DEV_VER'] === 'G2-REV2.0'"
                            >
                              <v-col class="font-weight-bold" cols="4" md="3">
                                {{ $t('serverVer') }}
                              </v-col>
                              <v-col>
                                {{ gillsDetail['GILLS_SERVER_VERSION'] }}
                              </v-col>
                            </v-row>
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col class="font-weight-bold" cols="4" md="3">
                                {{ $t('installedDate') }}
                              </v-col>
                              <v-col>
                                <v-dialog
                                  ref="dialogGillsInstDate"
                                  v-model="dialogGillsInstDate"
                                  :return-value.sync="editedGillsInfo['GILLS_INST_DATE']"
                                  persistent
                                  width="290"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="editedGillsInfo['GILLS_INST_DATE']"
                                      dense
                                      readonly
                                      outlined
                                      hide-details="auto"
                                      append-icon="mdi-calendar"
                                      class="mx-1 rounded-lg"
                                      color="grey600"
                                      v-bind="attrs"
                                      v-on="on"
                                      :disabled="isSavingGillsInfo"
                                      maxlength="10"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="editedGillsInfo['GILLS_INST_DATE']"
                                    locale="ko-kr"
                                    :day-format="(date) => $day(date).format('D')"
                                    @input="$refs.dialogGillsInstDate.save(editedGillsInfo['GILLS_INST_DATE'])"
                                    scrollable
                                  >
                                    <v-spacer />
                                    <v-btn class="rounded-lg" text color="primary" @click="dialogGillsInstDate = false">
                                      {{ $t('cancel') }}
                                    </v-btn>
                                    <v-btn
                                      class="rounded-lg"
                                      text
                                      color="primary"
                                      @click="$refs.dialogGillsInstDate.save(editedGillsInfo['GILLS_INST_DATE'])"
                                    >
                                      {{ $t('complete') }}
                                    </v-btn>
                                  </v-date-picker>
                                </v-dialog>
                              </v-col>
                            </v-row>
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col>
                                <v-row>
                                  <v-col cols="auto" class="font-weight-bold pb-0">
                                    {{ $t('relatedGsen') }}
                                    <span class="ml-3 ml-md-5 font-size-12 grey300--text font-weight-regular">
                                      {{ $t('relatedGsenNotice') }}
                                    </span>
                                  </v-col>
                                  <v-spacer />
                                  <v-col cols="12" md="auto" class="pb-0" v-if="$vuetify.breakpoint.mdAndUp">
                                    <v-btn
                                      class="rounded-lg"
                                      block
                                      color="grey600"
                                      dark
                                      elevation="0"
                                      outlined
                                      :small="$vuetify.breakpoint.smAndDown"
                                      @click="dialogGillsGsen = true"
                                    >
                                      {{ $t('edit') }}
                                    </v-btn>
                                  </v-col>
                                </v-row>
                                <v-row
                                  v-if="gillsDetail['GSEN'].length > 0"
                                  class="overflow-y-auto"
                                  style="height: 150px"
                                >
                                  <v-col class="py-0">
                                    <v-container class="pa-0">
                                      <v-row no-gutters v-for="gsen in gillsDetail['GSEN']" :key="gsen['GSEN_UDID']">
                                        <v-col cols="4" md="3" class="py-1 text-truncate">
                                          {{ gsen['GSEN_NAME'] }}
                                        </v-col>
                                        <v-col cols="7" md="8" offset="1" class="py-1 text-truncate">
                                          {{
                                            gsen['IS_EXT'] === 0
                                              ? $t('internalGsen')
                                              : gsen['AREA_A']['AREA_A_NAME'] +
                                                (gsen['AREA_B']['AREA_B_NAME']
                                                  ? ' > ' + gsen['AREA_B']['AREA_B_NAME']
                                                  : '') +
                                                (gsen['AREA_C']['AREA_C_NAME']
                                                  ? ' > ' + gsen['AREA_C']['AREA_C_NAME']
                                                  : '')
                                          }}
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-col>
                                </v-row>
                                <v-row v-else>
                                  <v-col class="text-center grey400--text py-16">
                                    <span>{{ $t('noData') }}</span>
                                  </v-col>
                                </v-row>
                                <v-row v-if="$vuetify.breakpoint.smAndDown">
                                  <v-col cols="12" md="auto">
                                    <v-btn
                                      class="rounded-lg"
                                      block
                                      color="grey600"
                                      dark
                                      elevation="0"
                                      outlined
                                      :small="$vuetify.breakpoint.smAndDown"
                                      @click="dialogGillsGsen = true"
                                    >
                                      {{ $t('edit') }}
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-dialog
                            persistent
                            v-model="dialogGillsGsen"
                            max-width="600"
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                          >
                            <v-card>
                              <v-card-title class="grey100">{{ $t('relatedGsen') }}</v-card-title>

                              <v-card-text class="py-6 px-4">
                                <search-box
                                  :isUseKeyword="false"
                                  :isUseKeywordOffset="false"
                                  @setAreaAId="setAreaAId"
                                  @setAreaBId="setAreaBId"
                                  @setAreaCId="setAreaCId"
                                  @requestSearch="getGillsGsen"
                                  v-if="dialogGillsGsen"
                                />
                                <v-container>
                                  <v-row v-if="gillsGsenList !== null && gillsGsenListPage['totalCount'] > 0">
                                    <v-col class="overflow-auto">
                                      <v-responsive min-width="500">
                                        <v-container
                                          fluid
                                          :class="[
                                            'table',
                                            { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                                            { 'font-size-14': $vuetify.breakpoint.smAndDown },
                                          ]"
                                        >
                                          <v-row
                                            :class="[
                                              'thead align-center text-center',
                                              { dense: $vuetify.breakpoint.smAndDown },
                                            ]"
                                          >
                                            <v-col cols="2" class="font-weight-bold"></v-col>
                                            <v-col cols="4" class="font-weight-bold">{{ $t('name') }}</v-col>
                                            <v-col cols="6" class="font-weight-bold">{{ $t('udid') }}</v-col>
                                          </v-row>
                                          <v-row
                                            :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]"
                                            v-for="gsen in gillsGsenList"
                                            :key="gsen['GSEN_UDID']"
                                          >
                                            <v-col cols="2" class="text-center">
                                              <v-checkbox
                                                value
                                                :input-value="editedGsenList.indexOf(gsen['GSEN_UDID']) > -1"
                                                @change="checkGillsGsen($event, gsen['GSEN_UDID'])"
                                                hide-details
                                                class="ma-0 d-inline-block"
                                                :readonly="gsen['IS_EXT'] === 0"
                                              />
                                            </v-col>
                                            <v-col cols="4" class="text-center">
                                              {{ gsen['GSEN_NAME'] }}
                                            </v-col>
                                            <v-col cols="6" class="text-center">
                                              {{ gsen['GSEN_UDID'] }}
                                            </v-col>
                                          </v-row>
                                        </v-container>
                                      </v-responsive>
                                    </v-col>
                                  </v-row>
                                  <v-row v-if="gillsGsenListPage !== null && gillsGsenListPage['totalCount'] > 0">
                                    <v-col>
                                      <v-pagination
                                        v-model="gsenPage"
                                        :length="gillsGsenListPage['totalPage']"
                                        @input="getGillsGsen"
                                      />
                                    </v-col>
                                  </v-row>
                                  <v-row v-else>
                                    <v-col class="text-center grey400--text py-16">
                                      <v-progress-circular indeterminate v-if="isLoadingGillsGsen" />
                                      <span v-else>{{ $t('noData') }}</span>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <!-- <v-divider></v-divider> -->

                              <v-card-actions class="pt-0 pb-6 px-8">
                                <v-spacer />
                                <v-btn
                                  class="rounded-lg"
                                  color="grey600"
                                  outlined
                                  @click="dialogGillsGsen = false"
                                  :disabled="isSavingGillsGsen"
                                >
                                  {{ $t('cancel') }}
                                </v-btn>
                                <v-btn
                                  class="rounded-lg"
                                  color="grey600"
                                  elevation="0"
                                  @click="setGillsGsen"
                                  :loading="isSavingGillsGsen"
                                  :disabled="isSavingGillsGsen"
                                  :outlined="isSavingGillsGsen"
                                  :dark="!isSavingGillsGsen"
                                >
                                  {{ $t('save') }}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            class="rounded-lg"
                            color="grey600"
                            outlined
                            @click="dialogGillsInfo = false"
                            :disabled="isSavingGillsInfo"
                          >
                            {{ $t('close') }}
                          </v-btn>
                          <v-btn
                            class="rounded-lg"
                            color="grey600"
                            elevation="0"
                            @click="setGillsInfo"
                            :loading="isSavingGillsInfo"
                            :disabled="isSavingGillsInfo"
                            :outlined="isSavingGillsInfo"
                            :dark="!isSavingGillsInfo"
                          >
                            {{ $t('save') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="auto"
                    :class="[{ 'pa-1': $vuetify.breakpoint.smAndDown }]"
                    v-if="!isControlMode && gillsDetail['GILLS_DEV_VER'] !== 'G2-REV2.0'"
                  >
                    <v-dialog
                      persistent
                      v-model="dialogGillsOpTime"
                      width="540"
                      :fullscreen="$vuetify.breakpoint.smAndDown"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="rounded-lg"
                          block
                          color="grey600"
                          dark
                          elevation="0"
                          outlined
                          :small="$vuetify.breakpoint.smAndDown"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ $t('setOpTime') }}
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="grey100">
                          {{ $t('setOpTime') }}
                        </v-card-title>

                        <v-card-text>
                          <v-container class="table black--text">
                            <v-row v-if="opTimeErrorMsg">
                              <v-col class="px-0">
                                <v-alert
                                  dense
                                  text
                                  :class="[
                                    'a-0 text-center',
                                    { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                                    { 'font-size-14': $vuetify.breakpoint.smAndDown },
                                  ]"
                                  v-html="opTimeErrorMsg"
                                />
                              </v-col>
                            </v-row>
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col class="font-weight-bold pb-0" cols="12">
                                {{ $t('selectDay') }}
                              </v-col>
                              <v-col cols="12" :class="$vuetify.breakpoint.smAndDown ? 'px-2' : 'px-1'">
                                <v-container>
                                  <v-row class="d-flex justify-space-between">
                                    <v-col
                                      :class="['py-0', $vuetify.breakpoint.smAndDown ? 'px-1' : 'px-2']"
                                      v-for="day in 7"
                                      :key="day - 1"
                                    >
                                      <v-btn
                                        color="grey600"
                                        elevation="0"
                                        :outlined="dayOfWeek !== day - 1"
                                        :dark="dayOfWeek === day - 1"
                                        @click="dayOfWeek = day - 1"
                                        class="pa-0 rounded-lg"
                                        min-width="100%"
                                        :small="$vuetify.breakpoint.smAndDown"
                                        :height="$vuetify.breakpoint.smAndDown ? 36 : 48"
                                      >
                                        {{
                                          $day()
                                            .day(day - 1)
                                            .format('ddd')
                                        }}
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-col>
                            </v-row>
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col class="font-weight-bold pb-0" cols="12">
                                {{ $t('selectOpTime') }}
                              </v-col>
                              <v-col cols="12" class="pa-2">
                                <v-container>
                                  <v-row :class="$vuetify.breakpoint.mdAndUp ? 'd-flex justify-space-between' : ''">
                                    <v-col cols="9" md="auto" class="pa-0">
                                      <v-container>
                                        <v-row>
                                          <v-col cols="6" class="pa-1">
                                            <v-select
                                              outlined
                                              dense
                                              hide-details
                                              height="20"
                                              class="pa-0 font-size-14 rounded-lg"
                                              :items="hourList"
                                              v-model="startHour"
                                            />
                                          </v-col>
                                          <v-col cols="6" class="pa-1">
                                            <v-select
                                              outlined
                                              dense
                                              hide-details
                                              height="20"
                                              class="pa-0 font-size-14 rounded-lg"
                                              :items="minList"
                                              v-model="startMin"
                                            />
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-col>
                                    <v-col class="pa-1 d-flex align-center justify-center" cols="3" md="auto">~</v-col>
                                    <v-col cols="9" md="auto" class="pa-0">
                                      <v-container>
                                        <v-row>
                                          <v-col cols="6" class="pa-1">
                                            <v-select
                                              outlined
                                              dense
                                              hide-details
                                              height="20"
                                              class="pa-0 font-size-14 rounded-lg"
                                              :items="hourList"
                                              v-model="endHour"
                                            />
                                          </v-col>
                                          <v-col cols="6" class="pa-1">
                                            <v-select
                                              outlined
                                              dense
                                              hide-details
                                              height="20"
                                              class="pa-0 font-size-14 rounded-lg"
                                              :items="[...minList, '59']"
                                              v-model="endMin"
                                            />
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-col>
                                    <v-col class="pa-1 d-flex align-center justify-center" cols="3" md="auto">
                                      <v-btn
                                        class="rounded-lg"
                                        color="grey600"
                                        elevation="0"
                                        height="100%"
                                        :dark="editedGillsOpTime && opTimeErrorMsg === ''"
                                        :disabled="!editedGillsOpTime || opTimeErrorMsg !== ''"
                                        @click="addGillsOpTime"
                                      >
                                        <v-icon>mdi-plus</v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-col>
                            </v-row>
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col class="font-weight-bold pb-0" cols="12">
                                {{ $t('opSchedule') }}
                              </v-col>
                              <v-col class="pa-2">
                                <v-container>
                                  <v-row v-if="editedGillsOpTime && editedGillsOpTime.length > 0">
                                    <v-col
                                      cols="12"
                                      md="6"
                                      class="pa-1"
                                      v-for="(time, index) in editedGillsOpTime"
                                      :key="index"
                                    >
                                      <v-btn
                                        class="rounded-lg"
                                        color="grey100"
                                        outlined
                                        block
                                        @click="editedGillsOpTime.splice(index, 1)"
                                      >
                                        <v-spacer />
                                        <span class="grey900--text">
                                          {{
                                            `${$day().day(time['DAY_OF_WEEK']).format('dddd')} ${time['START_HOUR']
                                              .toString()
                                              .padStart(2, '0')}:${time['START_MIN']
                                              .toString()
                                              .padStart(2, '0')} ~ ${time['END_HOUR']
                                              .toString()
                                              .padStart(2, '0')}:${time['END_MIN'].toString().padStart(2, '0')}`
                                          }}
                                        </span>
                                        <v-spacer />
                                        <v-icon color="grey200" dense>mdi-close</v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                  <v-row v-else>
                                    <v-col class="text-center grey400--text py-16">
                                      <v-progress-circular indeterminate v-if="isLoadingGillsOpTime" />
                                      <span v-else>{{ $t('noData') }}</span>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-btn
                            class="rounded-lg"
                            color="grey600"
                            outlined
                            @click="editedGillsOpTime = gillsOpTime.slice()"
                            :disabled="isLoadingGillsOpTime"
                          >
                            {{ $t('revert') }}
                          </v-btn>
                          <v-spacer />
                          <v-btn
                            class="rounded-lg"
                            color="grey600"
                            outlined
                            @click="dialogGillsOpTime = false"
                            :disabled="isSavingGillsOpTime"
                          >
                            {{ $t('close') }}
                          </v-btn>
                          <v-btn
                            class="rounded-lg"
                            color="grey600"
                            elevation="0"
                            @click="setGillsOpTime"
                            :loading="isLoadingGillsOpTime || isSavingGillsOpTime"
                            :disabled="isLoadingGillsOpTime || isSavingGillsOpTime"
                            :outlined="isLoadingGillsOpTime || isSavingGillsOpTime"
                            :dark="!isLoadingGillsOpTime && !isSavingGillsOpTime"
                          >
                            {{ $t('save') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="auto"
                    :class="[{ 'pa-1': $vuetify.breakpoint.smAndDown }]"
                    v-if="!isControlMode && gillsDetail['GILLS_DEV_VER'] === 'G2-REV2.0'"
                  >
                    <v-dialog
                      persistent
                      v-model="dialogGillsOpTime"
                      width="540"
                      :fullscreen="$vuetify.breakpoint.smAndDown"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="rounded-lg"
                          block
                          color="grey600"
                          dark
                          elevation="0"
                          outlined
                          :small="$vuetify.breakpoint.smAndDown"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ $t('setAutoOpSchedule') }}
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="grey100">
                          {{ $t('setAutoOpSchedule') }}
                        </v-card-title>

                        <v-card-text>
                          <v-container
                            class="table black--text"
                            v-if="editedGillsOpTime && editedGillsOpTime.length > 0"
                          >
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col class="font-weight-bold pb-0" cols="12">
                                {{ $t('opDay') }}
                              </v-col>
                              <v-col cols="12" :class="$vuetify.breakpoint.smAndDown ? 'px-2' : 'px-1'">
                                <v-container>
                                  <v-row class="d-flex justify-space-between">
                                    <v-col
                                      :class="['py-0', $vuetify.breakpoint.smAndDown ? 'px-1' : 'px-2']"
                                      v-for="day in 7"
                                      :key="day - 1"
                                    >
                                      <v-btn
                                        color="grey600"
                                        elevation="0"
                                        :dark="
                                          editedGillsOpTime
                                            .map((time) => time['DAY_OF_WEEK'])
                                            .filter((time) => time === day - 1).length > 0
                                        "
                                        :outlined="
                                          editedGillsOpTime
                                            .map((time) => time['DAY_OF_WEEK'])
                                            .filter((time) => time === day - 1).length === 0
                                        "
                                        class="pa-0 rounded-lg"
                                        min-width="100%"
                                        :small="$vuetify.breakpoint.smAndDown"
                                        :height="$vuetify.breakpoint.smAndDown ? 36 : 48"
                                        readonly
                                      >
                                        {{
                                          $day()
                                            .day(day - 1)
                                            .format('ddd')
                                        }}
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-col>
                            </v-row>
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col class="font-weight-bold pb-0" cols="5">
                                {{ $t('startTime') }}
                              </v-col>
                              <v-col class="font-weight-bold pb-0" cols="2" />
                              <v-col class="font-weight-bold pb-0 pl-1" cols="5">
                                {{ $t('endTime') }}
                              </v-col>
                              <v-col cols="12" class="pa-2">
                                <v-container>
                                  <v-row :class="$vuetify.breakpoint.mdAndUp ? 'd-flex justify-space-between' : ''">
                                    <v-col cols="5" class="pa-0">
                                      <v-text-field
                                        outlined
                                        dense
                                        hide-details
                                        height="20"
                                        class="pa-0 font-size-14 rounded-lg"
                                        :value="convertOpTime('start')"
                                        readonly
                                      />
                                    </v-col>
                                    <v-col class="pa-1 d-flex align-center justify-center" cols="2" />
                                    <v-col cols="5" class="pa-0">
                                      <v-text-field
                                        outlined
                                        dense
                                        hide-details
                                        height="20"
                                        class="pa-0 font-size-14 rounded-lg"
                                        :value="convertOpTime('end')"
                                        readonly
                                      />
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-container v-else>
                            <v-row>
                              <v-col class="text-center grey400--text py-16">
                                <v-progress-circular indeterminate v-if="isLoadingGillsOpTime" />
                                <span v-else>{{ $t('noData') }}</span>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            class="rounded-lg"
                            color="grey600"
                            outlined
                            @click="dialogGillsOpTime = false"
                            :disabled="isSavingGillsOpTime"
                          >
                            {{ $t('close') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col cols="6" sm="auto" :class="[{ 'pa-1': $vuetify.breakpoint.smAndDown }]" v-if="isControlMode">
                    <v-btn
                      class="rounded-lg"
                      block
                      color="grey600"
                      elevation="0"
                      outlined
                      :small="$vuetify.breakpoint.smAndDown"
                      @click="isControlMode = false"
                      :disabled="isSavingControl"
                      :dark="!isSavingControl"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="auto"
                    :class="[{ 'pa-1': $vuetify.breakpoint.smAndDown }]"
                    v-if="gillsDetail['GILLS_DEV_VER'] !== 'G2-REV2.0'"
                  >
                    <v-btn
                      class="rounded-lg"
                      block
                      :color="!isSavingControl ? 'grey600' : ''"
                      :dark="!isSavingControl"
                      elevation="0"
                      :small="$vuetify.breakpoint.smAndDown"
                      @click="saveControl"
                      :disabled="isSavingControl"
                      :loading="isSavingControl"
                      v-if="isControlMode"
                    >
                      {{ $t('saveChanges') }}
                    </v-btn>
                    <v-btn
                      class="rounded-lg"
                      block
                      color="grey600"
                      dark
                      elevation="0"
                      :small="$vuetify.breakpoint.smAndDown"
                      @click="isControlMode = !isControlMode"
                      v-else
                    >
                      {{ $t('controlMode') }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="isSavingControl">
                  <v-col class="d-flex justify-center align-center">
                    <v-progress-circular indeterminate color="grey800"></v-progress-circular>
                  </v-col>
                </v-row>
                <v-row class="align-self-baseline" v-else>
                  <v-col class="d-flex justify-center align-center px-0">
                    <v-container fluid>
                      <v-row :class="[{ 'px-2': $vuetify.breakpoint.smAndDown }]">
                        <v-col
                          cols="6"
                          md="3"
                          v-for="(control, index) in gillsDetail['GILLS_DEV_VER'] !== 'G2-REV2.0'
                            ? controlListG1
                            : controlListG2"
                          :key="control['key']"
                          :class="['py-1', { 'px-1': $vuetify.breakpoint.smAndDown }]"
                        >
                          <v-sheet outlined :color="getColorByStatus(control['key'])" rounded="lg">
                            <v-card rounded="lg" elevation="0" class="pa-3 pa-md-6">
                              <v-container fluid>
                                <v-row>
                                  <v-col class="pa-0 mr-3 mr-sm-6 mr-lg-0">
                                    <h4
                                      :class="[
                                        { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                                        { 'font-size-14': $vuetify.breakpoint.smAndDown },
                                      ]"
                                    >
                                      {{ $t(control['name']) }}
                                    </h4>
                                    <p
                                      :class="[
                                        'ma-0',
                                        { 'font-size-14': $vuetify.breakpoint.mdAndUp },
                                        { 'font-size-12': $vuetify.breakpoint.smAndDown },
                                      ]"
                                    >
                                      <span
                                        v-if="
                                          isControlMode &&
                                          control['name'].indexOf('MOTOR') > -1 &&
                                          gillsDetail['RELAY'][control['key']] !== null &&
                                          gillsDetail['RELAY'][control['key']] > -1
                                        "
                                        class="d-flex justify-space-between"
                                      >
                                        <span>{{ $t('speed') }}</span>
                                        <v-slider
                                          v-model="controlValue[index]"
                                          max="100"
                                          min="0"
                                          step="10"
                                          hide-details
                                          dense
                                          thumb-size="12"
                                          thumb-label
                                          :class="$vuetify.breakpoint.mdAndUp ? 'mx-2' : 'mr-1'"
                                          :color="'success' /*getColorByStatus(control['key']) */"
                                          :disabled="!controlOnOff[0] || !controlOnOff[index]"
                                        />
                                      </span>
                                      <span v-else>
                                        {{ getGillsStatusText(control) }}
                                      </span>
                                    </p>
                                  </v-col>
                                  <v-spacer v-if="$vuetify.breakpoint.lgAndUp" />
                                  <v-col cols="auto" class="pa-0 text-right d-flex justify-center align-center">
                                    <v-chip
                                      label
                                      :small="$vuetify.breakpoint.mdAndUp"
                                      :x-small="$vuetify.breakpoint.smAndDown"
                                      dark
                                      :color="getColorByStatus(control['key'])"
                                      v-if="!isControlMode"
                                    >
                                      {{ $t(getControlStatusText(control['key'])) }}
                                    </v-chip>
                                    <v-switch
                                      v-model="controlOnOff[index]"
                                      class="my-1"
                                      hide-details
                                      inset
                                      dense
                                      :color="'success' /*getColorByStatus(control['key'])*/"
                                      @change="setControlOnOff(control['name'], index, $event)"
                                      :disabled="
                                        (control['key'] !== 'OPERATING' && !controlOnOff[0]) ||
                                        getGillsStatusValue(control['key']) === 'abnormal'
                                      "
                                      v-else
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-sheet>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.mdAndUp && gillsDetail['GILLS_DEV_VER'] !== 'G2-REV2.0'">
        <v-col cols="8">
          <v-sheet outlined color="grey100" rounded="lg" class="box" height="100%">
            <v-card
              tag="section"
              rounded="lg"
              elevation="0"
              height="100%"
              :loading="isLoadingGillsHistoryList"
              :class="['pa-4 pa-md-10', { 'd-flex justify-center align-center': isLoadingGillsHistoryList }]"
            >
              <template slot="progress">
                <v-progress-circular indeterminate color="grey800"></v-progress-circular>
              </template>
              <v-container fill-height fluid class="pa-0" v-show="!isLoadingGillsHistoryList">
                <v-row class="align-self-start">
                  <v-col cols="12" md="auto">
                    <h3
                      :class="[
                        { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                        { 'font-size-16': $vuetify.breakpoint.smAndDown },
                      ]"
                    >
                      {{ $t('gillsHistory') }}
                    </h3>
                  </v-col>
                </v-row>
                <v-row class="align-self-baseline">
                  <v-col class="d-flex justify-center align-center">
                    <v-container fluid class="pa-0">
                      <v-row v-if="gillsHistoryListPage !== null && gillsHistoryListPage['totalCount'] > 0">
                        <v-col class="overflow-auto">
                          <v-responsive min-width="900">
                            <v-container
                              fluid
                              :class="[
                                'table',
                                { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                                { 'font-size-14': $vuetify.breakpoint.smAndDown },
                              ]"
                            >
                              <v-row
                                :class="['thead align-center text-center', { dense: $vuetify.breakpoint.smAndDown }]"
                              >
                                <v-col cols="3" class="font-weight-bold">{{ $t('date') }}</v-col>
                                <v-col cols="2" class="font-weight-bold">{{ $t('gillsHistoryPart') }}</v-col>
                                <v-col cols="2" class="font-weight-bold">{{ $t('gillsHistoryOld') }}</v-col>
                                <v-col cols="2" class="font-weight-bold">{{ $t('gillsHistoryNew') }}</v-col>
                                <v-col cols="3" class="font-weight-bold">{{ $t('operator') }}</v-col>
                              </v-row>
                              <v-row
                                :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]"
                                v-for="history in gillsHistoryList"
                                :key="history['HISTORY_ID']"
                              >
                                <v-col cols="3" class="text-center">
                                  {{ $day(history['GILLS_HISTORY_DT']).format('YYYY.MM.DD hh:mm:ss') }}
                                </v-col>
                                <v-col cols="2" class="text-center">{{ history['GILLS_HISTORY_PART'] }}</v-col>
                                <v-col cols="2" class="text-center">{{ history['GILLS_HISTORY_OLD'] }}</v-col>
                                <v-col cols="2" class="text-center">{{ history['GILLS_HISTORY_NEW'] }}</v-col>
                                <v-col cols="3" class="text-center">{{ history['USER_NAME'] }}</v-col>
                              </v-row>
                            </v-container>
                          </v-responsive>
                        </v-col>
                      </v-row>
                      <v-row v-if="gillsHistoryListPage !== null && gillsHistoryListPage['totalCount'] > 0">
                        <v-col>
                          <v-pagination
                            v-model="historyPage"
                            :length="gillsHistoryListPage['totalPage']"
                            @input="getGillsHistory"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col class="text-center grey400--text py-16">
                          <v-progress-circular indeterminate v-if="isLoadingGillsHistoryList" />
                          <span v-else>{{ $t('noData') }}</span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-sheet>
        </v-col>
        <v-col cols="4">
          <v-sheet outlined color="grey100" rounded="lg" class="box" height="100%">
            <v-card
              tag="section"
              rounded="lg"
              elevation="0"
              height="100%"
              :loading="isLoadingManagerList"
              :class="['pa-4 pa-md-10', { 'd-flex justify-center align-center': isLoadingManagerList }]"
            >
              <template slot="progress">
                <v-progress-circular indeterminate color="grey800"></v-progress-circular>
              </template>
              <v-container :fill-height="managerList.length === 0" fluid class="pa-0" v-show="!isLoadingManagerList">
                <v-row class="align-self-start">
                  <v-col cols="12" md="auto">
                    <h3
                      :class="[
                        { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                        { 'font-size-16': $vuetify.breakpoint.smAndDown },
                      ]"
                    >
                      {{ $t('manager') }}
                    </h3>
                  </v-col>
                </v-row>
                <v-row class="align-self-start ma-0 text-center" v-if="managerList.length === 0">
                  <v-col class="grey400--text">{{ $t('noData') }}</v-col>
                </v-row>
                <v-row v-for="manager in managerList" :key="manager['USER_ID']" v-show="managerList.length > 0">
                  <v-col class="d-flex justify-center align-center">
                    <v-sheet outlined color="grey100" rounded="lg" width="100%">
                      <v-card rounded="lg" elevation="0" class="pa-2">
                        <v-container fluid>
                          <v-row no-gutters>
                            <v-col cols="auto">
                              <h4
                                :class="[
                                  { 'font-size-16': $vuetify.breakpoint.lgAndUp },
                                  { 'font-size-14': $vuetify.breakpoint.mdAndDown },
                                ]"
                              >
                                {{ manager['USER_NAME'] }}
                              </h4>
                            </v-col>
                            <v-spacer />
                            <v-col cols="auto">
                              <h4
                                :class="[
                                  { 'font-size-16': $vuetify.breakpoint.lgAndUp },
                                  { 'font-size-14': $vuetify.breakpoint.mdAndDown },
                                ]"
                              >
                                {{ manager['USER_TEL'] }}
                              </h4>
                            </v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col
                              :class="[
                                { 'font-size-14': $vuetify.breakpoint.lgAndUp },
                                { 'font-size-12': $vuetify.breakpoint.mdAndDown },
                              ]"
                            >
                              {{ manager['USER_EMAIL'] }}
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row class="px-2" v-else-if="!$vuetify.breakpoint.mdAndUp && gillsDetail['GILLS_DEV_VER'] !== 'G2-REV2.0'">
        <v-col class="px-1">
          <v-card outlined elevation="0">
            <v-dialog persistent v-model="dialogGillsHistory" width="500" :fullscreen="$vuetify.breakpoint.smAndDown">
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-large elevation="0" block color="white" dark class="px-2 rounded-lg" v-bind="attrs" v-on="on">
                  <v-icon color="grey900">mdi-clipboard-text-clock-outline</v-icon>
                  <span class="ml-1 grey900--text">{{ $t('gillsHistory') }}</span>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="grey100">
                  {{ $t('gillsHistory') }}
                </v-card-title>

                <v-card-text class="pa-4 black--text">
                  <v-container fluid class="pa-0">
                    <v-row v-if="gillsHistoryListPage !== null && gillsHistoryListPage['totalCount'] > 0">
                      <v-col class="overflow-auto">
                        <v-responsive min-width="900">
                          <v-container
                            fluid
                            :class="[
                              'table',
                              { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                              { 'font-size-14': $vuetify.breakpoint.smAndDown },
                            ]"
                          >
                            <v-row
                              :class="['thead align-center text-center', { dense: $vuetify.breakpoint.smAndDown }]"
                            >
                              <v-col cols="3" class="font-weight-bold">{{ $t('date') }}</v-col>
                              <v-col cols="2" class="font-weight-bold">{{ $t('gillsHistoryPart') }}</v-col>
                              <v-col cols="2" class="font-weight-bold">{{ $t('gillsHistoryOld') }}</v-col>
                              <v-col cols="2" class="font-weight-bold">{{ $t('gillsHistoryNew') }}</v-col>
                              <v-col cols="3" class="font-weight-bold">{{ $t('operator') }}</v-col>
                            </v-row>
                            <v-row
                              :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]"
                              v-for="history in gillsHistoryList"
                              :key="history['HISTORY_ID']"
                            >
                              <v-col cols="3" class="text-center">
                                {{ $day(history['GILLS_HISTORY_DT']).format('YYYY.MM.DD hh:mm:ss') }}
                              </v-col>
                              <v-col cols="2" class="text-center">{{ history['GILLS_HISTORY_PART'] }}</v-col>
                              <v-col cols="2" class="text-center">{{ history['GILLS_HISTORY_OLD'] }}</v-col>
                              <v-col cols="2" class="text-center">{{ history['GILLS_HISTORY_NEW'] }}</v-col>
                              <v-col cols="3" class="text-center">{{ history['USER_NAME'] }}</v-col>
                            </v-row>
                          </v-container>
                        </v-responsive>
                      </v-col>
                    </v-row>
                    <v-row v-if="gillsHistoryListPage !== null && gillsHistoryListPage['totalCount'] > 0">
                      <v-col>
                        <v-pagination
                          v-model="historyPage"
                          :length="gillsHistoryListPage['totalPage']"
                          @input="getGillsHistory"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col class="text-center grey400--text py-16">
                        <v-progress-circular indeterminate v-if="isLoadingGillsHistoryList" />
                        <span v-else>{{ $t('noData') }}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn class="rounded-lg" color="grey600" outlined @click="dialogGillsHistory = false">
                    {{ $t('close') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col class="px-1">
          <v-card outlined elevation="0">
            <v-dialog persistent v-model="dialogManager" width="500" :fullscreen="$vuetify.breakpoint.smAndDown">
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-large elevation="0" block color="white" dark class="px-2 rounded-lg" v-bind="attrs" v-on="on">
                  <v-icon color="grey900">mdi-shield-account-outline</v-icon>
                  <span class="ml-1 grey900--text">{{ $t('manager') }}</span>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="grey100">
                  {{ $t('manager') }}
                </v-card-title>

                <v-card-text class="pa-4">
                  <v-container fill-height fluid class="pa-0" v-show="!isLoadingManagerList">
                    <v-row class="align-self-start ma-0 text-center" v-if="managerList.length === 0">
                      <v-col class="grey400--text">{{ $t('noData') }}</v-col>
                    </v-row>
                    <v-row v-for="manager in managerList" :key="manager['USER_ID']" v-show="managerList.length > 0">
                      <v-col class="d-flex justify-center align-center">
                        <v-sheet outlined color="grey100" rounded="lg" width="100%">
                          <v-card rounded="lg" elevation="0" class="pa-2">
                            <v-container fluid>
                              <v-row no-gutters>
                                <v-col cols="auto">
                                  <h4
                                    :class="[
                                      { 'font-size-16': $vuetify.breakpoint.lgAndUp },
                                      { 'font-size-14': $vuetify.breakpoint.mdAndDown },
                                    ]"
                                  >
                                    {{ manager['USER_NAME'] }}
                                  </h4>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                  <h4
                                    :class="[
                                      { 'font-size-16': $vuetify.breakpoint.lgAndUp },
                                      { 'font-size-14': $vuetify.breakpoint.mdAndDown },
                                    ]"
                                  >
                                    {{ manager['USER_TEL'] }}
                                  </h4>
                                </v-col>
                              </v-row>
                              <v-row no-gutters>
                                <v-col
                                  :class="[
                                    { 'font-size-14': $vuetify.breakpoint.lgAndUp },
                                    { 'font-size-12': $vuetify.breakpoint.mdAndDown },
                                  ]"
                                >
                                  {{ manager['USER_EMAIL'] }}
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn class="rounded-lg" color="grey600" outlined @click="dialogManager = false">
                    {{ $t('close') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="gillsDetail['GILLS_DEV_VER'] === 'G2-REV2.0' && gsenUdid !== ''">
        <v-col cols="12" md="4" lg="3" :class="[{ 'py-1 px-0': $vuetify.breakpoint.smAndDown }]">
          <v-container class="pa-0 align-content-start" fluid fill-height v-if="!isLoadingGsenDetail">
            <v-row :class="[{ 'pt-1 px-3': $vuetify.breakpoint.smAndDown }]">
              <v-col>
                <v-sheet outlined color="grey100" rounded="lg" class="box">
                  <v-card tag="section" rounded="lg" elevation="0">
                    <v-container fill-height class="pa-2 pa-md-8">
                      <v-row no-gutters :class="[{ 'flex-nowrap overflow-x-auto': $vuetify.breakpoint.smAndDown }]">
                        <v-col
                          v-for="item in itemList"
                          :key="item"
                          :cols="$vuetify.breakpoint.mdAndUp ? 6 : 'auto'"
                          class="pa-2"
                        >
                          <v-sheet
                            rounded="lg"
                            :class="[
                              'd-flex justify-center align-center mx-auto measure-item' +
                                (gillsDetail['GILLS_DEV_VER'].indexOf('G2') > -1 ? ' g2' : ''),
                            ]"
                            :width="$vuetify.breakpoint.mdAndUp ? '100%' : 100"
                            :height="$vuetify.breakpoint.mdAndUp ? 122 : 68"
                            :color="
                              activeItemList.indexOf(item) > -1
                                ? getGsenStatus(gsenDetail['GSEN_DATA_' + item + '_STATUS'])
                                : null
                            "
                            :dark="activeItemList.indexOf(item) > -1"
                            :outlined="activeItemList.indexOf(item) === -1"
                            @click="setActiveItem(item)"
                          >
                            <span
                              :class="[
                                'text-center font-weight-bold' +
                                  (activeItemList.indexOf(item) === -1
                                    ? ' ' + getGsenStatus(gsenDetail['GSEN_DATA_' + item + '_STATUS']) + '--text'
                                    : '') +
                                  (gillsDetail['GILLS_DEV_VER'].indexOf('G2') > -1 ? ' g2' : ''),
                                { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                                { 'font-size-16': $vuetify.breakpoint.smAndDown },
                              ]"
                            >
                              {{
                                item.indexOf('PM') > -1 || item === 'TEMP'
                                  ? Number(gsenDetail[`GSEN_DATA_${item}`]).toFixed(1)
                                  : Number(gsenDetail[`GSEN_DATA_${item}`]).toFixed(0)
                              }}
                              <span class="font-size-12 font-weight-regular">
                                {{ $store.getters.standardInfo[item]['STD_UNIT'] }}
                              </span>
                              <br />
                              <span
                                :class="[
                                  'font-size-16 font-weight-regular',
                                  { 'grey900--text': activeItemList.indexOf(item) === -1 },
                                ]"
                              >
                                {{ $t(item) }}
                              </span>
                            </span>
                          </v-sheet>
                        </v-col>
                        <v-responsive class="pa-2" v-if="!$vuetify.breakpoint.smAndDown"></v-responsive>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col>
          <v-container class="pa-0 align-content-start" fluid fill-height>
            <v-row :class="[{ 'pt-1 px-3': $vuetify.breakpoint.smAndDown }]">
              <v-col cols="12" :class="[{ 'py-1 px-0': $vuetify.breakpoint.smAndDown }]">
                <v-sheet outlined color="grey100" rounded="lg" class="box">
                  <v-card tag="section" rounded="lg" elevation="0">
                    <v-container fill-height class="pa-2 pa-md-8">
                      <v-row no-gutters class="d-flex align-center">
                        <search-schedule-box
                          @setSearchDtFrom="setSearchDtFrom"
                          @setSearchDtTo="setSearchDtTo"
                          class="col-12 col-lg-8"
                        />
                        <!-- <v-col md="4">
                          <v-row no-gutters>
                            <v-col>
                              <v-row class="py-1 px-2">
                                <v-col class="px-1">
                                  <v-btn
                                    class="rounded-lg"
                                    block
                                    :x-large="$vuetify.breakpoint.mdAndUp"
                                    :height="$vuetify.breakpoint.mdAndUp ? 56 : 40"
                                    color="grey600"
                                    dark
                                    elevation="0"
                                    :outlined="chartInterval !== 15"
                                    @click="setChartInterval(15)"
                                  >
                                    {{ $t('all') }}
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col> -->
                      </v-row>
                    </v-container>
                  </v-card>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row :class="[{ 'pt-1 px-3': $vuetify.breakpoint.smAndDown }]">
              <v-col cols="12" :class="[{ 'py-1 px-0': $vuetify.breakpoint.smAndDown }]">
                <v-sheet outlined color="grey100" rounded="lg" class="box" height="100%">
                  <v-card tag="section" rounded="lg" elevation="0" height="100%">
                    <v-container
                      fill-height
                      class="py-12 grey200--text d-flex justify-center align-center"
                      v-if="activeItemList.length === 0"
                    >
                      <span>{{ $t('msgSelectItem') }}</span>
                    </v-container>
                    <v-container fill-height class="pa-2 pa-md-8 d-block" v-else>
                      <v-row v-for="item in activeItemList" :key="item">
                        <v-col cols="12">
                          <v-card outlined rounded="lg" class="pa-2 pa-md-8">
                            <v-container>
                              <v-row>
                                <v-col cols="auto">
                                  <h3
                                    :class="[
                                      { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                                      { 'font-size-16': $vuetify.breakpoint.smAndDown },
                                    ]"
                                  >
                                    {{ $t(item) }}
                                  </h3>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                  <v-btn
                                    class="rounded-lg"
                                    icon
                                    @click="activeItemList.splice(activeItemList.indexOf(item), 1)"
                                  >
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <gsen-data
                                :gsenUdid="gsenUdid"
                                :item="item"
                                :dataDtFrom="dataDtFrom"
                                :dataDtTo="dataDtTo"
                                :ref="'gsenData-' + item"
                                :color="false"
                              />
                            </v-container>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row v-if="gillsDetail['GSEN'].filter((gsen) => gsen['IS_EXT'] === 1).length > 0">
        <v-col>
          <v-sheet outlined color="grey100" rounded="lg" class="box" height="100%">
            <v-card tag="section" rounded="lg" elevation="0" height="100%" class="pa-4 pa-md-10">
              <v-container fill-height fluid class="pa-0">
                <v-row class="align-self-start">
                  <v-col cols="12" md="auto">
                    <h3
                      :class="[
                        { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                        { 'font-size-16': $vuetify.breakpoint.smAndDown },
                      ]"
                    >
                      {{ $t('gillsGsenStatus') }}
                    </h3>
                  </v-col>
                  <!-- <v-spacer />
                  <v-col cols="12" md="auto" class="d-flex align-end justify-end">
                    <span
                      :class="[
                        'grey600--text',
                        { 'font-size-14': $vuetify.breakpoint.mdAndUp },
                        { 'font-size-12': $vuetify.breakpoint.smAndDown },
                        { 'mt-2 mb-1': $vuetify.breakpoint.smAndDown },
                      ]"
                    >
                      {{ lastUpdatedTime ? $t('lastUpdate') + lastUpdatedTime : '' }}
                    </span>
                  </v-col> -->
                </v-row>
                <v-row class="align-self-baseline">
                  <v-col
                    :class="[
                      'd-flex align-center pa-0',
                      gillsDetail['GSEN'].filter((gsen) => gsen['IS_EXT'] === 1).length > 0
                        ? 'justify-start'
                        : 'py-16 grey400--text justify-center',
                    ]"
                  >
                    <v-container fluid v-if="gillsDetail['GSEN'].filter((gsen) => gsen['IS_EXT'] === 1).length > 0">
                      <v-row class="overflow-auto flex-nowrap">
                        <v-col
                          v-for="gsen in gillsDetail['GSEN'].filter((gsen) => gsen['IS_EXT'] === 1)"
                          :key="gsen['GSEN_UDID']"
                          cols="auto"
                        >
                          <v-sheet
                            outlined
                            color="grey100"
                            rounded="lg"
                            :width="$vuetify.breakpoint.mdAndUp ? '320' : '290'"
                          >
                            <v-card rounded="lg" elevation="0">
                              <v-container :class="$vuetify.breakpoint.mdAndUp ? 'pa-6' : 'pa-4'">
                                <v-row>
                                  <v-col cols="auto">
                                    <h4>{{ gsen['GSEN_NAME'] }}</h4>
                                  </v-col>
                                  <v-spacer />
                                  <v-col
                                    :class="[
                                      'd-flex align-center',
                                      { 'font-size-12': $vuetify.breakpoint.mdAndUp },
                                      { 'font-size-10': $vuetify.breakpoint.smAndDown },
                                    ]"
                                    cols="auto"
                                  >
                                    <router-link
                                      :to="{ name: 'GsenDetail', params: { gsenUdid: gsen['GSEN_UDID'] } }"
                                      class="grey200--text"
                                    >
                                      {{ $t('viewDetail') }}
                                    </router-link>
                                  </v-col>
                                </v-row>
                                <v-row
                                  v-for="(item, index) in itemListRelatedGsen"
                                  :key="item"
                                  :class="[
                                    { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                                    { 'font-size-14': $vuetify.breakpoint.smAndDown },
                                  ]"
                                  :style="
                                    index > 0 ? 'border-top: 1px solid ' + $vuetify.theme.themes.light['grey100'] : ''
                                  "
                                >
                                  <v-col cols="auto" class="py-2 py-md-3">{{ $t(`${item}_FULL`) }}</v-col>
                                  <v-spacer />
                                  <v-col cols="auto" class="py-2 py-md-3">
                                    <span
                                      :class="['state-bullet', getGsenStatus(gsen[`GSEN_DATA_${item}_STATUS`])]"
                                      v-if="gsen[`GSEN_DATA_${item}_STATUS`] !== null"
                                    ></span>
                                    <span
                                      :class="[
                                        { 'font-weight-bold': item === 'TOTAL' },
                                        getGsenStatus(gsen[`GSEN_DATA_${item}_STATUS`]) + '--text',
                                      ]"
                                    >
                                      {{
                                        gsen[`GSEN_DATA_${item}_STATUS`] === null
                                          ? '-'
                                          : $t(getGsenStatusWord(gsen[`GSEN_DATA_${item}_STATUS`]))
                                      }}
                                    </span>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-sheet>
                        </v-col>
                      </v-row>
                    </v-container>
                    <span class="grey400--text" v-else>{{ $t('noData') }}</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
import Layout from '@/layouts/Dashboard'
import {
  getGillsDetail,
  getGillsHistory,
  getGillsManager,
  setGillsInfo,
  getAreaA,
  getAreaB,
  getAreaC,
  setGillsArea,
  getGillsUser,
  setGillsManager,
  getGillsGsen,
  setGillsGsen,
  getGillsOpTime,
  setGillsOpTime,
  setGillsControl,
  getGsenDetail,
} from '@/services/api'
import { mapGetters } from 'vuex'
import { getGsenStatus, getGsenStatusWord } from '@/services/common'
import SearchBox from '@/components/SearchBox.vue'
import SearchScheduleBox from '@/components/SearchScheduleBox.vue'
import GsenData from '@/components/GsenData.vue'

export default {
  name: 'GillsDetail',
  components: {
    Layout,
    SearchBox,
    SearchScheduleBox,
    GsenData,
  },
  async created() {
    await this.getGillsDetail()
    this.getGillsManager()

    if (this.gillsDetail['GILLS_DEV_VER'] !== 'G2-REV2.0') {
      this.getGillsHistory()
    } else {
      if (this.gsenUdid !== '') this.getGsenDetail()
    }

    this.updateInterval = setInterval(() => {
      this.getGillsDetail()
      this.getGillsManager()

      if (this.gillsDetail['GILLS_DEV_VER'] !== 'G2-REV2.0') {
        this.getGillsHistory(true)
      } else {
        if (this.gsenUdid !== '') this.getGsenDetail(true)
      }
    }, this.updateIntervalTime)
  },
  beforeDestroy() {
    clearInterval(this.updateInterval)
  },
  data: () => ({
    updateInterval: null,
    updateIntervalTime: 10000,
    lastUpdatedTime: '',
    gillsDetail: null,
    isLoadingGillsDetail: true,
    dataDtFrom: '',
    dataDtTo: '',
    itemListRelatedGsen: ['PM1P0', 'PM2P5', 'PM10P0', 'TVOC', 'CO2', 'CO', 'HCHO', 'RADON', 'TEMP', 'HUMI', 'SOUND'],
    controlListG1: [
      { key: 'OPERATING', name: 'POWER' },
      { key: 'RELAY2', name: 'MOTOR1' },
      { key: 'RELAY3', name: 'MOTOR2' },
      { key: 'RELAY1', name: 'PUMP' },
    ],
    controlListG2: [
      { key: 'OPERATING', name: 'POWER' },
      { key: 'MOTOR_SPEED', name: 'AIR_CLEANING' },
      { key: 'AUTO_CLEAN', name: 'AUTO_CLEAN' },
    ],
    isControlMode: false,
    dialogGillsInfo: false,
    editedGillsInfo: null,
    historyPage: 1,
    historyItemPerPage: 5,
    gillsHistoryListPage: null,
    gillsHistoryList: [],
    managerList: [],
    isLoadingGillsHistoryList: true,
    isLoadingManagerList: false,
    dialogGillsHistory: false,
    dialogManager: false,
    controlOnOff: [],
    controlValue: [],
    isSavingControl: false,
    isSavingGillsInfo: false,
    areaAList: [],
    areaBList: [],
    areaCList: [],
    areaAId: '',
    areaBId: '',
    areaCId: '',
    isLoadingAreaA: true,
    isLoadingAreaB: true,
    isLoadingAreaC: true,
    dialogGillsArea: false,
    isSavingGillsArea: false,
    userPage: 1,
    userItemPerPage: 5,
    gillsUserList: null,
    gillsUserListPage: null,
    isLoadingGillsUser: false,
    dialogGillsUser: false,
    isSavingGillsManager: false,
    editedGillsManager: null,
    gsenPage: 1,
    gsenItemPerPage: 5,
    gillsGsenList: null,
    gillsGsenListPage: null,
    isLoadingGillsGsen: false,
    dialogGillsGsen: false,
    isSavingGillsGsen: false,
    editedGsenList: null,
    isLoadingGillsOpTime: false,
    gillsOpTime: null,
    editedGillsOpTime: null,
    dialogGillsOpTime: false,
    isSavingGillsOpTime: false,
    dayOfWeek: null,
    startHour: '00',
    startMin: '00',
    endHour: '00',
    endMin: '00',
    opTimeErrorMsg: '',
    gsenDetail: null,
    isLoadingGsenDetail: true,
    isLoadingGsenData: true,
    chartInterval: 15,
    itemList: ['PM2P5', 'PM10P0', 'TVOC', 'CO2', 'TEMP', 'HUMI'],
    activeItemList: ['PM2P5'],
    dialogGillsInstDate: false,
  }),
  computed: {
    ...mapGetters(['userInfo']),
    gillsUdid() {
      return this.$route.params['gillsUdid']
    },
    gsenUdid() {
      let gsenUdid = ''

      if (this.gillsDetail && this.gillsDetail['GSEN'].length > 0)
        gsenUdid = this.gillsDetail['GSEN'].filter((gsen) => gsen['IS_EXT'] === 0)[0]['GSEN_UDID']

      return gsenUdid
    },
    hourList() {
      return [...Array(24).keys()].map((hour) => hour.toString().padStart(2, '0'))
    },
    minList() {
      // 30분 단위
      // return [...Array(2).keys()].map((min) => (min * 30).toString().padStart(2, '0'))
      // 10분 단위
      return [...Array(6).keys()].map((min) => (min * 10).toString().padStart(2, '0'))
    },
  },
  watch: {
    dialogGillsInfo() {
      if (this.dialogGillsInfo)
        this.editedGillsInfo = {
          GILLS_NAME: this.gillsDetail['GILLS_NAME'],
          GILLS_INST_DATE: this.gillsDetail['GILLS_INST_DATE'],
        }
    },
    dialogGillsArea() {
      if (this.dialogGillsArea) this.setCurrentLocation()
    },
    areaAId(after, before) {
      if (after !== before) {
        this.areaBId = ''
        this.areaCId = ''
        this.areaBList = []
        this.areaCList = []

        this.getAreaB()
      }
    },
    areaBId(after, before) {
      if (after !== before) {
        this.areaCId = ''
        this.areaCList = []

        this.getAreaC()
      }
    },
    dialogGillsUser() {
      if (this.dialogGillsUser) {
        this.editedGillsManager = this.managerList.map((manager) => manager['USER_ID'])
        this.getGillsUser()
      }
    },
    dialogGillsGsen() {
      if (this.dialogGillsGsen) {
        this.editedGsenList = this.gillsDetail['GSEN'].map((gsen) => gsen['GSEN_UDID'])
        this.getGillsGsen()
      }
    },
    async dialogGillsOpTime() {
      if (this.dialogGillsOpTime) {
        await this.getGillsOpTime()
        this.editedGillsOpTime = this.gillsOpTime.slice()
        this.resetSelectedGillsOpTime()
        this.checkOpTimeValidation()

        if (this.gillsDetail['GILLS_DEV_VER'] === 'G2-REV2') {
          this.startHour = this.editedGillsOpTime[0]['START_HOUR'].toString().padStart(2, '0')
          this.startMin = this.editedGillsOpTime[0]['START_MIN'].toString().padStart(2, '0')
          this.endHour = this.editedGillsOpTime[0]['END_HOUR'].toString().padStart(2, '0')
          this.endMin = this.editedGillsOpTime[0]['END_MIN'].toString().padStart(2, '0')
        }
      } else {
        this.editedGillsOpTime = null
      }
    },
    dayOfWeek() {
      this.checkOpTimeValidation()
    },
    startHour() {
      this.checkOpTimeValidation()
    },
    startMin() {
      this.checkOpTimeValidation()
    },
    endHour() {
      this.checkOpTimeValidation()
    },
    endMin() {
      this.checkOpTimeValidation()
    },
  },
  methods: {
    getGsenStatus(status) {
      return getGsenStatus(status)
    },
    getGsenStatusWord(status) {
      return getGsenStatusWord(status)
    },
    getColorByStatus(key) {
      let value = this.getControlStatusText(key)
      return value !== 'abnormal' && value !== 'off' && value !== 'stop' && value !== 'standby' ? 'working' : value
    },
    getControlStatusText(key) {
      let value = ''
      let target

      if (key.indexOf('RELAY') > -1) target = this.gillsDetail['RELAY'][key]
      else target = this.gillsDetail[key]

      if ((target ?? -1) === -1) value = 'abnormal'
      else {
        if (key === 'AUTO_CLEAN') {
          if (target === 5) value = 'off'
          else {
            if (this.gillsDetail['IS_AUTO_CLEANING']) value = 'cleaning'
            else value = 'standby'
          }
        } else {
          if (target === 0) {
            if (key === 'MOTOR_SPEED') value = 'stop'
            else value = 'off'
          } else {
            if (key === 'MOTOR_SPEED') {
              if (this.gillsDetail['IS_USING_AUTO_RUN'] && !this.gillsDetail['IS_AUTO_RUNNING']) value = 'standby'
              else value = 'working'
            } else value = 'working'
          }
        }
      }

      return value
    },
    getGillsStatusValue(key) {
      let value = ''
      let target

      if (key.indexOf('RELAY') > -1) target = this.gillsDetail['RELAY'][key]
      else target = this.gillsDetail[key]

      if ((target ?? -1) === -1) value = 'abnormal'
      else {
        if (key === 'AUTO_CLEAN') {
          if (target === 0) value = 'manual'
          else if (target === 1) value = 'every-week'
          else if (target === 2) value = 'every-day'
          else if (target === 3) value = 'twice-a-week'
          else if (target === 4) value = 'need-to-check'
          else if (target === 5) value = 'off'
          else value = 'abnormal'
        } else {
          if (target === 0) value = 'off'
          else {
            if (key === 'MOTOR_SPEED') {
              if (target === 5) value = 'auto'
              else value = `grade${target}`
            } else value = 'on'
          }
        }
      }

      return value
    },
    getGillsStatusText(control) {
      let text = ''

      if (
        control['name'].indexOf('MOTOR') > -1 &&
        this.gillsDetail['RELAY'][control['key']] !== null &&
        this.gillsDetail['RELAY'][control['key']] > -1
      )
        text = this.$t('speed') + ': ' + this.gillsDetail['RELAY'][control['key']]
      else {
        if (
          control['key'] === 'MOTER_SPEED' &&
          this.gillsDetail[control['key']] > 0 &&
          this.gillsDetail[control['key']] < 5
        )
          text = this.$t('speed') + ': '

        text += this.$t(this.getGillsStatusValue(control['key']))
      }

      return text
    },
    async getGillsDetail() {
      try {
        let result = await getGillsDetail(this, this.gillsUdid)
        if (result.data.length === 0) {
          alert(this.$t('noData'))
          this.$router.replace({ name: 'Gills' })
        }
        this.gillsDetail = result.data
        this.lastUpdatedTime = this.gillsDetail['LAST_COMM']
          ? this.$day(this.gillsDetail['LAST_COMM']).format('YYYY.MM.DD hh:mm:ss')
          : ''
        this.controlOnOff = this.controlListG1.map((control) => {
          let value = false
          if (control['key'].indexOf('RELAY') > -1)
            value = Boolean(
              this.gillsDetail['RELAY'][control['key']] === -1 ? 0 : this.gillsDetail['RELAY'][control['key']]
            )
          else value = Boolean(this.gillsDetail[control['key']] === -1 ? 0 : this.gillsDetail[control['key']])

          return value ?? -1
        })
        this.controlValue = this.controlListG1.map((control) => {
          let value = 0
          if (control['key'].indexOf('RELAY') > -1) value = this.gillsDetail['RELAY'][control['key']]
          else value = this.gillsDetail[control['key']]

          return value ?? -1
        })
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        if (this.isLoadingGillsDetail) this.isLoadingGillsDetail = false
      }
    },
    async getGillsHistory(update = false) {
      if (!update) this.isLoadingGillsHistoryList = true
      try {
        let result = await getGillsHistory(this, this.gillsUdid, this.historyPage, this.historyItemPerPage)
        this.gillsHistoryList = result.data['DATA']
        this.gillsHistoryListPage = result.data['PAGE']
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingGillsHistoryList = false
      }
    },
    async getGillsManager() {
      this.isLoadingManagerList = true
      try {
        let result = await getGillsManager(this, this.gillsUdid)
        this.managerList = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingManagerList = false
      }
    },
    async setGillsInfo() {
      this.isSavingGillsInfo = true
      try {
        let result = await setGillsInfo(this, this.gillsUdid, this.editedGillsInfo)
        if (result.data) {
          await this.getGillsDetail()
          this.dialogGillsInfo = false
        }
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(this.$t('errorMsg'))
      } finally {
        this.isSavingGillsInfo = false
      }
    },
    async setCurrentLocation() {
      await this.getAreaA()
      this.areaAId = this.gillsDetail['AREA_A']['AREA_A_ID']
      await this.getAreaB()
      this.areaBId = this.gillsDetail['AREA_B']['AREA_B_ID']
      await this.getAreaC()
      this.areaCId = this.gillsDetail['AREA_C']['AREA_C_ID']
    },
    async getAreaA() {
      this.isLoadingAreaA = true
      try {
        let result = await getAreaA(this)
        this.areaAList = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingAreaA = false
      }
    },
    async getAreaB() {
      this.isLoadingAreaB = true
      try {
        let result = await getAreaB(this, this.areaAId)
        this.areaBList = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingAreaB = false
      }
    },
    async getAreaC() {
      this.isLoadingAreaC = true
      try {
        let result = await getAreaC(this, this.areaBId)
        this.areaCList = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingAreaC = false
      }
    },
    setAreaAId(areaAId) {
      this.areaAId = areaAId
    },
    setAreaBId(areaBId) {
      this.areaBId = areaBId
    },
    setAreaCId(areaCId) {
      this.areaCId = areaCId
    },
    async setGillsArea() {
      this.isSavingGillsArea = true
      try {
        let result = await setGillsArea(this, this.gillsUdid, this.areaAId, this.areaBId, this.areaCId)
        if (result.data) {
          await this.getGillsDetail()
          this.dialogGillsArea = false
        }
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(this.$t('errorMsg'))
      } finally {
        this.isSavingGillsArea = false
      }
    },
    async getGillsUser() {
      this.isLoadingGillsUser = true
      try {
        let result = await getGillsUser(this, this.gillsUdid, this.userPage, this.userItemPerPage)
        this.gillsUserList = result.data['DATA']
        this.gillsUserListPage = result.data['PAGE']
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingGillsUser = false
      }
    },
    checkGillsManager(value, userId) {
      let index = this.editedGillsManager.indexOf(userId)

      if (!value) {
        if (index > -1) this.editedGillsManager.splice(index, 1)
      } else {
        if (index === -1) this.editedGillsManager.push(userId)
      }
    },
    async setGillsManager() {
      this.isSavingGillsManager = true
      try {
        let result = await setGillsManager(this, this.gillsUdid, this.editedGillsManager)
        if (result.data) {
          await this.getGillsManager()
          this.dialogGillsUser = false
        }
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(this.$t('errorMsg'))
      } finally {
        this.isSavingGillsManager = false
      }
    },
    async getGillsGsen() {
      this.isLoadingGillsGsen = true
      try {
        let result = await getGillsGsen(
          this,
          this.gillsUdid,
          this.gsenPage,
          this.gsenItemPerPage,
          this.areaAId,
          this.areaBId,
          this.areaCId
        )
        this.gillsGsenList = result.data['DATA']
        this.gillsGsenListPage = result.data['PAGE']
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingGillsGsen = false
      }
    },
    async getGillsOpTime() {
      this.isLoadingGillsOpTime = true
      try {
        let result = await getGillsOpTime(this, this.gillsUdid)
        this.gillsOpTime = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingGillsOpTime = false
      }
    },
    checkOpTimeValidation() {
      let timeNumList = this.gillsOpTime.map((optime) => ({
        start: Number(
          optime['DAY_OF_WEEK'] +
            optime['START_HOUR'].toString().padStart(2, '0') +
            optime['START_MIN'].toString().padStart(2, '0')
        ),
        end: Number(
          optime['DAY_OF_WEEK'] +
            optime['END_HOUR'].toString().padStart(2, '0') +
            optime['END_MIN'].toString().padStart(2, '0')
        ),
      }))

      if (this.dayOfWeek) {
        let selectedTimeNum = {
          start: Number(this.dayOfWeek + this.startHour + this.startMin),
          end: Number(this.dayOfWeek + this.endHour + this.endMin),
        }

        if (
          this.startHour + this.startMin === this.endHour + this.endMin ||
          Number(this.startHour + this.startMin) > Number(this.endHour + this.endMin) ||
          timeNumList.map((time) => this.checkTimeOverlap(time, selectedTimeNum)).indexOf(true) > -1
        )
          this.opTimeErrorMsg = this.$t('msgWrongOpTime')
        else this.opTimeErrorMsg = ''
      } else this.opTimeErrorMsg = this.$t('msgSelectOpTimeDay')
    },
    checkTimeOverlap(A, B) {
      return B.start < A.start ? B.end > A.start : B.start < A.end
    },
    addGillsOpTime() {
      this.editedGillsOpTime.push({
        GILLS_UDID: this.gillsUdid,
        DAY_OF_WEEK: this.dayOfWeek,
        START_HOUR: Number(this.startHour),
        START_MIN: Number(this.startMin),
        END_HOUR: Number(this.endHour),
        END_MIN: Number(this.endMin),
      })
      this.resetSelectedGillsOpTime()
    },
    resetSelectedGillsOpTime() {
      this.dayOfWeek = null
      this.startHour = '00'
      this.startMin = '00'
      this.endHour = '00'
      this.endMin = '00'
    },
    async setGillsOpTime() {
      this.isLoadingGillsOpTime = true
      try {
        let result = await setGillsOpTime(this, this.gillsUdid, this.editedGillsOpTime)
        if (result.data) {
          await this.getGillsOpTime()
          this.dialogGillsOpTime = false
        }
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingGillsOpTime = false
      }
    },
    checkGillsGsen(value, udid) {
      let index = this.editedGsenList.indexOf(udid)

      if (!value) {
        if (index > -1) this.editedGsenList.splice(index, 1)
      } else {
        if (index === -1) this.editedGsenList.push(udid)
      }
    },
    async setGillsGsen() {
      this.isSavingGillsGsen = true
      try {
        let result = await setGillsGsen(this, this.gillsUdid, this.editedGsenList)
        if (result.data) {
          await this.getGillsDetail()
          this.dialogGillsGsen = false
        }
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(this.$t('errorMsg'))
      } finally {
        this.isSavingGillsGsen = false
      }
    },
    setControlOnOff(name, index, value) {
      this.controlValue[index] = value ? (name.indexOf('MOTOR') > -1 ? 50 : 1) : 0
    },
    async saveControl() {
      this.isSavingControl = true
      try {
        let params = {}
        await this.controlListG1.map((control, index) => {
          params[control['key']] = this.controlValue[index]
        })
        let result = await setGillsControl(this, this.gillsUdid, params)
        if (result.data) {
          await this.getGillsDetail()
          await this.getGillsHistory()
          this.isControlMode = !this.isControlMode
        }
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(this.$t('errorMsg'))
      } finally {
        this.isSavingControl = false
      }
    },
    async getGsenDetail(update = false) {
      if (!update) this.isLoadingGsenDetail = true
      try {
        let result = await getGsenDetail(this, this.gsenUdid)
        if (result.data.length === 0) {
          // alert(this.$t('noData'))
          // this.$router.replace({ name: 'Gsen' })
          return
        }
        this.gsenDetail = result.data[0]
        this.lastUpdatedTime = this.gsenDetail['GSEN_DATA_DT']
          ? this.$day(this.gsenDetail['GSEN_DATA_DT']).format('YYYY.MM.DD hh:mm:ss')
          : ''
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingGsenDetail = false
      }
    },
    async setSearchDtFrom(searchDateFrom) {
      this.dataDtFrom = searchDateFrom
      if (this.dataDtFrom !== '' && this.dataDtTo !== '') {
        let tempActiveItemList = this.activeItemList.splice(0)
        this.activeItemList = this.activeItemList.splice()
        setTimeout(async () => {
          this.activeItemList = this.activeItemList.concat(tempActiveItemList)
        }, 100)
        // await this.requestSearch()
      }
    },
    async setSearchDtTo(searchDateTo) {
      this.dataDtTo = searchDateTo
      if (this.dataDtFrom !== '' && this.dataDtTo !== '') {
        let tempActiveItemList = this.activeItemList.splice(0)
        this.activeItemList = this.activeItemList.splice()
        setTimeout(async () => {
          this.activeItemList = this.activeItemList.concat(tempActiveItemList)
        }, 100)
        // await this.requestSearch()
      }
    },
    setActiveItem(item) {
      let index = this.activeItemList.indexOf(item)
      this.activeItemList = []
      this.activeItemList.push(item)
      // if (index > -1) this.activeItemList.splice(index, 1)
      // else this.activeItemList.push(item)
      return index
    },
    convertOpTime(type) {
      // if (this.editedGillsOpTime !== null && this.editedGillsOpTime.length > 0) return ''

      type = type.toUpperCase()
      let meridiem = 'am'
      let hour = ''
      let min = ''

      min = this.editedGillsOpTime[0][type + '_MIN']

      if (this.editedGillsOpTime[0][type + '_HOUR'] > 11) {
        meridiem = 'pm'
        hour = this.editedGillsOpTime[0][type + '_HOUR'] - 12
      } else hour = this.editedGillsOpTime[0][type + '_HOUR']

      if (hour === 0) hour = 12

      return this.$t(meridiem) + ' ' + hour.toString().padStart(2, '0') + ':' + min.toString().padStart(2, '0')
    },
  },
}
</script>

<style scoped>
* >>> .v-input {
  align-items: center !important;
}
* >>> .v-input--selection-controls__input {
  margin: 0 !important;
}

* >>> .v-input--selection-controls__ripple:before {
  display: none !important;
}

* >>> .v-slider__thumb-label {
  min-width: 28px;
  min-height: 28px;
}

* >>> .v-slider--horizontal {
  min-height: auto !important;
}

* >>> .v-slider__thumb:before,
* >>> .v-slider__thumb:after {
  display: none !important;
}

::v-deep.v-select .v-select__selections input {
  display: none;
}
</style>
