<template>
  <Layout v-if="isCanSignUp">
    <v-form slot="content" ref="signUpForm" class="text-center white" @submit.prevent="setSignUp" lazy-validation>
      <h2 class="mb-4 pb-2">{{ $t('signUp') }}</h2>
      <v-container class="px-0">
        <v-row>
          <v-col class="text-left pb-0">
            <label for="email">{{ $t('email') }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-text-field
              dense
              ref="email"
              id="email"
              validate-on-blur
              hide-details="auto"
              :rules="[rules.required(email, 'email'), rules.email]"
              v-model="email"
              maxlength="50"
              readonly
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-left pb-0">
            <label for="password">{{ $t('password') }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0">
            <v-text-field
              type="password"
              dense
              ref="password"
              id="password"
              validate-on-blur
              hide-details="auto"
              :rules="[rules.required(password, 'password'), rules.min]"
              v-model="password"
              maxlength="20"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-left pb-0">
            <label for="passwordRepeat">{{ $t('passwordRepeat') }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0">
            <v-text-field
              type="password"
              dense
              ref="passwordRepeat"
              id="passwordRepeat"
              validate-on-blur
              hide-details="auto"
              :rules="[
                rules.required(passwordRepeat, 'passwordRepeat'),
                rules.repeatPw(password, passwordRepeat),
                rules.min,
              ]"
              v-model="passwordRepeat"
              maxlength="20"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-left pb-0">
            <label for="name">{{ $t('name') }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-text-field
              dense
              ref="name"
              id="name"
              validate-on-blur
              hide-details="auto"
              :rules="[rules.required(name, 'name')]"
              v-model="name"
              maxlength="30"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-left pb-0">
            <label for="tel">{{ $t('tel') }}</label>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-text-field
              dense
              ref="tel"
              id="tel"
              validate-on-blur
              hide-details="auto"
              :rules="[rules.required(tel, 'tel')]"
              v-model="tel"
              maxlength="20"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0 mt-5">
            <v-btn
              class="rounded-lg"
              type="submit"
              color="primary"
              block
              elevation="0"
              height="40"
              :disabled="isLoading"
              :loading="isLoading"
              id="continueWithEmail"
              @click="setSignUp"
            >
              {{ $t('signUp') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pt-0 pb-0 mt-5">
          <v-col class="pb-0">
            <router-link :to="{ name: 'SignIn' }" class="black--text">
              {{ $t('signIn') }}
            </router-link>
          </v-col>
        </v-row>
        <v-row v-if="showMsg">
          <v-col class="pt-0 pb-0 mt-5">
            <v-alert dense outlined v-html="errorMessage" />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </Layout>
</template>

<script>
import Layout from '@/layouts/Onboarding'
import firebase from 'firebase/app'
import { isNull, regExpTest } from '@/services/common'
import { getInviteCheck, setSignUp } from '@/services/api'

export default {
  name: 'SignUp',
  components: {
    Layout,
  },
  data: () => ({
    currentUser: null,
    userId: '',
    email: '',
    password: '',
    passwordRepeat: '',
    name: '',
    tel: '',
    isLoading: false,
    showMsg: false,
    errorCode: '',
    errorMessage: '',
    isCanSignUp: false,
  }),
  computed: {
    rules() {
      let vm = this

      return {
        required: (value, type) => !isNull(value) || vm.$t('msgEnter' + type.charAt(0).toUpperCase() + type.slice(1)),
        min: (value) => value.length >= 6 || vm.$t('msgPasswordMinLength'),
        email: (value) => regExpTest('EMAIL', value) || vm.$t('msgCheckEmailFormat'),
        repeatPw: (password, passwordRepeat) => password === passwordRepeat || vm.$t('msgCheckPasswordRepeat'),
      }
    },
  },
  async created() {
    if (!localStorage.getItem('inviteEmail')) {
      alert(this.$t('canNotSignUp').replace('<br/>', '\n'))
      this.$router.replace({ name: 'SignIn' })
    } else {
      this.email = localStorage.getItem('inviteEmail')
      await this.checkAvailableSignUp()
    }
  },
  beforeDestroy() {
    localStorage.removeItem('inviteEmail')
  },
  methods: {
    async checkAvailableSignUp() {
      try {
        let result = await getInviteCheck(this, this.email)

        if (result.status === 200) {
          if (!result.data) {
            alert(this.$t('canNotSignUp').replace('<br/>', '\n'))
            await this.$router.replace({ name: 'SignIn' })
          } else this.isCanSignUp = true
        } else {
          alert(this.$t('canNotSignUp').replace('<br/>', '\n'))
          await this.$router.replace({ name: 'SignIn' })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async setSignUp() {
      this.isLoading = true
      this.errorCode = ''
      this.errorMessage = ''
      this.showMsg = false

      if (!this.$refs.signUpForm.validate()) {
        this.isLoading = false
        return false
      }

      try {
        if (!this.email) throw Error(this.$t('canNotSignUp'))

        let userCredential = await firebase.auth().createUserWithEmailAndPassword(this.email, this.password)

        let result = await setSignUp(this, userCredential.user.uid, this.email, this.name, this.tel) // 체크

        if (result.status === 200) {
          if (result.data) {
            alert(this.$t('signUpCompleted'))
            location.reload()
            // this.$router.replace({ name: 'SignIn' })
          }
        } else throw Error(this.$t('canNotSignUp'))
      } catch (error) {
        // console.error(error)
        this.errorMessage = this.$t('canNotSignUp')
        this.showMsg = true
        this.isLoading = false
      }
    },
  },
}
</script>
