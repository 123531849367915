<template>
  <Layout>
    <v-container class="pt-lg-15 pt-md-10 pb-0" fluid slot="title">
      <v-row no-gutters>
        <v-col cols="12" md="auto">
          <h2
            :class="[
              { 'font-size-30': $vuetify.breakpoint.mdAndUp },
              { 'font-size-20': $vuetify.breakpoint.smAndDown },
            ]"
          >
            {{ isLoadingGsenDetail ? $t('gsen') : gsenDetail['GSEN_NAME'] }}
            <br v-if="$vuetify.breakpoint.smAndDown" />
            <span
              :class="[
                'font-weight-regular grey300--text',
                { 'ml-3 font-size-16': $vuetify.breakpoint.mdAndUp },
                { 'font-size-14': $vuetify.breakpoint.smAndDown },
              ]"
              v-if="!isLoadingGsenDetail"
            >
              {{ gsenDetail['AREA_A']['AREA_A_NAME'] }} > {{ gsenDetail['AREA_B']['AREA_B_NAME'] }} >
              {{ gsenDetail['AREA_C']['AREA_C_NAME'] }}
            </span>
          </h2>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="auto" class="d-flex align-end justify-end">
          <span
            :class="[
              'grey600--text',
              { 'font-size-14': $vuetify.breakpoint.mdAndUp },
              { 'font-size-12': $vuetify.breakpoint.smAndDown },
              { 'mt-2 mb-1': $vuetify.breakpoint.smAndDown },
            ]"
          >
            {{ lastUpdatedTime ? $t('lastUpdate') + lastUpdatedTime : '' }}
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      class="pa-lg-4 pt-lg-10 pt-md-10 align-content-start"
      fluid
      fill-height
      slot="content"
      v-if="isLoadingGsenDetail"
    >
      <v-row>
        <v-col class="text-center grey400--text py-16">
          <v-progress-circular indeterminate />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pa-lg-4 pt-lg-10 pt-md-10 align-content-start" fluid fill-height slot="content" v-else>
      <v-row :class="[{ 'px-2': $vuetify.breakpoint.smAndDown }]">
        <v-col cols="3" sm="2" md="4" lg="3" :class="[{ 'pa-1': $vuetify.breakpoint.smAndDown }]">
          <v-sheet outlined color="grey100" rounded="lg" class="box" height="100%">
            <v-card tag="section" rounded="lg" elevation="0" height="100%">
              <v-container fill-height class="pa-2 pa-md-8">
                <v-row no-gutters class="d-flex align-center justify-center">
                  <v-col cols="12" md="auto" class="text-center">
                    <v-progress-circular
                      rotate="-90"
                      :value="
                        Math.round((gsenDetail['GSEN_BAT_LVL'] > 100 ? 100 : gsenDetail['GSEN_BAT_LVL']) / 10) * 10
                      "
                      :size="$vuetify.breakpoint.mdAndUp ? 56 : 48"
                      :width="$vuetify.breakpoint.mdAndUp ? 8 : 5"
                      :color="gsenDetail['STATE'] === 'NOSIGNAL' ? 'grey100' : 'grey800'"
                      class="progress-circular"
                    >
                      <v-icon small :color="gsenDetail['STATE'] === 'NOSIGNAL' ? 'grey100' : 'grey800'">
                        {{ getBatteryIcon(gsenDetail['GSEN_BAT_LVL']) + ' mdi-rotate-90' }}
                      </v-icon>
                    </v-progress-circular>
                  </v-col>
                  <v-col
                    cols="12"
                    md="auto"
                    :class="[
                      'font-weight-bold text-center',
                      gsenDetail['STATE'] === 'NOSIGNAL' ? 'grey100--text' : 'grey900--text',
                      $vuetify.breakpoint.smAndDown ? '' : 'ml-3 ml-md-5',
                    ]"
                  >
                    <span
                      :class="[
                        { 'font-size-32': $vuetify.breakpoint.mdAndUp && gsenDetail['STATE'] !== 'NOSIGNAL' },
                        { 'font-size-24': $vuetify.breakpoint.smAndDown && gsenDetail['STATE'] !== 'NOSIGNAL' },
                        { 'font-size-24': $vuetify.breakpoint.mdAndUp && gsenDetail['STATE'] === 'NOSIGNAL' },
                        { 'font-size-12': $vuetify.breakpoint.smAndDown && gsenDetail['STATE'] === 'NOSIGNAL' },
                      ]"
                    >
                      {{
                        gsenDetail['STATE'] === 'NOSIGNAL'
                          ? $t('nosignal')
                          : gsenDetail['GSEN_BAT_LVL'] > 100
                          ? 100
                          : gsenDetail['GSEN_BAT_LVL']
                      }}
                    </span>
                    <span
                      :class="[
                        { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                        { 'font-size-16': $vuetify.breakpoint.smAndDown },
                      ]"
                    >
                      {{ gsenDetail['STATE'] === 'NOSIGNAL' ? '' : '%' }}
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="8"
          lg="9"
          :order="$vuetify.breakpoint.mdAndUp ? 2 : 3"
          :class="[{ 'pa-1': $vuetify.breakpoint.smAndDown }]"
        >
          <v-sheet outlined color="grey100" rounded="lg" class="box">
            <v-card tag="section" rounded="lg" elevation="0">
              <v-container fill-height class="pa-2 pa-md-8">
                <v-row no-gutters class="d-flex align-center">
                  <search-schedule-box
                    @setSearchDtFrom="setSearchDtFrom"
                    @setSearchDtTo="setSearchDtTo"
                    class="col-12 col-lg-8"
                  />
                  <!-- <v-col md="4">
                    <v-row no-gutters>
                      <v-col>
                        <v-row class="py-1 px-2">
                          <v-col class="px-1">
                            <v-btn
                              class="rounded-lg"
                              block
                              :x-large="$vuetify.breakpoint.mdAndUp"
                              :height="$vuetify.breakpoint.mdAndUp ? 56 : 40"
                              color="grey600"
                              dark
                              elevation="0"
                              :outlined="chartInterval !== 15"
                              @click="setChartInterval(15)"
                            >
                              {{ $t('all') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col> -->
                </v-row>
              </v-container>
            </v-card>
          </v-sheet>
        </v-col>
        <v-col
          cols="9"
          md="4"
          lg="3"
          :order="$vuetify.breakpoint.mdAndUp ? 3 : 2"
          :class="[{ 'pa-1': $vuetify.breakpoint.smAndDown }]"
        >
          <v-sheet outlined color="grey100" rounded="lg" class="box">
            <v-card tag="section" rounded="lg" elevation="0">
              <v-container fill-height class="pa-2 pa-md-8">
                <v-row no-gutters :class="[{ 'flex-nowrap overflow-x-auto': $vuetify.breakpoint.smAndDown }]">
                  <v-col
                    v-for="item in itemList"
                    :key="item"
                    :cols="$vuetify.breakpoint.mdAndUp ? 6 : 'auto'"
                    class="pa-2"
                  >
                    <v-sheet
                      rounded="lg"
                      class="d-flex justify-center align-center mx-auto measure-item"
                      :width="$vuetify.breakpoint.mdAndUp ? '100%' : 100"
                      :height="$vuetify.breakpoint.mdAndUp ? 122 : 68"
                      :color="
                        activeItemList.indexOf(item) > -1
                          ? getGsenStatus(gsenDetail['GSEN_DATA_' + item + '_STATUS'])
                          : null
                      "
                      :dark="activeItemList.indexOf(item) > -1"
                      :outlined="activeItemList.indexOf(item) === -1"
                      @click="setActiveItem(item)"
                    >
                      <span
                        :class="[
                          'text-center font-weight-bold' +
                            (activeItemList.indexOf(item) === -1
                              ? ' ' + getGsenStatus(gsenDetail['GSEN_DATA_' + item + '_STATUS']) + '--text'
                              : ''),
                          { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                          { 'font-size-16': $vuetify.breakpoint.smAndDown },
                        ]"
                      >
                        {{
                          Number(gsenDetail[`GSEN_DATA_${item}`]) >= 1
                            ? parseInt(gsenDetail[`GSEN_DATA_${item}`])
                            : gsenDetail[`GSEN_DATA_${item}`]
                        }}
                        <span class="font-size-12 font-weight-regular">
                          {{ $store.getters.standardInfo[item]['STD_UNIT'] }}
                        </span>
                        <br />
                        <span
                          :class="[
                            'font-size-16 font-weight-regular',
                            { 'grey900--text': activeItemList.indexOf(item) === -1 },
                          ]"
                        >
                          {{ $t(item) }}
                        </span>
                      </span>
                    </v-sheet>
                  </v-col>
                  <v-responsive class="pa-2" v-if="!$vuetify.breakpoint.smAndDown"></v-responsive>
                </v-row>
              </v-container>
            </v-card>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="8" lg="9" order="4" :class="[{ 'pa-1': $vuetify.breakpoint.smAndDown }]">
          <v-sheet outlined color="grey100" rounded="lg" class="box" height="100%">
            <v-card tag="section" rounded="lg" elevation="0" height="100%">
              <v-container
                fill-height
                class="pa-2 pa-md-8 grey200--text d-flex justify-center align-center"
                v-if="activeItemList.length === 0"
              >
                <span>{{ $t('msgSelectItem') }}</span>
              </v-container>
              <v-container fill-height class="pa-2 pa-md-8 d-block" v-else>
                <v-row v-for="item in activeItemList" :key="item">
                  <v-col cols="12">
                    <v-card outlined rounded="lg" class="pa-2 pa-md-8">
                      <v-container>
                        <v-row>
                          <v-col cols="auto">
                            <h3
                              :class="[
                                { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                                { 'font-size-16': $vuetify.breakpoint.smAndDown },
                              ]"
                            >
                              {{ $t(item) }}
                            </h3>
                          </v-col>
                          <v-spacer />
                          <v-col cols="auto">
                            <v-btn
                              class="rounded-lg"
                              icon
                              @click="activeItemList.splice(activeItemList.indexOf(item), 1)"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <gsen-data
                          :gsenUdid="gsenUdid"
                          :item="item"
                          :dataDtFrom="dataDtFrom"
                          :dataDtTo="dataDtTo"
                          :ref="'gsenData-' + item"
                        />
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
import Layout from '@/layouts/Dashboard'
import { getGsenDetail } from '@/services/api'
import SearchScheduleBox from '@/components/SearchScheduleBox.vue'
import GsenData from '@/components/GsenData.vue'
import { getGsenStatus } from '@/services/common'

export default {
  name: 'GsenDetail',
  components: {
    Layout,
    SearchScheduleBox,
    GsenData,
  },
  created() {
    this.getGsenDetail()

    this.updateInterval = setInterval(() => {
      this.getGsenDetail(true)
    }, this.updateIntervalTime)
  },
  beforeDestroy() {
    clearInterval(this.updateInterval)
  },
  data: () => ({
    updateInterval: null,
    updateIntervalTime: 10000,
    lastUpdatedTime: '',
    gsenDetail: null,
    isLoadingGsenDetail: true,
    isLoadingGsenData: true,
    dataDtFrom: '',
    dataDtTo: '',
    chartInterval: 15,
    itemList: ['PM1P0', 'PM2P5', 'PM10P0', 'TVOC', 'CO2', 'CO', 'HCHO', 'RADON', 'TEMP', 'HUMI', 'SOUND'],
    activeItemList: [],
  }),
  computed: {
    gsenUdid() {
      return this.$route.params['gsenUdid']
    },
  },
  methods: {
    async getGsenDetail(update = false) {
      if (!update) this.isLoadingGsenDetail = true
      try {
        let result = await getGsenDetail(this, this.gsenUdid)
        if (result.data.length === 0) {
          alert(this.$t('noData'))
          this.$router.replace({ name: 'Gsen' })
        }
        this.gsenDetail = result.data[0]
        this.lastUpdatedTime = this.gsenDetail['GSEN_DATA_DT']
          ? this.$day(this.gsenDetail['GSEN_DATA_DT']).format('YYYY.MM.DD hh:mm:ss')
          : ''
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingGsenDetail = false
      }
    },
    async setSearchDtFrom(searchDateFrom) {
      this.dataDtFrom = searchDateFrom
      if (this.dataDtFrom !== '' && this.dataDtTo !== '') {
        let tempActiveItemList = this.activeItemList.splice(0)
        this.activeItemList = this.activeItemList.splice()
        setTimeout(async () => {
          this.activeItemList = this.activeItemList.concat(tempActiveItemList)
        }, 100)
        // await this.requestSearch()
      }
    },
    async setSearchDtTo(searchDateTo) {
      this.dataDtTo = searchDateTo
      if (this.dataDtFrom !== '' && this.dataDtTo !== '') {
        let tempActiveItemList = this.activeItemList.splice(0)
        this.activeItemList = this.activeItemList.splice()
        setTimeout(async () => {
          this.activeItemList = this.activeItemList.concat(tempActiveItemList)
        }, 100)
        // await this.requestSearch()
      }
    },
    async requestSearch() {
      // if (item) {
      //   this.$refs['gsenData-' + item].getGsenData(item)
      // } else {
      //   for (let item of this.activeItemList) {
      //     this.$refs['gsenData-' + item].getGsenData(item)
      //   }
      // }
      for (let item of this.activeItemList) {
        let refName = `gsenData-${item}`

        this.$refs[refName].forEach((i) => {
          i.getGsenData()
        })
      }
    },
    getBatteryIcon(level) {
      let batClass = 'mdi-battery'
      let batLevel = Math.round((level > 100 ? 100 : level) / 10) * 10

      if (batLevel < 100) batClass += '-' + batLevel

      return batClass
    },
    getGsenStatus(status) {
      return getGsenStatus(status)
    },
    setActiveItem(item) {
      let index = this.activeItemList.indexOf(item)
      if (index > -1) this.activeItemList.splice(index, 1)
      else this.activeItemList.push(item)
      return index
    },
  },
}
</script>

<style scoped>
.progress-circular >>> circle {
  stroke-linecap: round;
}
.progress-circular >>> .v-progress-circular__underlay {
  stroke: var(--v-grey100-base) !important;
}
.measure-item {
  cursor: pointer;
}
</style>
