<template>
  <v-app>
    <v-main class="blue-grey lighten-5">
      <v-container fill-height>
        <v-container fluid>
          <v-card
            elevation="0"
            class="mx-auto onboading-wrap"
            max-width="1120px"
            :height="$vuetify.breakpoint.mdAndUp ? 676 : 'auto'"
          >
            <v-container fill-height class="pa-0" align-md-stretch>
              <v-row no-gutters style="height: 100%">
                <v-col cols="12" md="6" class="left d-flex justify-center align-center pa-md-0 pa-4">
                  <h1 :class="$vuetify.breakpoint.mdAndUp ? 'big' : 'small'">GILLS - 4SEASON TOTAL CARE SYSTEM</h1>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  :class="[
                    $vuetify.breakpoint.mdAndUp ? 'big' : 'small',
                    'pa-10 d-flex justify-center align-center content overflow-auto',
                  ]"
                  style="height: 100%"
                >
                  <slot name="title" />
                  <slot name="content" />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-container>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import { mapActions } from 'vuex'

export default {
  name: 'OnboardingLayout',
  data: () => ({}),
  methods: {
    // ...mapActions(['setSignOut']),
  },
}
</script>

<style scoped>
.onboading-wrap {
  box-shadow: 0px 12px 42px rgb(190 190 190 / 60%) !important;
  border-radius: 10px;
  overflow: hidden;
}

.onboading-wrap .left {
  background: linear-gradient(#479ec1, #105a78);
}

.onboading-wrap .left h1 {
  width: 100%;
  /* height: 100%; */
  max-width: 335px;
  /* max-height: 236px; */
  background-image: url('~@/assets/images/logo-onboarding.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-indent: -9999px;
  font-size: 0;
  line-height: 0;
  padding-top: 33.6%;
}

.onboading-wrap .left h1.small {
  width: 100px;
  height: 80px;
  padding-top: 0;
}

.onboading-wrap > .container {
  padding-top: 0;
  padding-bottom: 0;
}

.onboading-wrap .content >>> form {
  width: 100%;
  max-width: 300px;
}

.onboading-wrap .content >>> form h2 {
  line-height: 52px;
  font-size: 36px;
  font-weight: 700;
}

.onboading-wrap .content.small >>> form h2 {
  line-height: 32px;
  font-size: 28px;
}

.onboading-wrap .content >>> form label {
  color: var(--v-grey300-base);
}

.onboading-wrap .content.small >>> form {
  font-size: 14px;
}

.onboading-wrap .content >>> form button.v-size--x-large {
  font-size: 20px;
  font-weight: 700;
}

.onboading-wrap .content.small >>> form button.v-size--x-large {
  font-size: 16px;
}

.onboading-wrap .content >>> .v-input:not(.error--text) .v-input__slot:before {
  border-color: #eceff1 !important;
}
</style>
