<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-sheet outlined color="grey100" rounded="lg" class="box" v-if="editedStandardInfo">
          <v-card tag="section" rounded="lg" elevation="0" class="pa-4 pa-md-10">
            <v-container fill-height fluid class="pa-0">
              <v-row class="align-self-start">
                <v-col cols="12" sm="auto">
                  <h3
                    :class="[
                      { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                      { 'font-size-16': $vuetify.breakpoint.smAndDown },
                    ]"
                  >
                    {{ $t('standard') }}
                    <!-- {{ $t('pushNotify') }} -->
                  </h3>
                </v-col>
                <!-- <v-spacer v-if="$vuetify.breakpoint.smAndDown" /> -->
                <!-- <v-col cols="auto">
                  <v-switch
                    class="ma-0"
                    hide-details
                    inset
                    color="working"
                    readonly
                    @click="pushNotifySnackbar = true"
                  />
                  <v-snackbar v-model="pushNotifySnackbar" :timeout="2000" :value="true" color="grey600" bottom center>
                    {{ $t('comingSoon') }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                        class="rounded-lg font-weight-bold"
                        color="white"
                        text
                        v-bind="attrs"
                        @click="pushNotifySnackbar = false"
                      >
                        {{ $t('close') }}
                      </v-btn>
                    </template>
                  </v-snackbar>
                </v-col>
                <v-col
                  cols="12"
                  md="auto"
                  :class="[
                    'd-flex',
                    { 'align-center': $vuetify.breakpoint.mdAndUp },
                    { 'align-start pt-0': $vuetify.breakpoint.smAndDown },
                  ]"
                >
                  <v-icon color="grey300" small>mdi-information-outline</v-icon>
                  <span class="ml-1 font-size-12 grey300--text font-weight-regular">{{ $t('pushNotifyNotice') }}</span>
                </v-col> -->
                <v-spacer />
                <v-col cols="6" sm="auto">
                  <v-btn
                    class="rounded-lg"
                    block
                    color="grey600"
                    elevation="0"
                    :loading="isLoadingStandard || isSavingStandard"
                    :disabled="isLoadingStandard || isSavingStandard"
                    outlined
                    :small="$vuetify.breakpoint.smAndDown"
                    @click="editedStandardInfo = JSON.parse(JSON.stringify(standardInfo))"
                  >
                    {{ $t('revert') }}
                  </v-btn>
                </v-col>
                <v-col cols="6" sm="auto">
                  <v-btn
                    class="rounded-lg"
                    block
                    color="grey600"
                    elevation="0"
                    :loading="isLoadingStandard || isSavingStandard"
                    :disabled="isLoadingStandard || isSavingStandard"
                    :outlined="isLoadingStandard || isSavingStandard"
                    :dark="!isLoadingStandard && !isSavingStandard"
                    :small="$vuetify.breakpoint.smAndDown"
                    @click="setStandard"
                  >
                    {{ $t('saveChanges') }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-container fluid>
                    <v-row>
                      <v-col class="overflow-auto pa-0">
                        <v-responsive min-width="1450">
                          <v-container
                            fluid
                            :class="[
                              'table',
                              { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                              { 'font-size-14': $vuetify.breakpoint.smAndDown },
                            ]"
                          >
                            <v-row
                              :class="['thead align-center text-center px-4', { dense: $vuetify.breakpoint.smAndDown }]"
                            >
                              <v-col class="font-weight-bold">{{ $t('standard') }}</v-col>
                              <v-col
                                class="font-weight-bold"
                                v-for="measureItem in Object.keys(editedStandardInfo)"
                                :key="measureItem"
                              >
                                {{ $t(measureItem) }}
                              </v-col>
                            </v-row>
                            <v-row
                              :class="['tbody align-center px-4', { dense: $vuetify.breakpoint.smAndDown }]"
                              v-for="standardItem in standardItemList"
                              :key="standardItem"
                            >
                              <v-col class="text-center font-weight-bold">{{ $t(standardItem) }}</v-col>
                              <v-col
                                class="text-center"
                                v-for="measureItem in Object.keys(editedStandardInfo)"
                                :key="measureItem"
                              >
                                <v-text-field
                                  class="rounded-lg d-flex align-center font-size-14 text-center"
                                  outlined
                                  hide-details
                                  dense
                                  v-if="['warn', 'alert'].indexOf(standardItem) > -1"
                                  :prefix="['TEMP', 'HUMI'].indexOf(measureItem) > -1 ? '±' : ''"
                                  v-model="editedStandardInfo[`${measureItem}`][`STD_${standardItem.toUpperCase()}`]"
                                  :disabled="isSavingStandard || isLoadingStandard"
                                />
                                <span v-if="standardItem === 'unit'">
                                  {{ editedStandardInfo[`${measureItem}`]['STD_UNIT'] }}
                                </span>
                                <span v-if="standardItem === 'safe' && ['TEMP', 'HUMI'].indexOf(measureItem) === -1">
                                  00 ~ {{ editedStandardInfo[`${measureItem}`]['STD_WARN'] }}
                                </span>
                                <v-container
                                  v-if="standardItem === 'safe' && ['TEMP', 'HUMI'].indexOf(measureItem) > -1"
                                  class="pa-0"
                                >
                                  <v-row no-gutters>
                                    <v-col class="pa-0">
                                      <v-text-field
                                        class="rounded-lg d-flex align-center font-size-14 text-center"
                                        outlined
                                        hide-details
                                        dense
                                        maxlength="2"
                                        v-model="editedStandardInfo[`${measureItem}`]['STD_SAFE_MIN']"
                                        :disabled="isSavingStandard || isLoadingStandard"
                                      />
                                    </v-col>
                                    <v-col class="pa-0 d-flex align-center justify-center" cols="2">~</v-col>
                                    <v-col class="pa-0">
                                      <v-text-field
                                        class="rounded-lg d-flex align-center font-size-14 text-center"
                                        outlined
                                        hide-details
                                        dense
                                        maxlength="2"
                                        v-model="editedStandardInfo[`${measureItem}`]['STD_SAFE_MAX']"
                                        :disabled="isSavingStandard || isLoadingStandard"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-responsive>
                      </v-col>
                    </v-row>
                    <!-- <v-row v-if="gillsUserPage !== null && gillsUserPage['totalCount'] > 0">
                    <v-col>
                      <v-pagination v-model="userPage" :length="gillsUserPage['totalPage']" @input="getGillsUser" />
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col class="text-center grey400--text py-16">
                      <v-progress-circular indeterminate v-if="isLoadingGillsUser" />
                      <span v-else>{{ $t('noData') }}</span>
                    </v-col>
                  </v-row> -->
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { setStandard } from '@/services/api'
import { mapGetters } from 'vuex'

export default {
  name: 'StandardManagement',
  data: () => ({
    standardItemList: ['unit', 'safe', 'warn', 'alert'],
    editedStandardInfo: null,
    pushNotifySnackbar: false,
    isLoadingStandard: false,
    isSavingStandard: false,
  }),
  computed: {
    ...mapGetters(['userInfo', 'standardInfo']),
  },
  created() {
    // 길스 아이디 외에는 접근 불가
    if (this.userInfo['USER_LEVEL'] !== 'G') {
      this.$router.replace({ name: 'AreaManagement' })
    } else this.editedStandardInfo = JSON.parse(JSON.stringify(this.standardInfo))
  },
  methods: {
    async setStandard() {
      this.isSavingStandard = true
      try {
        let result = await setStandard(this, this.editedStandardInfo)
        if (result.data) {
          this.isLoadingStandard = true
          await this.$store.dispatch('getStandard')
          this.isLoadingStandard = false
        }
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(this.$t('errorMsg'))
      } finally {
        this.isSavingStandard = false
      }
    },
  },
}
</script>

<style scoped>
::v-deep.v-text-field input {
  text-align: center;
}
</style>
