var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('v-container',{staticClass:"pt-lg-15 pt-md-10 pb-0",attrs:{"slot":"title","fluid":""},slot:"title"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('h2',{class:[
            { 'font-size-30': _vm.$vuetify.breakpoint.mdAndUp },
            { 'font-size-20': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.isLoadingGsenDetail ? _vm.$t('gsen') : _vm.gsenDetail['GSEN_NAME'])+" "),(_vm.$vuetify.breakpoint.smAndDown)?_c('br'):_vm._e(),(!_vm.isLoadingGsenDetail)?_c('span',{class:[
              'font-weight-regular grey300--text',
              { 'ml-3 font-size-16': _vm.$vuetify.breakpoint.mdAndUp },
              { 'font-size-14': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.gsenDetail['AREA_A']['AREA_A_NAME'])+" > "+_vm._s(_vm.gsenDetail['AREA_B']['AREA_B_NAME'])+" > "+_vm._s(_vm.gsenDetail['AREA_C']['AREA_C_NAME'])+" ")]):_vm._e()])]),_c('v-spacer'),_c('v-col',{staticClass:"d-flex align-end justify-end",attrs:{"cols":"12","md":"auto"}},[_c('span',{class:[
            'grey600--text',
            { 'font-size-14': _vm.$vuetify.breakpoint.mdAndUp },
            { 'font-size-12': _vm.$vuetify.breakpoint.smAndDown },
            { 'mt-2 mb-1': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.lastUpdatedTime ? _vm.$t('lastUpdate') + _vm.lastUpdatedTime : '')+" ")])])],1)],1),(_vm.isLoadingGsenDetail)?_c('v-container',{staticClass:"pa-lg-4 pt-lg-10 pt-md-10 align-content-start",attrs:{"slot":"content","fluid":"","fill-height":""},slot:"content"},[_c('v-row',[_c('v-col',{staticClass:"text-center grey400--text py-16"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)],1)],1):_c('v-container',{staticClass:"pa-lg-4 pt-lg-10 pt-md-10 align-content-start",attrs:{"slot":"content","fluid":"","fill-height":""},slot:"content"},[_c('v-row',{class:[{ 'px-2': _vm.$vuetify.breakpoint.smAndDown }]},[_c('v-col',{class:[{ 'pa-1': _vm.$vuetify.breakpoint.smAndDown }],attrs:{"cols":"3","sm":"2","md":"4","lg":"3"}},[_c('v-sheet',{staticClass:"box",attrs:{"outlined":"","color":"grey100","rounded":"lg","height":"100%"}},[_c('v-card',{attrs:{"tag":"section","rounded":"lg","elevation":"0","height":"100%"}},[_c('v-container',{staticClass:"pa-2 pa-md-8",attrs:{"fill-height":""}},[_c('v-row',{staticClass:"d-flex align-center justify-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"auto"}},[_c('v-progress-circular',{staticClass:"progress-circular",attrs:{"rotate":"-90","value":Math.round((_vm.gsenDetail['GSEN_BAT_LVL'] > 100 ? 100 : _vm.gsenDetail['GSEN_BAT_LVL']) / 10) * 10,"size":_vm.$vuetify.breakpoint.mdAndUp ? 56 : 48,"width":_vm.$vuetify.breakpoint.mdAndUp ? 8 : 5,"color":_vm.gsenDetail['STATE'] === 'NOSIGNAL' ? 'grey100' : 'grey800'}},[_c('v-icon',{attrs:{"small":"","color":_vm.gsenDetail['STATE'] === 'NOSIGNAL' ? 'grey100' : 'grey800'}},[_vm._v(" "+_vm._s(_vm.getBatteryIcon(_vm.gsenDetail['GSEN_BAT_LVL']) + ' mdi-rotate-90')+" ")])],1)],1),_c('v-col',{class:[
                    'font-weight-bold text-center',
                    _vm.gsenDetail['STATE'] === 'NOSIGNAL' ? 'grey100--text' : 'grey900--text',
                    _vm.$vuetify.breakpoint.smAndDown ? '' : 'ml-3 ml-md-5' ],attrs:{"cols":"12","md":"auto"}},[_c('span',{class:[
                      { 'font-size-32': _vm.$vuetify.breakpoint.mdAndUp && _vm.gsenDetail['STATE'] !== 'NOSIGNAL' },
                      { 'font-size-24': _vm.$vuetify.breakpoint.smAndDown && _vm.gsenDetail['STATE'] !== 'NOSIGNAL' },
                      { 'font-size-24': _vm.$vuetify.breakpoint.mdAndUp && _vm.gsenDetail['STATE'] === 'NOSIGNAL' },
                      { 'font-size-12': _vm.$vuetify.breakpoint.smAndDown && _vm.gsenDetail['STATE'] === 'NOSIGNAL' } ]},[_vm._v(" "+_vm._s(_vm.gsenDetail['STATE'] === 'NOSIGNAL' ? _vm.$t('nosignal') : _vm.gsenDetail['GSEN_BAT_LVL'] > 100 ? 100 : _vm.gsenDetail['GSEN_BAT_LVL'])+" ")]),_c('span',{class:[
                      { 'font-size-20': _vm.$vuetify.breakpoint.mdAndUp },
                      { 'font-size-16': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.gsenDetail['STATE'] === 'NOSIGNAL' ? '' : '%')+" ")])])],1)],1)],1)],1)],1),_c('v-col',{class:[{ 'pa-1': _vm.$vuetify.breakpoint.smAndDown }],attrs:{"cols":"12","md":"8","lg":"9","order":_vm.$vuetify.breakpoint.mdAndUp ? 2 : 3}},[_c('v-sheet',{staticClass:"box",attrs:{"outlined":"","color":"grey100","rounded":"lg"}},[_c('v-card',{attrs:{"tag":"section","rounded":"lg","elevation":"0"}},[_c('v-container',{staticClass:"pa-2 pa-md-8",attrs:{"fill-height":""}},[_c('v-row',{staticClass:"d-flex align-center",attrs:{"no-gutters":""}},[_c('search-schedule-box',{staticClass:"col-12 col-lg-8",on:{"setSearchDtFrom":_vm.setSearchDtFrom,"setSearchDtTo":_vm.setSearchDtTo}})],1)],1)],1)],1)],1),_c('v-col',{class:[{ 'pa-1': _vm.$vuetify.breakpoint.smAndDown }],attrs:{"cols":"9","md":"4","lg":"3","order":_vm.$vuetify.breakpoint.mdAndUp ? 3 : 2}},[_c('v-sheet',{staticClass:"box",attrs:{"outlined":"","color":"grey100","rounded":"lg"}},[_c('v-card',{attrs:{"tag":"section","rounded":"lg","elevation":"0"}},[_c('v-container',{staticClass:"pa-2 pa-md-8",attrs:{"fill-height":""}},[_c('v-row',{class:[{ 'flex-nowrap overflow-x-auto': _vm.$vuetify.breakpoint.smAndDown }],attrs:{"no-gutters":""}},[_vm._l((_vm.itemList),function(item){return _c('v-col',{key:item,staticClass:"pa-2",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 'auto'}},[_c('v-sheet',{staticClass:"d-flex justify-center align-center mx-auto measure-item",attrs:{"rounded":"lg","width":_vm.$vuetify.breakpoint.mdAndUp ? '100%' : 100,"height":_vm.$vuetify.breakpoint.mdAndUp ? 122 : 68,"color":_vm.activeItemList.indexOf(item) > -1
                        ? _vm.getGsenStatus(_vm.gsenDetail['GSEN_DATA_' + item + '_STATUS'])
                        : null,"dark":_vm.activeItemList.indexOf(item) > -1,"outlined":_vm.activeItemList.indexOf(item) === -1},on:{"click":function($event){return _vm.setActiveItem(item)}}},[_c('span',{class:[
                        'text-center font-weight-bold' +
                          (_vm.activeItemList.indexOf(item) === -1
                            ? ' ' + _vm.getGsenStatus(_vm.gsenDetail['GSEN_DATA_' + item + '_STATUS']) + '--text'
                            : ''),
                        { 'font-size-20': _vm.$vuetify.breakpoint.mdAndUp },
                        { 'font-size-16': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(Number(_vm.gsenDetail[("GSEN_DATA_" + item)]) >= 1 ? parseInt(_vm.gsenDetail[("GSEN_DATA_" + item)]) : _vm.gsenDetail[("GSEN_DATA_" + item)])+" "),_c('span',{staticClass:"font-size-12 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$store.getters.standardInfo[item]['STD_UNIT'])+" ")]),_c('br'),_c('span',{class:[
                          'font-size-16 font-weight-regular',
                          { 'grey900--text': _vm.activeItemList.indexOf(item) === -1 } ]},[_vm._v(" "+_vm._s(_vm.$t(item))+" ")])])])],1)}),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-responsive',{staticClass:"pa-2"}):_vm._e()],2)],1)],1)],1)],1),_c('v-col',{class:[{ 'pa-1': _vm.$vuetify.breakpoint.smAndDown }],attrs:{"cols":"12","md":"8","lg":"9","order":"4"}},[_c('v-sheet',{staticClass:"box",attrs:{"outlined":"","color":"grey100","rounded":"lg","height":"100%"}},[_c('v-card',{attrs:{"tag":"section","rounded":"lg","elevation":"0","height":"100%"}},[(_vm.activeItemList.length === 0)?_c('v-container',{staticClass:"pa-2 pa-md-8 grey200--text d-flex justify-center align-center",attrs:{"fill-height":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('msgSelectItem')))])]):_c('v-container',{staticClass:"pa-2 pa-md-8 d-block",attrs:{"fill-height":""}},_vm._l((_vm.activeItemList),function(item){return _c('v-row',{key:item},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-2 pa-md-8",attrs:{"outlined":"","rounded":"lg"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('h3',{class:[
                              { 'font-size-20': _vm.$vuetify.breakpoint.mdAndUp },
                              { 'font-size-16': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.$t(item))+" ")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"rounded-lg",attrs:{"icon":""},on:{"click":function($event){_vm.activeItemList.splice(_vm.activeItemList.indexOf(item), 1)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('gsen-data',{ref:'gsenData-' + item,refInFor:true,attrs:{"gsenUdid":_vm.gsenUdid,"item":item,"dataDtFrom":_vm.dataDtFrom,"dataDtTo":_vm.dataDtTo}})],1)],1)],1)],1)}),1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }