'use strict'

module.exports = {
  /* isNotNull */
  isNotNull: (data) => {
    if (data !== null && data != 'null' && data != undefined && data != '') return true
    else return false
  },
  /* isNull */
  isNull: (data) => {
    if (data === null || data == 'null' || data == undefined || data === '') return true
    else return false
  },
  /* 유효성 체크 */
  regExpTest: (type, value) => {
    var regExp = ''
    switch (type) {
      case 'MOBILE':
        regExp = /^\d{3}-\d{3,4}-\d{4}$/
        break
      case 'MOBILE2':
        regExp = /(01[016789])(\d{4}|\d{3})\d{4}$/g
        break
      case 'HANGUL':
        regExp = /[가-힣]/
        break
      case 'EMPTY':
        regExp = /\s/g
        break
      case 'NUM':
        regExp = /^[0-9]+$/
        break
      case 'EMAIL':
        regExp = /^[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
        break
      case 'ID':
        regExp = /^[a-zA-Z]+[a-zA-Z0-9]{3,30}$/g
        break
      case 'PASSWD':
        regExp = /^.*(?=.{8,30})(?=.*[0-9])(?=.*[a-zA-Z]).*$/
        break
    }

    if (!regExp.test(value) || value == undefined) return false
    else return true
  },
  /* GSEN 상태값 */
  getGsenStatus(status) {
    switch (status) {
      case 'S':
        return 'safe'
      case 'W':
        return 'warning'
      case 'A':
        return 'alert'
      case 'D':
        return 'danger'
      case 'E':
        return 'nosignal'
      case 'N':
        return 'normal'
      case null:
        return 'nosignal'
      default:
        return ''
    }
  },
  /* GSEN 상태값2 */
  getGsenStatusWord(status) {
    switch (status) {
      case 'S':
        return 'good'
      case 'W':
        return 'normal'
      case 'A':
        return 'bad'
      case 'D':
        return 'verybad'
      case 'E':
        return 'error'
      case null:
        return 'error'
      default:
        return ''
    }
  },
  /* 사용자 권한 */
  getUserLevel(level) {
    switch (level) {
      case 'G':
        return 'gills'
      case 'M':
        return 'manager'
      case 'U':
        return 'user'
      default:
        return ''
    }
  },
}
