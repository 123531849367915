<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-sheet outlined color="grey100" rounded="lg" class="box">
          <v-card tag="section" rounded="lg" elevation="0" class="pa-4 pa-md-10">
            <search-box
              :isUseKeyword="false"
              :isUseKeywordOffset="true"
              @setAreaAId="setAreaAId"
              @setAreaBId="setAreaBId"
              @setAreaCId="setAreaCId"
              @setKeyword="() => {}"
              @requestSearch="requestSearch"
            />
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet outlined color="grey100" rounded="lg" class="box">
          <v-card tag="section" rounded="lg" elevation="0" class="pa-4 pa-md-10">
            <v-container fill-height fluid class="pa-0">
              <v-row class="align-self-start justify-center">
                <v-col cols="12" sm="auto" class="pb-0 pb-sm-3">
                  <h3
                    :class="[
                      { 'font-size-20': $vuetify.breakpoint.mdAndUp },
                      { 'font-size-16': $vuetify.breakpoint.smAndDown },
                    ]"
                  >
                    {{ $t('user') }}
                  </h3>
                </v-col>
                <v-col
                  v-if="userListPage !== null"
                  cols="auto"
                  :class="[
                    'd-flex align-center pa-2 pa-sm-3',
                    { 'font-size-14': $vuetify.breakpoint.mdAndUp },
                    { 'font-size-12': $vuetify.breakpoint.smAndDown },
                  ]"
                >
                  {{ $t('totalUserCount') }}&nbsp;
                  <strong>{{ userListPage['totalCount'] }}</strong>
                  {{ $t(userListPage['totalCount'] === 1 ? 'unitUsers' : 'unitUser') }}
                </v-col>
                <v-col
                  v-if="userListPage !== null"
                  cols="auto"
                  :class="[
                    'd-flex align-center pa-2 pa-sm-3',
                    { 'font-size-14': $vuetify.breakpoint.mdAndUp },
                    { 'font-size-12': $vuetify.breakpoint.smAndDown },
                  ]"
                >
                  {{ $t('managerCount') }}&nbsp;
                  <strong>{{ userListPage['managerCount'] }}</strong>
                  {{ $t(userListPage['managerCount'] === 1 ? 'unitUsers' : 'unitUser') }}
                </v-col>
                <v-col
                  v-if="userListPage !== null"
                  cols="auto"
                  :class="[
                    'd-flex align-center pa-2 pa-sm-3',
                    { 'font-size-14': $vuetify.breakpoint.mdAndUp },
                    { 'font-size-12': $vuetify.breakpoint.smAndDown },
                  ]"
                >
                  {{ $t('userCount') }}&nbsp;
                  <strong>{{ userListPage['userCount'] }}</strong>
                  {{ $t(userListPage['userCount'] === 1 ? 'unitUsers' : 'unitUser') }}
                </v-col>
                <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
                <v-col cols="12" sm="auto">
                  <v-dialog persistent v-model="dialogAddUser" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="rounded-lg"
                        block
                        color="grey600"
                        dark
                        elevation="0"
                        :small="$vuetify.breakpoint.smAndDown"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t('addUser') }}
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="grey100">
                        {{ $t('addUser') }}
                      </v-card-title>

                      <v-card-text>
                        <v-form ref="addUserForm" @submit.prevent="addUser" v-model="isAddUserFormValid">
                          <v-container class="table black--text">
                            <v-row
                              :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]"
                              v-if="userInfo['USER_LEVEL'] === 'G'"
                            >
                              <v-col class="font-weight-bold" cols="4">
                                {{ $t('companyName') }}
                              </v-col>
                              <v-col cols="8">
                                <v-select
                                  :items="companyList"
                                  item-text="COMPANY_NAME"
                                  item-value="COMPANY_ID"
                                  v-model="companyId"
                                  dense
                                  outlined
                                  hide-details="auto"
                                  color="grey600"
                                  class="rounded-lg"
                                  :placeholder="$t('msgSelectCompany')"
                                />
                              </v-col>
                            </v-row>
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col class="font-weight-bold" cols="4">
                                {{ $t('email') }}
                              </v-col>
                              <v-col cols="8">
                                <v-text-field
                                  v-model="email"
                                  dense
                                  outlined
                                  hide-details="auto"
                                  color="grey600"
                                  maxlength="20"
                                  :rules="[rules.required(email, 'email'), rules.email]"
                                  validate-on-blur
                                  class="rounded-lg"
                                />
                              </v-col>
                            </v-row>
                            <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                              <v-col class="font-weight-bold" cols="4">
                                {{ $t('userLevel') }}
                              </v-col>
                              <v-col cols="8">
                                <v-select
                                  :items="levelList"
                                  item-text="text"
                                  item-value="value"
                                  v-model="userLevel"
                                  dense
                                  outlined
                                  hide-details="auto"
                                  color="grey600"
                                  class="rounded-lg"
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn class="rounded-lg" color="grey600" outlined @click="dialogAddUser = false">
                          {{ $t('close') }}
                        </v-btn>
                        <v-btn
                          class="rounded-lg"
                          color="grey600"
                          elevation="0"
                          @click="addUser"
                          :loading="isSavingAddUser"
                          :disabled="isSavingAddUser || !isAddUserFormValid"
                          :outlined="isSavingAddUser || !isAddUserFormValid"
                          :dark="!isSavingAddUser && isAddUserFormValid"
                        >
                          {{ $t('sendEmail') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row v-if="userListPage !== null && userListPage['totalCount'] > 0">
                <v-col cols="12" sm="6" md="4" lg="3" v-for="(user, index) in userList" :key="index">
                  <v-sheet rounded="lg" outlined color="grey100">
                    <v-card rounded="lg" elevation="0">
                      <v-container class="px-6 py-4">
                        <v-row no-gutters>
                          <v-col cols="9">
                            <p class="mb-0 font-weight-bold text-truncate" v-if="userInfo['USER_LEVEL'] === 'G'">
                              {{ `[${user['COMPANY_NAME']}]` }}
                            </p>
                            <p class="mb-0 font-weight-bold text-truncate">
                              {{
                                !user['USER_SIGNED_DT']
                                  ? $t('inviting')
                                  : (user['USER_LEFT_DT'] ? '[' + $t('left') + ']' : '') + user['USER_NAME']
                              }}
                            </p>
                            <p class="mb-0 font-size-14 text-truncate">{{ user['USER_EMAIL'] }}</p>
                          </v-col>
                          <v-col cols="3" class="text-right" v-if="user['USER_LEVEL'] === 'M'">
                            <v-chip label small outlined color="grey400">
                              {{ $t(getUserLevel(user['USER_LEVEL'])) }}
                            </v-chip>
                          </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-3">
                          <v-col cols="auto">
                            <p class="mb-0 font-weight-bold">{{ user['USER_TEL'] }}</p>
                          </v-col>
                          <v-spacer />
                          <v-col cols="auto" v-if="!user['USER_LEFT_DT']">
                            <v-btn
                              text
                              class="pa-0 ml-2"
                              min-width="auto"
                              min-height="auto"
                              height="auto"
                              v-if="user['USER_SIGNED_DT']"
                              @click="
                                selectedUserIndex = index
                                dialogUserInfo = true
                              "
                            >
                              {{ $t('edit') }}
                            </v-btn>
                            <v-btn
                              text
                              class="pa-0 ml-2"
                              min-width="auto"
                              min-height="auto"
                              height="auto"
                              color="red"
                              @click="
                                selectedUserIndex = index
                                dialogDelUser = true
                              "
                            >
                              {{ $t('delete') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row v-if="userListPage !== null && userListPage['totalCount'] > 0">
                <v-col>
                  <v-pagination v-model="userPage" :length="userListPage['totalPage']" @input="getUserList" />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col class="text-center grey400--text py-16">
                  <v-progress-circular indeterminate v-if="isLoadingUserList" />
                  <span v-else>{{ $t('noData') }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-sheet>

        <v-dialog persistent v-model="dialogUserInfo" width="600" :fullscreen="$vuetify.breakpoint.smAndDown">
          <v-card>
            <v-card-title class="grey100">
              {{ $t('userInfo') }}
            </v-card-title>

            <v-card-text v-if="editedUserInfo">
              <v-container class="table black--text">
                <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                  <v-col class="font-weight-bold" cols="4" md="3">
                    {{ $t('name') }}
                  </v-col>
                  <v-col>
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      color="grey600"
                      v-model="editedUserInfo['USER_NAME']"
                      maxlength="20"
                      class="rounded-lg"
                    />
                  </v-col>
                </v-row>
                <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                  <v-col class="font-weight-bold" cols="4" md="3">
                    {{ $t('email') }}
                  </v-col>
                  <v-col>
                    {{ editedUserInfo['USER_EMAIL'] }}
                  </v-col>
                </v-row>
                <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                  <v-col class="font-weight-bold" cols="4" md="3">
                    {{ $t('userLevel') }}
                  </v-col>
                  <v-col>
                    <v-select
                      :items="levelList"
                      item-text="text"
                      item-value="value"
                      v-model="editedUserInfo['USER_LEVEL']"
                      dense
                      outlined
                      hide-details="auto"
                      color="grey600"
                      class="rounded-lg"
                    />
                  </v-col>
                </v-row>
                <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                  <v-col class="font-weight-bold" cols="4" md="3">
                    {{ $t('tel') }}
                  </v-col>
                  <v-col>
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      color="grey600"
                      v-model="editedUserInfo['USER_TEL']"
                      maxlength="20"
                      @keyup="editedUserInfo['USER_TEL'] = editedUserInfo['USER_TEL'].replace(/[^0-9+-]/g, '')"
                      class="rounded-lg"
                    />
                  </v-col>
                </v-row>
                <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                  <v-col class="font-weight-bold" cols="4" md="3">
                    {{ $t('signedDate') }}
                  </v-col>
                  <v-col>
                    {{ $day(userList[selectedUserIndex]['USER_SIGNED_DT']).format('YYYY-MM-DD') }}
                    {{
                      userList[selectedUserIndex]['USER_SIGNED_DT'] !== null &&
                      userList[selectedUserIndex]['USER_LEFT_DT'] === null
                        ? `(${$t('invite')}: ${$day(userList[selectedUserIndex]['USER_INVITED_DT']).format(
                            'YYYY-MM-DD'
                          )})`
                        : ''
                    }}
                    {{
                      userList[selectedUserIndex]['USER_LEFT_DT'] !== null
                        ? `(${$t('left')}: ${$day(userList[selectedUserIndex]['USER_LEFT_DT']).format('YYYY-MM-DD')})`
                        : ''
                    }}
                  </v-col>
                </v-row>
                <v-row :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]">
                  <v-col>
                    <v-row>
                      <v-col cols="auto" class="font-weight-bold pb-0">
                        {{ $t('chargeGills') }}
                      </v-col>
                      <v-spacer />
                      <v-col cols="12" md="auto" class="pb-0" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-btn
                          class="rounded-lg"
                          block
                          color="grey600"
                          dark
                          elevation="0"
                          outlined
                          :small="$vuetify.breakpoint.smAndDown"
                          @click="dialogUserCharge = true"
                          :disabled="isLoadingUserCharge"
                        >
                          {{ $t('edit') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="userChargeList !== null && userChargeList.length > 0"
                      class="overflow-y-auto"
                      style="height: 150px"
                    >
                      <v-col class="py-0">
                        <v-container class="pa-0">
                          <v-row no-gutters v-for="gills in userChargeList" :key="gills['GILLS_UDID']">
                            <v-col cols="4" md="3" class="py-1 text-truncate">
                              {{ gills['GILLS_NAME'] }}
                            </v-col>
                            <v-col cols="7" md="8" offset="1" class="py-1 text-truncate">
                              {{
                                gills['AREA_A']['AREA_A_NAME'] +
                                (gills['AREA_B']['AREA_B_NAME'] ? ' > ' + gills['AREA_B']['AREA_B_NAME'] : '') +
                                (gills['AREA_C']['AREA_C_NAME'] ? ' > ' + gills['AREA_C']['AREA_C_NAME'] : '')
                              }}
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col class="text-center grey400--text py-16">
                        <v-progress-circular indeterminate v-if="isLoadingUserCharge" />
                        <span v-else>{{ $t('noData') }}</span>
                      </v-col>
                    </v-row>
                    <v-row v-if="$vuetify.breakpoint.smAndDown">
                      <v-col cols="12" md="auto">
                        <v-btn
                          class="rounded-lg"
                          block
                          color="grey600"
                          elevation="0"
                          :small="$vuetify.breakpoint.smAndDown"
                          @click="dialogUserCharge = true"
                          :disabled="isLoadingUserCharge"
                          :outlined="isLoadingUserCharge"
                          :dark="!isLoadingUserCharge"
                        >
                          {{ $t('edit') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>

              <v-dialog
                persistent
                v-model="dialogUserCharge"
                max-width="600"
                :fullscreen="$vuetify.breakpoint.smAndDown"
              >
                <v-card>
                  <v-card-title class="grey100">{{ $t('chargeGills') }}</v-card-title>

                  <v-card-text class="py-6 px-4">
                    <v-container>
                      <v-row v-if="gillsListPage !== null && gillsListPage['totalCount'] > 0">
                        <v-col class="overflow-auto">
                          <v-responsive min-width="500">
                            <v-container
                              fluid
                              :class="[
                                'table',
                                { 'font-size-16': $vuetify.breakpoint.mdAndUp },
                                { 'font-size-14': $vuetify.breakpoint.smAndDown },
                              ]"
                            >
                              <v-row
                                :class="['thead align-center text-center', { dense: $vuetify.breakpoint.smAndDown }]"
                              >
                                <v-col cols="2" class="font-weight-bold"></v-col>
                                <v-col cols="4" class="font-weight-bold">{{ $t('name') }}</v-col>
                                <v-col cols="6" class="font-weight-bold">{{ $t('udid') }}</v-col>
                              </v-row>
                              <v-row
                                :class="['tbody align-center', { dense: $vuetify.breakpoint.smAndDown }]"
                                v-for="gills in gillsList"
                                :key="gills['GILLS_UDID']"
                              >
                                <v-col cols="2" class="text-center">
                                  <v-checkbox
                                    value
                                    :input-value="editedUserChargeList.indexOf(gills['GILLS_UDID']) > -1"
                                    @change="checkUserCharge($event, gills['GILLS_UDID'])"
                                    hide-details
                                    class="ma-0 d-inline-block"
                                  />
                                </v-col>
                                <v-col cols="4" class="text-center">
                                  {{ gills['GILLS_NAME'] }}
                                </v-col>
                                <v-col cols="6" class="text-center">
                                  {{ gills['GILLS_UDID'] }}
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-responsive>
                        </v-col>
                      </v-row>
                      <v-row v-if="gillsListPage !== null && gillsListPage['totalCount'] > 0">
                        <v-col>
                          <v-pagination
                            v-model="gillsPage"
                            :length="gillsListPage['totalPage']"
                            @input="getUserGillsList"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col class="text-center grey400--text py-16">
                          <v-progress-circular indeterminate v-if="isLoadingGillsList || isLoadingUserCharge" />
                          <span v-else>{{ $t('noData') }}</span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <!-- <v-divider></v-divider> -->

                  <v-card-actions class="pt-0 pb-6 px-8">
                    <v-spacer />
                    <v-btn
                      class="rounded-lg"
                      color="grey600"
                      outlined
                      @click="dialogUserCharge = false"
                      :disabled="isSavingUserCharge"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                      class="rounded-lg"
                      color="grey600"
                      elevation="0"
                      @click="setUserCharge"
                      :loading="isSavingUserCharge"
                      :disabled="isSavingUserCharge"
                      :outlined="isSavingUserCharge"
                      :dark="!isSavingUserCharge"
                    >
                      {{ $t('save') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn class="rounded-lg" color="grey600" outlined @click="dialogUserInfo = false">
                {{ $t('close') }}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="grey600"
                elevation="0"
                @click="setUserInfo"
                :loading="isSavingUserInfo"
                :disabled="isSavingUserInfo"
                :outlined="isSavingUserInfo"
                :dark="!isSavingUserInfo"
              >
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog persistent v-model="dialogDelUser" width="500">
          <v-card>
            <v-card-text class="pt-6" v-html="$t('msgDelUser')" />

            <v-card-actions>
              <v-spacer />
              <v-btn class="rounded-lg" color="grey600" outlined @click="dialogDelUser = false">
                {{ $t('close') }}
              </v-btn>
              <v-btn
                class="rounded-lg"
                color="grey600"
                elevation="0"
                @click="delUserInfo"
                :loading="isSavingDelUser"
                :disabled="isSavingDelUser"
                :outlined="isSavingDelUser"
                :dark="!isSavingDelUser"
              >
                {{ $t('delete') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase/app'
import SearchBox from '@/components/SearchBox.vue'
import { isNull, regExpTest } from '@/services/common'
import {
  getUserList,
  getUserGillsList,
  setUserInfo,
  getUserCharge,
  setUserCharge,
  setUserInvite,
  delUserInfo,
  delInvitedUserInfo,
  getCompanyList,
} from '@/services/api'
import { getUserLevel } from '@/services/common'
import { mapGetters } from 'vuex'

export default {
  name: 'UserManagement',
  components: {
    SearchBox,
  },
  data: () => ({
    areaAId: '',
    areaBId: '',
    areaCId: '',
    keyword: '',
    userPage: 1,
    userItemPerPage: 12,
    userList: null,
    userListPage: null,
    isLoadingUserList: true,
    dialogAddUser: false,
    isSavingAddUser: false,
    isAddUserFormValid: false,
    isLoadingCompanyList: false,
    companyList: null,
    companyId: '',
    email: '',
    userLevel: 'U',
    selectedUserIndex: null,
    dialogUserInfo: false,
    editedUserInfo: null,
    isSavingUserInfo: false,
    dialogDelUser: false,
    isSavingDelUser: false,
    dialogUserCharge: false,
    isLoadingUserCharge: true,
    isSavingUserCharge: false,
    userChargeList: [],
    editedUserChargeList: [],
    gillsPage: 1,
    gillsItemPerPage: 5,
    gillsList: [],
    gillsListPage: null,
    isLoadingGillsList: true,
  }),
  computed: {
    ...mapGetters(['userInfo']),
    rules() {
      let vm = this

      return {
        required: (value, type) => !isNull(value) || vm.$t('msgEnter' + type.charAt(0).toUpperCase() + type.slice(1)),
        email: (value) => regExpTest('EMAIL', value) || vm.$t('msgCheckEmailFormat'),
      }
    },
    levelList() {
      let list = []

      // if (this.userInfo['USER_LEVEL'] === 'G') list.push({ text: this.$t('gills'), value: 'G' })
      list.push({ text: this.$t('manager'), value: 'M' })
      list.push({ text: this.$t('user'), value: 'U' })

      return list
    },
  },
  watch: {
    dialogAddUser() {
      if (this.dialogAddUser) {
        if (this.userInfo['COMPANY_ID'] === 'GILLS') this.companyId = ''
        else this.companyId = this.userInfo['COMPANY_ID']

        this.email = ''
        this.userLevel = 'U'
        this.isSavingAddUser = false
        this.isAddUserFormValid = false
      } else {
        if (this.$refs.addUserForm) this.$refs.addUserForm.reset()
      }
    },
    async dialogUserInfo() {
      if (this.dialogUserInfo) {
        this.editedUserInfo = { ...this.userList[this.selectedUserIndex] }
        await this.getUserCharge()
        this.editedUserChargeList = this.userChargeList.map((gills) => gills['GILLS_UDID'])
      } else {
        this.userChargeList = []
        this.editedUserChargeList = []
      }
    },
    async dialogUserCharge() {
      if (this.dialogUserInfo) {
        this.gillsPage = 1
        await this.getUserGillsList()
      } else {
        this.gillsList = []
      }
    },
  },
  async created() {
    this.getUserList()

    if (this.userInfo['COMPANY_ID'] === 'GILLS') await this.getCompanyList()
    else this.companyId = this.userInfo['COMPANY_ID']
  },
  methods: {
    getUserLevel(level) {
      return getUserLevel(level)
    },
    setAreaAId(areaAId) {
      this.areaAId = areaAId
    },
    setAreaBId(areaBId) {
      this.areaBId = areaBId
    },
    setAreaCId(areaCId) {
      this.areaCId = areaCId
    },
    requestSearch() {
      this.userPage = 1
      this.getUserList()
    },
    async getCompanyList() {
      this.isLoadingCompanyList = true
      try {
        let result = await getCompanyList(this)
        this.companyList = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(this.$t('errorMsg'))
      } finally {
        this.isLoadingCompanyList = false
      }
    },
    async getUserList() {
      this.isLoadingUserList = true
      try {
        let result = await getUserList(
          this,
          this.userPage,
          this.userItemPerPage,
          this.areaAId,
          this.areaBId,
          this.areaCId,
          this.userCat,
          this.userDtFrom,
          this.userDtTo
        )
        this.userList = result.data['DATA']
        this.userListPage = result.data['PAGE']
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingUserList = false
      }
    },
    async addUser() {
      this.isSavingAddUser = true

      if (!this.$refs.addUserForm.validate()) {
        this.isSavingAddUser = false
        return false
      }

      try {
        let result = await setUserInvite(this, this.companyId, this.email, this.userLevel)
        if (result.data) {
          await firebase.auth().sendSignInLinkToEmail(this.email, {
            url: `${location.protocol}//${location.host}?inviteKey=${window.btoa(this.email)}`,
            handleCodeInApp: true,
            dynamicLinkDomain: 'cleangills.page.link',
          })
          await this.requestSearch()
          this.dialogAddUser = false
        } else {
          alert(this.$t('addUserFailed'))
        }
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)

        alert(this.$t('errorMsg'))
      } finally {
        this.isSavingAddUser = false
      }
    },
    async setUserInfo() {
      this.isSavingUserInfo = true
      try {
        let result = await setUserInfo(
          this,
          this.userList[this.selectedUserIndex]['USER_ID'],
          this.editedUserInfo['USER_NAME'],
          this.editedUserInfo['USER_TEL'],
          this.editedUserInfo['USER_LEVEL']
        )
        this.userList.splice(this.selectedUserIndex, 1, result.data)
        this.dialogUserInfo = false
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)

        alert(this.$t('errorMsg'))
      } finally {
        this.isSavingUserInfo = false
      }
    },
    async delUserInfo() {
      this.isSavingDelUser = true
      try {
        let result
        if ((this.userList[this.selectedUserIndex]['USER_SIGNED_DT'] ?? '') === '')
          result = await delInvitedUserInfo(
            this,
            this.userList[this.selectedUserIndex]['COMPANY_ID'],
            this.userList[this.selectedUserIndex]['USER_EMAIL']
          )
        else result = await delUserInfo(this, this.userList[this.selectedUserIndex]['USER_ID'])
        if (result.data) {
          await this.requestSearch()
          alert(this.$t('delUserCompleted'))
          this.dialogDelUser = false
        } else alert(this.$t('delUserFailed'))
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)

        alert(this.$t('errorMsg'))
      } finally {
        this.isSavingDelUser = false
      }
    },
    async getUserGillsList() {
      this.isLoadingGillsList = true
      try {
        let result = await getUserGillsList(
          this,
          this.userList[this.selectedUserIndex]['USER_ID'],
          this.gillsPage,
          this.gillsItemPerPage
        )
        this.gillsListPage = result.data['PAGE']
        this.gillsList = result.data['DATA']
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingGillsList = false
      }
    },
    async getUserCharge() {
      this.isLoadingUserCharge = true
      try {
        let result = await getUserCharge(this, this.userList[this.selectedUserIndex]['USER_ID'])
        this.userChargeList = result.data
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
      } finally {
        this.isLoadingUserCharge = false
      }
    },
    checkUserCharge(value, userId) {
      let index = this.editedUserChargeList.indexOf(userId)

      if (!value) {
        if (index > -1) this.editedUserChargeList.splice(index, 1)
      } else {
        if (index === -1) this.editedUserChargeList.push(userId)
      }
    },
    async setUserCharge() {
      this.isSavingUserCharge = true
      try {
        let result = await setUserCharge(
          this,
          this.userList[this.selectedUserIndex]['USER_ID'],
          this.editedUserChargeList
        )
        if (result.data) {
          await this.getUserCharge()
          this.dialogUserCharge = false
        }
      } catch (error) {
        if (error.message) console.error(error.code, error.message)
        else if (error.data) console.error(error.data)
        else console.error(error)
        alert(this.$t('errorMsg'))
      } finally {
        this.isSavingUserCharge = false
      }
    },
  },
}
</script>
