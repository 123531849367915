var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('v-container',{staticClass:"pt-lg-15 pt-md-10 pb-0",attrs:{"slot":"title","fluid":""},slot:"title"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('h2',{class:[
            { 'font-size-30': _vm.$vuetify.breakpoint.mdAndUp },
            { 'font-size-20': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.$t('gsen'))+" "),(_vm.gsenListPage !== null)?_c('span',{class:[
              'ml-3 font-weight-regular',
              { 'font-size-16': _vm.$vuetify.breakpoint.mdAndUp },
              { 'font-size-14': _vm.$vuetify.breakpoint.smAndDown } ],domProps:{"innerHTML":_vm._s(_vm.$t('totalGsenCount', { count: _vm.gsenListPage['totalCount'] }))}}):_vm._e()])]),_c('v-spacer'),_c('v-col',{staticClass:"d-flex align-end justify-end",attrs:{"cols":"12","md":"auto"}},[_c('span',{class:[
            'grey600--text',
            { 'font-size-14': _vm.$vuetify.breakpoint.mdAndUp },
            { 'font-size-12': _vm.$vuetify.breakpoint.smAndDown },
            { 'mt-2 mb-1': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.lastUpdatedTime ? _vm.$t('lastUpdate') + _vm.lastUpdatedTime : '')+" ")])])],1)],1),_c('v-container',{staticClass:"pa-lg-4 pt-lg-10 pt-md-10 align-content-start",attrs:{"slot":"content","fluid":"","fill-height":""},slot:"content"},[_c('v-row',[_c('v-col',[_c('v-sheet',{staticClass:"box",attrs:{"outlined":"","color":"grey100","rounded":"lg"}},[_c('v-card',{staticClass:"pa-4 pa-md-10",attrs:{"tag":"section","rounded":"lg","elevation":"0"}},[_c('search-box',{attrs:{"isUseKeyword":true,"isUseKeywordOffset":true},on:{"setAreaAId":_vm.setAreaAId,"setAreaBId":_vm.setAreaBId,"setAreaCId":_vm.setAreaCId,"setKeyword":_vm.setKeyword,"requestSearch":_vm.requestSearch}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.gsenList),function(gsen){return _c('v-col',{key:gsen['GSEN_UDID'],staticClass:"pa-3",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-sheet',{staticClass:"box",attrs:{"outlined":"","color":"grey100","rounded":"lg"}},[_c('v-card',{staticClass:"pa-4 pa-md-10",attrs:{"tag":"section","rounded":"lg","elevation":"0","to":{ name: 'GsenDetail', params: { gsenUdid: gsen['GSEN_UDID'] } }}},[_c('v-container',{staticClass:"pa-0",attrs:{"fill-height":""}},[_c('v-row',{staticClass:"align-self-start"},[_c('v-col',{class:[{ 'pa-2': _vm.$vuetify.breakpoint.smAndDown }]},[_c('h3',{class:[
                            { 'font-size-20': _vm.$vuetify.breakpoint.mdAndUp },
                            { 'font-size-16': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(gsen['GSEN_NAME'])+" ")])]),_c('v-spacer'),_c('v-col',{class:['text-right', { 'pa-2': _vm.$vuetify.breakpoint.smAndDown }]},[_c('v-chip',{attrs:{"label":"","small":_vm.$vuetify.breakpoint.mdAndUp,"x-small":_vm.$vuetify.breakpoint.smAndDown,"dark":"","color":(gsen['STATE'] === 'ERROR' ? 'NOSIGNAL' : gsen['STATE']).toLowerCase()}},[_vm._v(" "+_vm._s(_vm.$t((gsen['STATE'] === 'WORKING' ? 'ONLINE' : gsen['STATE']).toLowerCase()))+" ")])],1)],1),_c('v-row',{staticClass:"align-self-baseline"},[_c('v-col',{staticClass:"d-flex pa-0"},[_c('v-container',[_c('v-row',{class:[{ 'flex-nowrap overflow-x-auto': _vm.$vuetify.breakpoint.smAndDown }]},[_vm._l((_vm.itemList),function(item){return _c('v-col',{key:item,staticClass:"pa-2"},[_c('v-sheet',{staticClass:"d-flex justify-center align-center",attrs:{"rounded":"lg","width":"68","height":"68","color":_vm.getGsenStatus(gsen['GSEN_DATA_' + item + '_STATUS']),"dark":""}},[_c('span',{class:[
                                    { 'font-size-16': _vm.$vuetify.breakpoint.mdAndUp },
                                    { 'font-size-14': _vm.$vuetify.breakpoint.smAndDown } ]},[_vm._v(" "+_vm._s(_vm.$t(item))+" ")])])],1)}),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-responsive',{staticClass:"pa-2"}):_vm._e()],2)],1)],1)],1)],1)],1)],1)],1)}),1),(_vm.gsenListPage !== null && _vm.gsenListPage['totalCount'] > 0)?_c('v-row',[_c('v-col',[_c('v-pagination',{attrs:{"length":_vm.gsenListPage['totalPage']},on:{"input":_vm.getGsenList},model:{value:(_vm.gsenPage),callback:function ($$v) {_vm.gsenPage=$$v},expression:"gsenPage"}})],1)],1):_c('v-row',[_c('v-col',{staticClass:"text-center grey400--text py-16"},[(_vm.isLoadingGsenList)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_c('span',[_vm._v(_vm._s(_vm.$t('noData')))])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }